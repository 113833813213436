import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const TermsOfServiceContent = () => {


    return (

        <section className='TermsOfServiceContent section_gaps'>

            <Container>
                
                <Row>

                    <Col md={12}>

                        <div className="Header">

                            <h2>Terms & <span>Condition</span></h2>
                            <p>Hostwev is committed to respecting our visitors information. We collect basic data from our visitors, such as IP addresses, browsers, time of access, etc. We use this information to analyze our traffic and to produce services that will be beneficial to our customers and us. We also apply cookies to all customers that visit our website.Cookies are small pieces of information that are stored by your browser on your computer's hard drive. We use cookies for tracking purposes only. All information received upon our tracking devices, and any online form filled out by our visitors, will be kept strictly confidential, and will never be shared with a third party.  </p>
                            <p>Once a visitor becomes a client of Hostwev by filling out our online order form or account activation form, they are immediately affected by our privacy policy. We also collect personal information to process our customer’s service requests. Their personal information is only used to process the request, and for no other purpose. All information that is collected from our customers is kept strictly confidential, and will never be shared with a third party. All customers have their own unique account with Hostwev and it is kept completely secure at all times. </p>

                            <p>Our privacy policy has been compiled to better serve those who are concerned with how their 'Personally identifiable information' (PII) is being used online. PII, as used in US privacy law and information security, is information that can be used on its own or with other information to identify, contact, or locate a single person, or to identify an individual in context. Please read our privacy policy carefully to get a clear understanding of how we collect, use, protect or otherwise handle your Personally Identifiable Information in accordance with our website. </p>

                        </div>

                        {/* SubHeading */}
                        <div className="SubHeading">

                            <h5>Personal information that we collect from our users </h5>
                            <p>When ordering or registering on our site, as appropriate, you may be asked to enter your name, email address, mailing address, phone number, credit card information or other details to help you with your experience. </p>
                            <p>We collect information from you when you register on our site, place an order, subscribe to a newsletter, respond to a survey, fill out a form or enter information on our site. </p>

                        </div>

                        {/* SubHeading */}
                        <div className="SubHeading">

                              <h5>Usage of user information </h5>
                              <p>We may use the information we collect from you when you register, make a purchase, sign up for our newsletter, respond to a survey or marketing communication, surf the website, or use certain other site features in the following ways: </p>
                              <p>We collect information from you when you register on our site, place an order, subscribe to a newsletter, respond to a survey, fill out a form or enter information on our site. </p>

                            <ul>
                                <li>To personalize user's experience and to allow us to deliver the type of content and product offerings in which you are most interested. </li>
                                <li>To improve our website in order to better serve you.</li>
                                <li>To allow us to better service you in responding to your customer service requests. </li>
                                <li>To administer a contest, promotion, survey or other site feature. </li>
                                <li>To quickly process your transactions. </li>
                                <li>To send periodic emails regarding your order or other products and services. </li>
                            </ul>

                        </div>

                        {/* SubHeading */}
                        <div className="SubHeading">

                            <h5>Protection of User Information</h5>
                            <p>Our website is scanned on a regular basis for security holes and known vulnerabilities in order to make your visit to our site as safe as possible. </p>
                            <p>Your personal information is contained behind secured networks and is only accessible by a limited number of persons who have special access rights to such systems, and are required to keep the information confidential. In addition, all sensitive/credit information you supply is encrypted via Secure Socket Layer (SSL) technology. </p>
                            <p>We implement a variety of security measures when a user places an order enters, submits, or accesses their information to maintain the safety of your personal information. </p>
                            <p>All transactions are processed through a gateway provider and are not stored or processed on our servers. </p>

                        </div>

                        {/* SubHeading */}
                        <div className="SubHeading">

                            <h5>Usage of 'cookies'</h5>
                            <p>Cookies are small files that a site or its service provider transfers to your computer's hard drive through your Web browser (if you allow) that enables the site's or service provider's systems to recognize your browser and capture and remember certain information. For instance, we use cookies to help us remember and process the items in your shopping cart. They are also used to help us understand your preferences based on previous or current site activity, which enables us to provide you with improved services. We also use cookies to help us compile aggregate data about site traffic and site interaction so that we can offer better site experiences and tools in the future. </p>

                            <p>We use cookies to: </p>

                            <ul>
                                <li>Help remember and process the items in the shopping cart. </li>
                                <li>Understand and save user's preferences for future visits. </li>
                                <li>Keep track of advertisements. </li>
                                <li>Compile aggregate data about site traffic and site interactions in order to offer better site experiences and tools in the future. We may also use trusted third party services that track this information on our behalf. </li>
                            </ul>

                            <p>You can choose to have your computer warn you each time a cookie is being sent, or you can choose to turn off all cookies. You do this through your browser (like Internet Explorer) settings. Each browser is a little different, so look at your browser's Help menu to learn the correct way to modify your cookies. </p>
                            <p>If you disable cookies off, some features will be disabled It won't affect the users experience that make your site experience more efficient and some of our services will not function properly. </p>

                        </div>

                        {/* SubHeading */}
                        <div className="SubHeading">

                            <h5>Usage of 'cookies'</h5>
                            <p>Cookies are small files that a site or its service provider transfers to your computer's hard drive through your Web browser (if you allow) that enables the site's or service provider's systems to recognize your browser and capture and remember certain information. For instance, we use cookies to help us remember and process the items in your shopping cart. They are also used to help us understand your preferences based on previous or current site activity, which enables us to provide you with improved services. We also use cookies to help us compile aggregate data about site traffic and site interaction so that we can offer better site experiences and tools in the future. </p>

                            <p>We use cookies to: </p>

                            <ul>

                                <li>Help remember and process the items in the shopping cart. </li>
                                <li>Understand and save user's preferences for future visits. </li>
                                <li>Keep track of advertisements. </li>
                                <li>Compile aggregate data about site traffic and site interactions in order to offer better site experiences and tools in the future. We may also use trusted third party services that track this information on our behalf.</li>

                            </ul>

                            <p>You can choose to have your computer warn you each time a cookie is being sent, or you can choose to turn off all cookies. You do this through your browser (like Internet Explorer) settings. Each browser is a little different, so look at your browser's Help menu to learn the correct way to modify your cookies. </p>
                            <p>If you disable cookies off, some features will be disabled It won't affect the users experience that make your site experience more efficient and some of our services will not function properly. </p>

                        </div>

                        {/* SubHeading */}
                        <div className="SubHeading">

                            <h5>Third Party Disclosure</h5>
                            <p>We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information unless we provide you with advance notice. This does not include website hosting partners and other parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential. We may also release your information when we believe release is appropriate to comply with the law, enforce our site policies, or protect ours or others' rights, property, or safety.  </p>
                            <p>However, non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other uses. </p>

                        </div>

                        {/* SubHeading */}
                        <div className="SubHeading">

                            <h5>Third Party Links </h5>
                            <p>Occasionally, at our discretion, we may include or offer third party products or services on our website. These third party sites have separate and independent privacy policies. We therefore have no responsibility or liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity of our site and welcome any feedback about these sites. </p>

                        </div>

                        {/* SubHeading */}
                        <div className="SubHeading">

                            <h5>COPPA - Children Online Privacy Protection Act</h5>
                            <p>When it comes to the collection of personal information from children under 13, the Children's Online Privacy Protection Act (COPPA) puts parents in control. The Federal Trade Commission, the nation's consumer protection agency, enforces the COPPA Rule, which spells out what operators of websites and online services must do to protect children's privacy and safety online. </p>
                            <p>We do not specifically market to children under 13. </p>

                        </div>

                        {/* SubHeading */}
                        <div className="SubHeading">

                            <h5>Fair Information Practices </h5>
                            <p>The Fair Information Practices Principles form the backbone of privacy law in the United States and the concepts they include have played a significant role in the development of data protection laws around the globe. Understanding the Fair Information Practice Principles and how they should be implemented is critical to comply with the various privacy laws that protect personal information. </p>
                            <p>In order to be in line with Fair Information Practices we will take the following responsive action, should a data breach occur: We will notify the users via email </p>

                            <ul>
                                <li>Within 7 working days </li>
                                <li>We will notify the users via in site notification </li>
                                <li>Within 7 working days </li>
                            </ul>

                            <p>We also agree to the individual redress principle, which requires that individuals have a right to pursue legally enforceable rights against data collectors and processors who fail to adhere to the law. This principle requires not only that individuals have enforceable rights against data users, but also that individuals have recourse to courts or a government agency to investigate and/or prosecute non-compliance by data processors. </p>

                        </div>

                        {/* SubHeading */}
                        <div className="SubHeading">

                            <h5>CAN SPAM Act</h5>
                            <p>We collect your email address in order to: </p>

                            <ul>
                                <li>Send information, respond to inquiries, and/or other requests or questions. </li>
                                <li>Process orders and to send information and updates pertaining to orders </li>
                                <li>We may also send you additional information related to your product and/or service. </li>
                                <li>Market to our mailing list or continue to send emails to our clients after the original transaction has occurred </li>
                            </ul>

                            <p>To be accordance with CAN SPAM we agree to the following: </p>

                            <ul>
                                <li>NOT use false, or misleading subjects or email addresses </li>
                                <li>Identify the message as an advertisement in some reasonable way </li>
                                <li>Include the physical address of our business or site headquarters </li>
                                <li>Monitor third party email marketing services for compliance, if one is used. </li>
                                <li>Honor opt-out/unsubscribe requests quickly </li>
                                <li>Allow users to unsubscribe by using the link at the bottom of each email </li>
                            </ul>

                            <p>If at any time you would like to unsubscribe from receiving future emails, you can</p>
                            <ul>
                                <li>Follow the instructions at the bottom of each email. </li>
                            </ul>

                        </div>

                        {/* SubHeading */}
                        <div className="SubHeading">

                            <h5>Contacting Us </h5>
                            <p>If there are any questions regarding this privacy policy you may contact us at sales@hostwev.com </p>

                        </div>

                    </Col>

                </Row>
                
            </Container>

        </section>
        
    )


}

export default TermsOfServiceContent