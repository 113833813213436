import React from 'react';
import Header from '../Components/CommonComponents/Header';
import Menu from '../Components/CommonComponents/Menu';
import Footer from '../Components/CommonComponents/Footer';
import OthersBanner from '../Components/CommonComponents/OthersBanner';
import WhatSsl from '../Components/SslCertificatePage/WhatSsl';
import RapidSsl from '../Components/SslCertificatePage/RapidSsl';
import Package from '../Components/SslCertificatePage/Package';
import OpenVzVps from '../Components/SslCertificatePage/OpenVzVps';
import WhereToStart from '../Components/CommonComponents/WhereToStart';
import Partner from '../Components/CommonComponents/Partner';


const SslCertificate = () => {


    let header={
        header:'SSL',
        span:'Certificate',
        description:'We like to think of a dedicated server like owning your own house. You can do whatever you’d like. This leads to increased speed, reliability, and security',
    }



    return (

        <>
        
            {/* Header */}
            <Header></Header>

            {/* Menu */}
            <Menu active='ssl'></Menu>

            {/* banner */}
            <OthersBanner header={header}></OthersBanner>

            {/* WhatSsl */}
            <WhatSsl></WhatSsl>

            {/* WhatSsl */}
            <Package></Package>

            {/* RapidSsl */}
            <RapidSsl></RapidSsl>

            {/* OpenVzVps */}
            <OpenVzVps></OpenVzVps>

            {/* HostingSolution */}
            <WhereToStart></WhereToStart>

            {/* Partner */}
            <Partner></Partner>
            
            {/* Footer */}
            <Footer></Footer>

            


        </>

    )



}

export default SslCertificate