import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const OpenVzVps = () => {


    return (

        <>
        
            {/* Single Processor */}
            <section className='OpenVzVps'>

                <Container>

                    {/* header */}
                    <Row className="justify-content-md-center">

                        <Col md={12}>

                            <div className="Header text-center">
                                <h2> CloudLinux Lightweight Virtual <span> Environment (LVE) </span></h2>
                                <p>The CloudLinux OS Lightweight Virtualized Environment is a kernel-level technology that isolates each tenant into their own environment and provides them with the amount of resources (CPU, IO, memory, processes, etc.) that can be used – specific to each individual customer</p>
                                
                            </div>

                        </Col>

                    </Row>

                    <div className='OpenVzVpsContent SslCertificate'>

                        <Row>

                            <Col md={12}>

                                {/* header */}
                                <div className="OpenVzVpHeader d_flex">

                                    {/* item */}
                                    <div className="OpenVzVpHeaderItem d_flex d_center">
                                        <h4>CloudLinux <br /> LVE Limits</h4>
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpHeaderItem d_flex d_center">
                                        <h4>Basic</h4>
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpHeaderItem d_flex d_center">
                                        <h4>Starter</h4>
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpHeaderItem d_flex d_center">
                                        <h4>Standard</h4>
                                    </div>
                                    
                                </div>

                                {/* Content */}
                                <div className="OpenVzVpMiddleContent">

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>CPU Cores</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>1 CPU Core</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>1 CPU Core</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>2 CPU Core</h4>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>RAM</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>750 MB</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>1 GB</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>1.5 GB</h4>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>Disk I/O</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>15 Mbps I/O</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>20 Mbps I/O</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>40 Mbps I/O</h4>
                                            </div>

                                        </div>
                                        
                                    </div>
                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                            {/* right */}
                                            <div className="OpenVzVpRight d_flex">

                                                {/* item */}
                                                <div className="OpenVzVpRightItem">
                                                    <h4>Concurrent Connections</h4>
                                                </div>

                                                {/* item */}
                                                <div className="OpenVzVpRightItem">
                                                    <h4>15 EP</h4>
                                                </div>

                                                {/* item */}
                                                <div className="OpenVzVpRightItem">
                                                    <h4>20 EP</h4>
                                                </div>

                                                {/* item */}
                                                <div className="OpenVzVpRightItem">
                                                    <h4>40 EP</h4>
                                                </div>

                                            </div>

                                    </div>
                                    
                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                            {/* right */}
                                            <div className="OpenVzVpRight d_flex">

                                                {/* item */}
                                                <div className="OpenVzVpRightItem">
                                                    <h4>Max. Number Of Processes</h4>
                                                </div>

                                                {/* item */}
                                                <div className="OpenVzVpRightItem">
                                                    <h4>100 nPROC</h4>
                                                </div>

                                                {/* item */}
                                                <div className="OpenVzVpRightItem">
                                                    <h4>100 nPROC</h4>
                                                </div>

                                                {/* item */}
                                                <div className="OpenVzVpRightItem">
                                                    <h4>100 nPROC</h4>
                                                </div>

                                            </div>

                                    </div>

                                </div>
                                

                            </Col>

                        </Row>

                    </div>
                    
                </Container>

            </section>

        </>
        
    )


}

export default OpenVzVps
