import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const OpenVzVps = () => {


    return (

        <>
        
            {/* Single Processor */}
            <section className='OpenVzVps'>

                <Container>
                    
                    <Row>

                        <Col md={12}>

                            <div className="Header">
                                <h2><span>Dedicated</span> Servers</h2>
                            </div>

                        </Col>

                    </Row>

                    <div className='OpenVzVpsContent'>

                        <Row>

                            <Col md={12}>

                                {/* header */}
                                <div className="OpenVzVpHeader d_flex">

                                    {/* item */}
                                    <div className="OpenVzVpHeaderItem d_flex d_center">
                                        <h4>Processor <br/> Name</h4>
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpHeaderItem d_flex d_center">
                                        <h4>Speed</h4>
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpHeaderItem d_flex d_center">
                                        <h4>Memory</h4>
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpHeaderItem d_flex d_center">
                                        <h4>Storage</h4>
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpHeaderItem d_flex d_center">
                                        <h4>Transfer</h4>
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpHeaderItem d_flex d_center">
                                        <h4>Price</h4>
                                    </div>
                                    
                                </div>

                                {/* Content */}
                                <div className="OpenVzVpMiddleContent">

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* left */}
                                        <div className="OpenVzVpLeft">
                                            <h4>Intel Xeon D-2141 I</h4>
                                        </div>

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>3.50<br /> GHz</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>16GB<br />RAM</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>500 GB<br />SSD</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4> 5<br />TB</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT<br />20,999/Mo</h4>
                                            </div>

                                            {/* item */}
                                            <div className="Position">
                                                <a href="https://my.hostwev.com/index.php?rp=/store/dedicated-server/ds-ssd8c" className='bg'>Configure</a>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* left */}
                                        <div className="OpenVzVpLeft">
                                            <h4>Intel Xeon D-2141 I</h4>
                                        </div>

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>3.5 <br /> GHz</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>28 GB <br />DDR4 ECC</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>1 TB<br />SSD</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4> 10 <br />TB</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT<br />28999/Mo</h4>
                                            </div>

                                            {/* item */}
                                            <div className="Position">
                                                <a href="https://my.hostwev.com/index.php?rp=/store/dedicated-server/ds-ssd28gb" className='bg'>Configure</a>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* left */}
                                        <div className="OpenVzVpLeft">
                                            <h4>Intel Xeon D-2141 I</h4>
                                        </div>

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>3.7<br /> GHz</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>60 GB <br />DDR4 ECC</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>1.79 TB<br />SSD</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4> 15<br />TB</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT<br />36,555/Mo</h4>
                                            </div>

                                            {/* item */}
                                            <div className="Position">
                                                <a href="https://my.hostwev.com/index.php?rp=/store/dedicated-server/ds-ssd60gb" className='bg'>Configure</a>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    {/* item */}
                                    {/* <div className="OpenVzVpMiddle d_flex">

                                        
                                        <div className="OpenVzVpLeft">
                                            <h4>Intel Xeon E5 1650 V4</h4>
                                        </div>

                                        
                                        <div className="OpenVzVpRight d_flex">

                                            
                                            <div className="OpenVzVpRightItem">
                                                <h4>3.6<br /> GHz</h4>
                                            </div>

                                           
                                            <div className="OpenVzVpRightItem">
                                                <h4>32 GB <br />DDR4 ECC</h4>
                                            </div>

                                            
                                            <div className="OpenVzVpRightItem">
                                                <h4>1 TB<br />SSD</h4>
                                            </div>

                                           
                                            <div className="OpenVzVpRightItem">
                                                <h4> 10<br />TB</h4>
                                            </div>

                                            
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT<br />34000/Mo</h4>
                                            </div>

                                            
                                            <div className="Position">
                                                <button className='bg'>Configure</button>
                                            </div>

                                        </div>
                                        
                                    </div> */}


                                </div>
                                

                            </Col>

                        </Row>

                    </div>
                    
                </Container>

            </section>

            {/* Dual CPU Servers */}
            
            {/* <section className='OpenVzVps'>

                <Container>
                    
                    <Row>

                        <Col md={12}>

                            <div className="Header">
                                <h2><span>Dual</span> CPU Servers</h2>
                            </div>

                        </Col>

                    </Row>

                    <div className='OpenVzVpsContent'>

                        <Row>

                            <Col md={12}>

                                <div className="OpenVzVpHeader d_flex">

                                    <div className="OpenVzVpHeaderItem d_flex d_center">
                                        <h4>Processor <br/> Name</h4>
                                    </div>

                                    <div className="OpenVzVpHeaderItem d_flex d_center">
                                        <h4>Speed</h4>
                                    </div>

                                    <div className="OpenVzVpHeaderItem d_flex d_center">
                                        <h4>Memory</h4>
                                    </div>

                                    <div className="OpenVzVpHeaderItem d_flex d_center">
                                        <h4>Storage</h4>
                                    </div>

                                    <div className="OpenVzVpHeaderItem d_flex d_center">
                                        <h4>Transfer</h4>
                                    </div>

                                    <div className="OpenVzVpHeaderItem d_flex d_center">
                                        <h4>Price</h4>
                                    </div>
                                    
                                </div>

                                <div className="OpenVzVpMiddleContent">

                                    <div className="OpenVzVpMiddle d_flex">

                                        <div className="OpenVzVpLeft">
                                            <h4>Intel Xeon E3 1240 V5 4 Cores @ 3.5 Ghz</h4>
                                        </div>

                                        <div className="OpenVzVpRight d_flex">

                                            <div className="OpenVzVpRightItem">
                                                <h4>2+2.2 <br /> GHz</h4>
                                            </div>

                                            <div className="OpenVzVpRightItem">
                                                <h4>2 <br />Dedicated</h4>
                                            </div>

                                            <div className="OpenVzVpRightItem">
                                                <h4>40 GB<br />SSD</h4>
                                            </div>

                                            <div className="OpenVzVpRightItem">
                                                <h4> 1<br />TB</h4>
                                            </div>

                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT<br />2200</h4>
                                            </div>

                                            <div className="Position">
                                                <button className='bg'>Configure</button>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    <div className="OpenVzVpMiddle d_flex">

                                        <div className="OpenVzVpLeft">
                                            <h4>Intel Xeon E3 1240 V5 4 Cores @ 3.5 Ghz</h4>
                                        </div>

                                        <div className="OpenVzVpRight d_flex">

                                            <div className="OpenVzVpRightItem">
                                                <h4>2+2.2 <br /> GHz</h4>
                                            </div>

                                            <div className="OpenVzVpRightItem">
                                                <h4>2 <br />Dedicated</h4>
                                            </div>

                                            <div className="OpenVzVpRightItem">
                                                <h4>40 GB<br />SSD</h4>
                                            </div>

                                            <div className="OpenVzVpRightItem">
                                                <h4> 1<br />TB</h4>
                                            </div>

                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT<br />2200</h4>
                                            </div>

                                            <div className="Position">
                                                <button className='bg'>Configure</button>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    <div className="OpenVzVpMiddle d_flex">

                                        <div className="OpenVzVpLeft">
                                            <h4>Intel Xeon E3 1240 V5 4 Cores @ 3.5 Ghz</h4>
                                        </div>

                                        <div className="OpenVzVpRight d_flex">

                                            <div className="OpenVzVpRightItem">
                                                <h4>2+2.2 <br /> GHz</h4>
                                            </div>

                                            <div className="OpenVzVpRightItem">
                                                <h4>2 <br />Dedicated</h4>
                                            </div>

                                            <div className="OpenVzVpRightItem">
                                                <h4>40 GB<br />SSD</h4>
                                            </div>

                                            <div className="OpenVzVpRightItem">
                                                <h4> 1<br />TB</h4>
                                            </div>

                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT<br />2200</h4>
                                            </div>

                                            <div className="Position">
                                                <button className='bg'>Configure</button>
                                            </div>

                                        </div>
                                        
                                    </div>


                                </div>
                                

                            </Col>

                        </Row>

                    </div>
                    
                </Container>

            </section> */}

        </>
        
    )


}

export default OpenVzVps
