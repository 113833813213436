/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Container, Row, Col, Nav, NavDropdown, Tabs, Tab } from 'react-bootstrap';

import { Link } from 'react-router-dom';

const Package = () => {


    return (

        <section className='Package'>

            <Container>
                
                <Row>

                    
                    <Col md={12}>

                        <div className="PackageContent">

                            {/* <Tabs variant='pills' defaultActiveKey="PackOne" > */}

                                {/* PackOne */}
                                {/* <Tab eventKey="PackOne" title="1 Month"> */}
                                    
                                    <div className="PackageContentPart">

                                        <Row>

                                            {/* item */}
                                            <Col lg={4} md={4}>

                                                <div className="PackageContentItem">

                                                    {/* <span className='Off'>50% OFF</span> */}
                                                    
                                                    {/* PackagePrice */}
                                                    <div className="PackagePrice">

                                                        <h4>POSITIVE SSL</h4>
                                                        <h3>BDT 1449/-</h3>
                                                        <h5>Yearly</h5>
                                                        <h6></h6>

                                                    </div>

                                                    {/* listItem */}
                                                    <div className="listItem">

                                                        <ul>

                                                            <li>Domain Validation</li>
                                                            <li>1 Sub-domain</li>
                                                            <li>SHA2 & ECC 128/256 bit Encryption</li>
                                                            <li>Trust Logo Supported</li>
                                                            <li>Issued within 48 hours</li>
                                                            <li>Free Reissuance</li>
                                                            <li>24/7 Technical Support</li>
                                                            <li>7 day Money Back</li>

                                                        </ul>

                                                    </div>

                                                    <a href="https://my.hostwev.com/index.php?rp=/store/ssl-certificate/positive-ssl" className='bg'>Get Started</a>

                                                </div>

                                            </Col>

                                            {/* item */}
                                            <Col lg={4} md={4}>
                                            
                                                <div className="PackageContentItem">

                                                    {/* <span className='Off'>50% OFF</span> */}
                                                    
                                                    {/* PackagePrice */}
                                                    <div className="PackagePrice">

                                                        <h4>COMODO SSL</h4>
                                                        <h3>BDT 4899/-</h3>
                                                        <h5>Yearly</h5>
                                                        <h6></h6>

                                                    </div>

                                                    {/* listItem */}
                                                    <div className="listItem">

                                                        <ul>

                                                            <li>Domain Validation</li>
                                                            <li>1 Sub-domain</li>
                                                            <li>SHA2 & ECC 128/256 bit Encryption</li>
                                                            <li>Trust Logo Supported</li>
                                                            <li>Issued within 48 hours</li>
                                                            <li>Free Reissuance</li>
                                                            <li>24/7 Technical Support</li>
                                                            <li>7 day Money Back</li>

                                                        </ul>

                                                    </div>

                                                    <a href="https://my.hostwev.com/index.php?rp=/store/ssl-certificate/comodo-ssl" className='bg'>Get Started</a>

                                                </div>

                                            </Col>

                                            {/* item */}
                                            <Col lg={4} md={4}>
                                            
                                                <div className="PackageContentItem">

                                                    {/* <span className='Off'>50% OFF</span> */}
                                                    
                                                    {/* PackagePrice */}
                                                    <div className="PackagePrice">

                                                        <h4>WILDCARD SSL</h4>
                                                        <h3>BDT 6899/-</h3>
                                                        <h5>Yearly</h5>
                                                        <h6></h6>

                                                    </div>

                                                    {/* listItem */}
                                                    <div className="listItem">

                                                        <ul>

                                                            <li>Domain Validation</li>
                                                            <li>Unlimited Sub-domain</li>
                                                            <li>SHA2 & ECC 128/256 bit Encryption</li>
                                                            <li>Trust Logo Supported</li>
                                                            <li>Issued within 48 hours</li>
                                                            <li>Free Reissuance</li>
                                                            <li>24/7 Technical Support</li>
                                                            <li>7 day Money Back</li>

                                                        </ul>

                                                    </div>

                                                    <a href="https://my.hostwev.com/index.php?rp=/store/ssl-certificate/positive-ssl-wildcard" className='bg'>Get Started</a>

                                                </div>

                                            </Col>

                                        </Row>

                                    </div>
                                    
                                {/* </Tab> */}

                                {/* <Tab eventKey="PackTwo" title="12 Month">

                                    <div className="PackageContentPart">

                                        <Row>

                                            
                                            <Col lg={4} md={4}>

                                                <div className="PackageContentItem">

                                                    
                                                    
                                                    
                                                    <div className="PackagePrice">

                                                        <h4>RESELLER BASIC</h4>
                                                        <h3>BDT ৳ 30000/-</h3>
                                                        <h5>Per Month</h5>
                                                        <h6>25GB Reseller HOSTING</h6>

                                                    </div>

                                                    
                                                    <div className="listItem">

                                                        <ul>

                                                            <li>25 GBNVME SSD space</li>
                                                            <li>250 GBMonthly Bandwidth</li>
                                                            <li>30cPanel Accounts</li>
                                                            <li>AttractaSEO Tools</li>
                                                            <li>CageFS HackProtection</li>
                                                            <li>CloudLinuxOS</li>
                                                            <li>24/7Technical Support</li>
                                                            <li>100%Whitelabeled Hosting</li>
                                                            <li>CloudflareCDN</li>
                                                            <li>99.9%Uptime Guarantee</li>
                                                            <li>FreeSSL Certificate</li>
                                                            <li>2500/Monthly</li>

                                                        </ul>

                                                    </div>

                                                    <a href="" className='bg'>Get Started</a>

                                                </div>

                                            </Col>

                                            
                                            <Col lg={4} md={4}>
                                            
                                                <div className="PackageContentItem">

                                                    
                                                    
                                                    
                                                    <div className="PackagePrice">

                                                        <h4>RESELLER STANDARD</h4>
                                                        <h3>BDT 84000/-</h3>
                                                        <h5>Per Month</h5>
                                                        <h6>50GB Reseller HOSTING</h6>

                                                    </div>

                                                    
                                                    <div className="listItem">

                                                        <ul>

                                                            <li>50 GBNVME SSD space</li>
                                                            <li>500 GBMonthly Bandwidth</li>
                                                            <li>60cPanel Accounts</li>
                                                            <li>AttractaSEO Tools</li>
                                                            <li>15% offVPS Hosting</li>
                                                            <li>CageFS HackProtection</li>
                                                            <li>CloudLinuxOS</li>
                                                            <li>24/7Technical Support</li>
                                                            <li>100%Whitelabeled Hosting</li>
                                                            <li>CloudflareCDN</li>
                                                            <li>99.9%Uptime Guarantee</li>
                                                            <li>FreeSSL Certificate</li>
                                                            <li>3500/Monthly</li>

                                                        </ul>

                                                    </div>

                                                    <a href="" className='bg'>Get Started</a>

                                                </div>

                                            </Col>

                                            
                                            <Col lg={4} md={4}>
                                            
                                                <div className="PackageContentItem">

                                                    
                                                    
                                                    
                                                    <div className="PackagePrice">

                                                        <h4>Package Name</h4>
                                                        <h3>BDT 57800/-</h3>
                                                        <h5>Per Month</h5>
                                                        <h6>100GB Reseller HOSTING</h6>

                                                    </div>

                                                    
                                                    <div className="listItem">

                                                        <ul>

                                                            <li>100 GBNVME SSD space</li>
                                                            <li>1 TBMonthly Bandwidth</li>
                                                            <li>100cPanel Accounts</li>
                                                            <li>AttractaSEO Tools</li>
                                                            <li>15% offVPS Hosting</li>
                                                            <li>CageFS HackProtection</li>
                                                            <li>CloudLinuxOS</li>
                                                            <li>24/7Technical Support</li>
                                                            <li>100%Whitelabeled Hosting</li>
                                                            <li>CloudflareCDN</li>
                                                            <li>99.9%Uptime Guarantee</li>
                                                            <li>FreeSSL Certificate</li>
                                                            <li>4999/Monthly</li>

                                                        </ul>

                                                    </div>

                                                    <a href="" className='bg'>Get Started</a>

                                                </div>

                                            </Col>

                                        </Row>

                                    </div>
                                   
                                </Tab> */}

                                {/* <Tab eventKey="PackThree" title="36 Month">

                                    <div className="PackageContentPart">

                                        <Row>

                                            
                                            <Col lg={4} md={4}>

                                                <div className="PackageContentItem">

                                                    
                                                    <div className="PackagePrice">

                                                        <h4>RESELLER BASIC</h4>
                                                        <h3>BDT ৳ 60000/-</h3>
                                                        <h5>Per Month</h5>
                                                        <h6>25GB Reseller HOSTING</h6>

                                                    </div>

                                                    
                                                    <div className="listItem">

                                                        <ul>

                                                            <li>25 GBNVME SSD space</li>
                                                            <li>250 GBMonthly Bandwidth</li>
                                                            <li>30cPanel Accounts</li>
                                                            <li>AttractaSEO Tools</li>
                                                            <li>CageFS HackProtection</li>
                                                            <li>CloudLinuxOS</li>
                                                            <li>24/7Technical Support</li>
                                                            <li>100%Whitelabeled Hosting</li>
                                                            <li>CloudflareCDN</li>
                                                            <li>99.9%Uptime Guarantee</li>
                                                            <li>FreeSSL Certificate</li>
                                                            <li>2500/Monthly</li>

                                                        </ul>

                                                    </div>

                                                    <a href="" className='bg'>Get Started</a>

                                                </div>

                                            </Col>

                                            
                                            <Col lg={4} md={4}>
                                            
                                                <div className="PackageContentItem">

                                                    
                                                    <div className="PackagePrice">

                                                        <h4>RESELLER STANDARD</h4>
                                                        <h3>BDT 168000/-</h3>
                                                        <h5>Per Month</h5>
                                                        <h6>50GB Reseller HOSTING</h6>

                                                    </div>

                                                   
                                                    <div className="listItem">

                                                        <ul>

                                                            <li>50 GBNVME SSD space</li>
                                                            <li>500 GBMonthly Bandwidth</li>
                                                            <li>60cPanel Accounts</li>
                                                            <li>AttractaSEO Tools</li>
                                                            <li>15% offVPS Hosting</li>
                                                            <li>CageFS HackProtection</li>
                                                            <li>CloudLinuxOS</li>
                                                            <li>24/7Technical Support</li>
                                                            <li>100%Whitelabeled Hosting</li>
                                                            <li>CloudflareCDN</li>
                                                            <li>99.9%Uptime Guarantee</li>
                                                            <li>FreeSSL Certificate</li>
                                                            <li>3500/Monthly</li>

                                                        </ul>

                                                    </div>

                                                    <a href="" className='bg'>Get Started</a>

                                                </div>

                                            </Col>

                                            
                                            <Col lg={4} md={4}>
                                            
                                                <div className="PackageContentItem">

                                                    
                                                    <div className="PackagePrice">

                                                        <h4>Package Name</h4>
                                                        <h3>BDT 115600/-</h3>
                                                        <h5>Per Month</h5>
                                                        <h6>100GB Reseller HOSTING</h6>

                                                    </div>

                                                    
                                                    <div className="listItem">

                                                        <ul>

                                                            <li>100 GBNVME SSD space</li>
                                                            <li>1 TBMonthly Bandwidth</li>
                                                            <li>100cPanel Accounts</li>
                                                            <li>AttractaSEO Tools</li>
                                                            <li>15% offVPS Hosting</li>
                                                            <li>CageFS HackProtection</li>
                                                            <li>CloudLinuxOS</li>
                                                            <li>24/7Technical Support</li>
                                                            <li>100%Whitelabeled Hosting</li>
                                                            <li>CloudflareCDN</li>
                                                            <li>99.9%Uptime Guarantee</li>
                                                            <li>FreeSSL Certificate</li>
                                                            <li>4999/Monthly</li>

                                                        </ul>

                                                    </div>

                                                    <a href="" className='bg'>Get Started</a>

                                                </div>

                                            </Col>

                                        </Row>

                                    </div>
                                   
                                </Tab> */}

                            {/* </Tabs> */}
                            
                        </div>

                    </Col>

                </Row>
                
            </Container>

        </section>
        
    )


}

export default Package