import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const SupportedOperatingSystem = () => {


    return (

        <section className='SupportedOperatingSystem section_gaps'>

            <Container>

                {/* header */}
                <Row className="justify-content-md-center">

                    <Col md={8}>

                        <div className="Header">
                            <h2><span>Supported</span> Operating Systems</h2>
                        </div>

                    </Col>

                </Row>
                
                <Row className="justify-content-md-center">

                    
                    <Col md={6}>

                        <div className="DataCenterImg">
                            <img src="assets/images/operating.png" alt="" />
                        </div>

                    </Col>

                </Row>
                
            </Container>

        </section>
        
    )


}

export default SupportedOperatingSystem