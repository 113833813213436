/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Container, Row, Col, Button, Nav, NavDropdown, Tabs, Tab } from 'react-bootstrap';

import { Link } from 'react-router-dom';

const Package = () => {


    return (

         <section className='Package'>

            <Container>

                {/* header */}
                <Row className="justify-content-md-center">

                    <Col md={12}>

                        <div className="Header">
                            <h4>The best plan to host multiple websites and to extend their availability!</h4>
                            <h2>Managed <span>Web Hosting</span> service!</h2>
                        </div>

                    </Col>

                </Row>
                
                <Row>
                    
                    <Col md={12}>

                        <div className="PackageContent">

                            <Tabs variant='pills' defaultActiveKey="PackOne" >

                                {/* PackOne */}
                                <Tab eventKey="PackOne" title="Bill Monthly (Pay Per Month)">
                                    
                                    <div className="PackageContentPart">

                                        <Row>

                                            {/* item */}
                                            <Col lg={4} md={4}>

                                                <div className="PackageContentItem">

                                                    {/* <span className='Off'>50% OFF</span> */}
                                                    
                                                    {/* PackagePrice */}
                                                    <div className="PackagePrice">

                                                        <h4>TURBO BOOST</h4>
                                                        <h3>BDT 699 /mo</h3>
                                                        <h5>Per Month</h5>
                                                        <h6></h6>

                                                    </div>

                                                    {/* listItem */}
                                                    <div className="listItem">

                                                        <ul>

                                                            <li>25 GB NVME SSD Storage</li>
                                                            <li>Unmetered Bandwidth</li>
                                                            <li>Unlimited Websites</li>
                                                            <li>FREE SSL Certificates</li>
                                                            <li>CageFS Hack Protection</li>
                                                            <li>CloudLinux OS</li>
                                                            <li>FREE SSL Certificates</li>
                                                            <li>LiteSpeed with LSCache</li>
                                                            <li>Website Toolkit</li>
                                                            <li>Advanced Features</li>
                                                            <li>Security Shield</li>
                                                            <li>99.99% Uptime Guarantee</li>

                                                        </ul>

                                                    </div>

                                                    <a href="https://my.hostwev.com/index.php?rp=/store/web-hosting-bdix/bdix-starter&billingcycle=monthly" className='bg'>Get Started</a>

                                                </div>

                                            </Col>

                                            {/* item */}
                                            <Col lg={4} md={4}>
                                            
                                                <div className="PackageContentItem">

                                                    {/* <span className='Off'>50% OFF</span> */}
                                                    
                                                    {/* PackagePrice */}
                                                    <div className="PackagePrice">

                                                        <h4>TURBO POWER</h4>
                                                        <h3>BDT 1,599 /mo</h3>
                                                        <h5>Per Month</h5>
                                                        <h6></h6>

                                                    </div>

                                                    {/* listItem */}
                                                    <div className="listItem">

                                                        <ul>

                                                            <li>50 GB NVME SSD Storage</li>
                                                            <li>Unmetered Bandwidth</li>
                                                            <li>Unlimited Websites</li>
                                                            <li>FREE SSL Certificates</li>
                                                            <li>CageFS Hack Protection</li>
                                                            <li>CloudLinux OS</li>
                                                            <li>FREE SSL Certificates</li>
                                                            <li>LiteSpeed with LSCache</li>
                                                            <li>Website Toolkit</li>
                                                            <li>Advanced Features</li>
                                                            <li>Security Shield</li>
                                                            <li>99.99% Uptime Guarantee</li>

                                                        </ul>

                                                    </div>

                                                    <a href="https://my.hostwev.com/index.php?rp=/store/web-hosting-bdix/bdix-advanced&billingcycle=monthly" className='bg'>Get Started</a>

                                                </div>

                                            </Col>

                                            {/* item */}
                                            <Col lg={4} md={4}>
                                            
                                                <div className="PackageContentItem">

                                                    {/* <span className='Off'>50% OFF</span> */}
                                                    
                                                    {/* PackagePrice */}
                                                    <div className="PackagePrice">

                                                        <h4>TURBO PRO</h4>
                                                        <h3>BDT 2,099 /mo</h3>
                                                        <h5>Per Month</h5>
                                                        <h6></h6>

                                                    </div>

                                                    {/* listItem */}
                                                    <div className="listItem">

                                                        <ul>

                                                            <li>75 GB NVME SSD Storage</li>
                                                            <li>Unmetered Bandwidth</li>
                                                            <li>Unlimited Websites</li>
                                                            <li>FREE SSL Certificates</li>
                                                            <li>CageFS Hack Protection</li>
                                                            <li>CloudLinux OS</li>
                                                            <li>FREE SSL Certificates</li>
                                                            <li>LiteSpeed with LSCache</li>
                                                            <li>Website Toolkit</li>
                                                            <li>Advanced Features</li>
                                                            <li>Security Shield</li>
                                                            <li>99.99% Uptime Guarantee</li>

                                                        </ul>

                                                    </div>

                                                    <a href="https://my.hostwev.com/index.php?rp=/store/web-hosting-bdix/bdix-premium&billingcycle=monthly" className='bg'>Get Started</a>

                                                </div>

                                            </Col>

                                        </Row>

                                    </div>
                                    
                                </Tab>

                                <Tab eventKey="PackTwo" title="Bill Yearly (Pay Once A Year)">

                                    <div className="PackageContentPart">

                                        <Row>

                                            {/* item */}
                                            <Col lg={4} md={4}>

                                                <div className="PackageContentItem">

                                                    {/* <span className='Off'>50% OFF</span> */}
                                                    
                                                    {/* PackagePrice */}
                                                    <div className="PackagePrice">

                                                        <h4>TURBO BOOST</h4>
                                                        <h3>BDT 7,999 /yr</h3>
                                                        <h5>Yearly</h5>
                                                        <h6></h6>

                                                    </div>

                                                    {/* listItem */}
                                                    <div className="listItem">

                                                        <ul>

                                                            <li>25 GB NVME SSD Storage</li>
                                                            <li>Unmetered Bandwidth</li>
                                                            <li>Unlimited Websites</li>
                                                            <li>FREE SSL Certificates</li>
                                                            <li>CageFS Hack Protection</li>
                                                            <li>CloudLinux OS</li>
                                                            <li>FREE SSL Certificates</li>
                                                            <li>LiteSpeed with LSCache</li>
                                                            <li>Website Toolkit</li>
                                                            <li>Advanced Features</li>
                                                            <li>Security Shield</li>
                                                            <li>99.99% Uptime Guarantee</li>

                                                        </ul>

                                                    </div>

                                                    <a href="https://my.hostwev.com/index.php?rp=/store/web-hosting-bdix/bdix-starter&billingcycle=annually" className='bg'>Get Started</a>

                                                </div>

                                            </Col>

                                            {/* item */}
                                            <Col lg={4} md={4}>
                                            
                                                <div className="PackageContentItem">

                                                    {/* <span className='Off'>50% OFF</span> */}
                                                    
                                                    {/* PackagePrice */}
                                                    <div className="PackagePrice">

                                                        <h4>TURBO POWER</h4>
                                                        <h3>BDT 15,999 /yr</h3>
                                                        <h5>Yearly</h5>
                                                        <h6></h6>

                                                    </div>

                                                    {/* listItem */}
                                                    <div className="listItem">

                                                        <ul>

                                                            <li>50 GB NVME SSD Storage</li>
                                                            <li>Unmetered Bandwidth</li>
                                                            <li>Unlimited Websites</li>
                                                            <li>FREE SSL Certificates</li>
                                                            <li>CageFS Hack Protection</li>
                                                            <li>CloudLinux OS</li>
                                                            <li>FREE SSL Certificates</li>
                                                            <li>LiteSpeed with LSCache</li>
                                                            <li>Website Toolkit</li>
                                                            <li>Advanced Features</li>
                                                            <li>Security Shield</li>
                                                            <li>99.99% Uptime Guarantee</li>

                                                        </ul>

                                                    </div>

                                                    <a href="https://my.hostwev.com/index.php?rp=/store/web-hosting-bdix/bdix-advanced&billingcycle=annually" className='bg'>Get Started</a>

                                                </div>

                                            </Col>

                                            {/* item */}
                                            <Col lg={4} md={4}>
                                            
                                                <div className="PackageContentItem">

                                                    {/* <span className='Off'>50% OFF</span> */}
                                                    
                                                    {/* PackagePrice */}
                                                    <div className="PackagePrice">

                                                        <h4>TURBO PRO</h4>
                                                        <h3>BDT 23,999 /yr</h3>
                                                        <h5>Yearly</h5>
                                                        <h6></h6>

                                                    </div>

                                                    {/* listItem */}
                                                    <div className="listItem">

                                                        <ul>

                                                            <li>75 GB NVME SSD Storage</li>
                                                            <li>Unmetered Bandwidth</li>
                                                            <li>Unlimited Websites</li>
                                                            <li>FREE SSL Certificates</li>
                                                            <li>CageFS Hack Protection</li>
                                                            <li>CloudLinux OS</li>
                                                            <li>FREE SSL Certificates</li>
                                                            <li>LiteSpeed with LSCache</li>
                                                            <li>Website Toolkit</li>
                                                            <li>Advanced Features</li>
                                                            <li>Security Shield</li>
                                                            <li>99.99% Uptime Guarantee</li>

                                                        </ul>

                                                    </div>

                                                    <a href="https://my.hostwev.com/index.php?rp=/store/web-hosting-bdix/bdix-premium&billingcycle=annually" className='bg'>Get Started</a>

                                                </div>

                                            </Col>

                                        </Row>

                                    </div>
                                   
                                </Tab>

                            </Tabs>
                            
                        </div>

                    </Col>

                </Row>
                
            </Container>

        </section>
        
    )


}

export default Package