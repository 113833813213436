import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { AiOutlineCloud } from 'react-icons/ai';
import { FaAws, FaCpanel, FaDigitalOcean, FaEdge } from 'react-icons/fa';
import { SiOpenstack } from 'react-icons/si';
import { Link } from 'react-router-dom';

const Partner = () => {


    return (

        <section className='Partner'>

            <Container>
                
                <Row>

                    
                    <Col md={12}>

                        <div className="PartnerContent d_flex d_justify">

                            {/* item */}
                            <div className="PartnerImg">
                                <FaCpanel/>
                            </div>

                            {/* item */}
                            <div className="PartnerImg">
                                <AiOutlineCloud/>
                            </div>

                            {/* item */}
                            <div className="PartnerImg">
                                <FaAws/>
                            </div>

                            {/* item */}
                            <div className="PartnerImg">
                                <SiOpenstack />
                            </div>

                            {/* item */}
                            <div className="PartnerImg">
                                <FaEdge />
                            </div>

                            {/* item */}
                            <div className="PartnerImg">
                                <FaDigitalOcean />
                            </div>
                            
                        </div>

                    </Col>

                </Row>
                
            </Container>

        </section>
        
    )


}

export default Partner