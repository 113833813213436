import React from 'react';
import Header from '../Components/CommonComponents/Header';
import Menu from '../Components/CommonComponents/Menu';
import Footer from '../Components/CommonComponents/Footer';
import OthersBanner from '../Components/CommonComponents/OthersBanner';
import WhatSsl from '../Components/GSuitePage/WhatSsl';
import Package from '../Components/GSuitePage/Package';
import Question from '../Components/GSuitePage/Question';
import WhereToStart from '../Components/CommonComponents/WhereToStart';
import Partner from '../Components/CommonComponents/Partner';


const GSuite = () => {

    let header={
        header:'G Suite by',
        span:'Google Service',
        description:'We like to think of a dedicated server like owning your own house. You can do whatever you’d like. This leads to increased speed, reliability, and security',
    }



    return (

        <>
        
            {/* Header */}
            <Header></Header>

            {/* Menu */}
            <Menu active='email'></Menu>

            {/* banner */}
            <OthersBanner header={header}></OthersBanner>

            {/* WhatSsl */}
            <WhatSsl></WhatSsl>

            {/* WhatSsl */}
            <Package></Package>

            {/* Question */}
            <Question></Question>

            {/* HostingSolution */}
            <WhereToStart></WhereToStart>

            {/* Partner */}
            <Partner></Partner>
            
            {/* Footer */}
            <Footer></Footer>

            


        </>

    )



}

export default GSuite