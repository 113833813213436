import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const OpenVzVps = () => {


    return (

        <>
        
            {/* Single Processor */}
            <section className='OpenVzVps'>

                <Container>

                    <div className='OpenVzVpsContent SslCertificate'>

                        <Row>

                            <Col md={12}>

                                {/* header */}
                                <div className="OpenVzVpHeader d_flex">

                                    {/* item */}
                                    <div className="OpenVzVpHeaderItem d_flex d_center">
                                        <h4>PRODUCT NAME</h4>
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpHeaderItem d_flex d_center">
                                        <h4>1 YEAR PRICE</h4>
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpHeaderItem d_flex d_center">
                                        <h4>2 YEAR PRICE</h4>
                                    </div>
                                    
                                    {/* item */}
                                    {/* <div className="OpenVzVpHeaderItem d_flex d_center">
                                        <h4></h4>
                                    </div> */}
                                    
                                </div>

                                {/* Content */}
                                <div className="OpenVzVpMiddleContent">

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>Rapid SSL</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT 1,449</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT 2,799</h4>
                                            </div>

                                            {/* item */}
                                            <div className="Position">
                                                <a href="https://my.hostwev.com/index.php?rp=/store/ssl-certificate/positive-ssl"><button className='bg'>Order Now</button></a>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>RapidSSL Wildcard</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT 6,899</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>Not Available</h4>
                                            </div>

                                            {/* item */}
                                            <div className="Position">
                                            <a href="https://my.hostwev.com/index.php?rp=/store/ssl-certificate/positive-ssl-wildcard"><button className='bg'>Order Now</button></a>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BusinessID EV</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT 16,788</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>Not Available</h4>
                                            </div>

                                            {/* item */}
                                            <div className="Position">
                                                <a href="https://my.hostwev.com/index.php?rp=/store/ssl-certificate/ev-ssl"><button className='bg'>Order Now</button></a>
                                            </div>

                                        </div>
                                        
                                    </div>


                                </div>
                                

                            </Col>

                        </Row>

                    </div>
                    
                </Container>

            </section>

        </>
        
    )


}

export default OpenVzVps
