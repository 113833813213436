import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const ResellerHostingSolution = () => {


    return (

        <section className='ResellerHostingSolution section_gaps'>

            <Container>
                
                <Row className="justify-content-md-center">

                    
                    <Col md={8}>

                        <div className="Header">
                            
                            <h4>Find the Reseller Hosting Plan that's Right for Your Business</h4>
                            <h2>Get Updated<span>With Our Blogs</span></h2>

                        </div>

                    </Col>

                </Row>
                
            </Container>

        </section>
        
    )


}

export default ResellerHostingSolution