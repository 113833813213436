import React from 'react';
import Header from '../Components/CommonComponents/Header';
import Menu from '../Components/CommonComponents/Menu';
import Partner from '../Components/CommonComponents/Partner';
import Footer from '../Components/CommonComponents/Footer';
import WhereToStart from '../Components/CommonComponents/WhereToStart';
import Map from '../Components/ContactUsPage/Map';
import CompanyAddress from '../Components/ContactUsPage/CompanyAddress';
import OthersBanner from '../Components/CommonComponents/OthersBanner';


const ContactUs = () => {


    let header={
        header:'Contact ',
        span:'Us',
        description:'We like to think of a dedicated server like owning your own house. You can do whatever you’d like. This leads to increased speed, reliability, and security',
    }


    return (

        <>
        
            {/* Header */}
            <Header active='contactus'></Header>

            {/* Menu */}
            <Menu active='contactus'></Menu>

            {/* banner */}
            <OthersBanner header={header}></OthersBanner>

            {/* Maps */}
            <Map></Map>

            {/* CompanyAddress */}
            <CompanyAddress></CompanyAddress>
            

            {/* HostingSolution */}
            <WhereToStart></WhereToStart>

            {/* Partner */}
            <Partner></Partner>

             {/* Footer */}
             <Footer></Footer>

            


        </>

    )



}

export default ContactUs