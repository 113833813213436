import React from 'react';
import Header from '../Components/CommonComponents/Header';
import Menu from '../Components/CommonComponents/Menu';
import Footer from '../Components/CommonComponents/Footer';
import OthersBanner from '../Components/CommonComponents/OthersBanner';
import ResellerHostingSolution from '../Components/TurboHostingPage/ResellerHostingSolution';
import Package from '../Components/TurboHostingPage/Package';
import Partner from '../Components/CommonComponents/Partner';
import OpenVzVps from '../Components/TurboHostingPage/OpenVzVps';
import HostingFeature from '../Components/CommonComponents/HostingFeature';
import WhatMakeUsBest from '../Components/CommonComponents/WhatMakeUsBest';
import WhereToStart from '../Components/CommonComponents/WhereToStart';
import ClientReview from '../Components/CommonComponents/ClientReview';



const WebHosting = () => {

    let header={
        header:'Extremely fast',
        span:'Turbo Hosting',
        description:'Loading speed optimized, cPanel-based Web Hosting service built on top of state of the art Cloud Platform with fast and redundant SSD Storage!',
    }



    return (

        <>
        
            {/* Header */}
            <Header></Header>

            {/* Menu */}
            <Menu active='hosting'></Menu>

            {/* banner */}
            <OthersBanner header={header}></OthersBanner>

            {/* HostingReseller */}
            <ResellerHostingSolution></ResellerHostingSolution>

            {/* Package */}
            <Package></Package>

            {/* OpenVzVps */}
            <OpenVzVps></OpenVzVps>

            {/* HostingFeature */}
            <HostingFeature></HostingFeature>

            {/* WhatMakeUsBest */}
            <WhatMakeUsBest></WhatMakeUsBest>

            {/* ClientReview */}
            <ClientReview></ClientReview>

            {/* HostingSolution */}
            <WhereToStart></WhereToStart>

            {/* Partner */}
            <Partner></Partner>

            {/* Footer */}
            <Footer></Footer>



        </>

    )



}

export default WebHosting