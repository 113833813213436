import React from 'react';
import Header from '../Components/CommonComponents/Header';
import Menu from '../Components/CommonComponents/Menu';
import Partner from '../Components/CommonComponents/Partner';
import Footer from '../Components/CommonComponents/Footer';
import WhereToStart from '../Components/CommonComponents/WhereToStart';
import OthersBanner from '../Components/CommonComponents/OthersBanner';
import WhatSsl from '../Components/AboutUsPage/WhatSsl';
import HostWebComponent from '../Components/AboutUsPage/HostWebComponent';
import MeetOurteam from '../Components/AboutUsPage/MeetOurteam';


const AboutUs = () => {

    let header={
        header:'About',
        span:'Us',
        description:'We like to think of a dedicated server like owning your own house. You can do whatever you’d like. This leads to increased speed, reliability, and security',
    }



    return (

        <>
        
            {/* Header */}
            <Header active='aboutus'></Header>

            {/* Menu */}
            <Menu active='aboutus'></Menu>

            {/* banner */}
            <OthersBanner header={header}></OthersBanner>

            {/* WhatSsl */}
            <WhatSsl></WhatSsl>

            {/* HostWeb */}
            <HostWebComponent></HostWebComponent>

            {/* Meet Our Team */}
            {/* <MeetOurteam></MeetOurteam> */}

            {/* HostingSolution */}
            <WhereToStart></WhereToStart>

            {/* Partner */}
            <Partner></Partner>

            {/* Footer */}
            <Footer></Footer>

        </>

    )


}

export default AboutUs