import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { AiOutlineFieldTime } from 'react-icons/ai';

const WhatMakeUsBest = () => {


    return (

        <section className='WhatMakeUsBest section_gaps'>

            <Container>

                {/* header */}
                <Row className="justify-content-md-center">

                    <Col md={10}>

                        <div className="Header">
                            <h4>No matter which service you choose, you can expect the highest performance.</h4>
                            <h2><span>Why Choose Hostwev for </span> Your Hosting Needs?</h2>
                        </div>

                    </Col>

                </Row>
                
                <div className="WhatMakeUsBestContent">

                    <Row>

                        {/* item */}
                        <Col md={6} lg={3}>

                            <div className="WhatMakeUsBestItem">
                                
                                {/* time */}
                                <div className="Time">
                                    <img src="assets/images/time-left.png" alt="ime-left" />
                                </div>

                                {/* number */}
                                <div className="Number">
                                    <h5>01</h5>
                                </div>

                                {/* text */}
                                <div className="Content">

                                    <h3>99.99% Uptime Guarantee</h3>
                                    <p>Hostwev is the host you can depend on with ultra-reliable servers!</p>

                                </div>

                            </div>

                        </Col>

                        {/* item */}
                        <Col md={6} lg={3}>

                            <div className="WhatMakeUsBestItem MoneyBack">
                                
                                {/* time */}
                                <div className="Time">
                                    <img src="assets/images/money-back-guarantee.png" alt="ime-left" />
                                </div>

                                {/* number */}
                                <div className="Number">
                                    <h5>02</h5>
                                </div>

                                {/* text */}
                                <div className="Content">

                                    <h3>Money-Back Guarantee</h3>
                                    <p>Give our high-speed hosting service a try completely risk-free!</p>

                                </div>

                            </div>

                        </Col>

                        {/* item */}
                        <Col md={6} lg={3}>

                            <div className="WhatMakeUsBestItem headphone">
                                
                                {/* time */}
                                <div className="Time">
                                    <img src="assets/images/headphone.png" alt="headphone" />
                                </div>

                                {/* number */}
                                <div className="Number">
                                    <h5>03</h5>
                                </div>

                                {/* text */}
                                <div className="Content">

                                    <h3>24/7/365 support</h3>
                                    <p>We work when you work. Whenever that happens to be.</p>

                                </div>

                            </div>

                        </Col>

                        {/* item */}
                        <Col md={6} lg={3}>

                            <div className="WhatMakeUsBestItem SslCertificate">
                                
                                {/* time */}
                                <div className="Time">
                                    <img src="assets/images/ssl-certificate.png" alt="ssl-certificate" />
                                </div>

                                {/* number */}
                                <div className="Number">
                                    <h5>04</h5>
                                </div>

                                {/* text */}
                                <div className="Content">

                                    <h3>Free Unlimited SSL Certificate</h3>
                                    <p>FREE Let's Encrypt SSL certificates with just a few clicks in your cPanel.</p>

                                </div>

                            </div>

                        </Col>

                    </Row>

                </div>

                
                
            </Container>

        </section>
        
    )


}

export default WhatMakeUsBest