import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const HostWebComponent = () => {


    return (

        <section className='HostWebComponent section_gaps'>

            <Container>

                <Row className="justify-content-md-center">

                    <Col md={8}>

                        <div className="Header">
                            
                            <div className="HostWebComponentLogo">
                                <h2>Our <span>Staff</span> </h2>
                            </div>
                            
                        </div>

                    </Col>

                </Row>
                
                <Row className='d_flex'>

                    {/* content */}
                    <Col md={12}>

                        <div className="HostWebComponent">
                            
                            <p>We believe that a good company consists of well-educated and experienced people. Our industry-recognized experts have a wide range of experiences gained through consulting and direct positions at various business levels, including but not limited to: Fortune 500's, start-ups, non-profits, and government organizations.</p>

                            <p>Our team members hold degrees from the most prestigious schools, as well as certificates from the top industry leaders, thus assisting us in building strong relationships while giving confidence to our clients and partners. We interact with a wide variety of organizations to ensure that we always have access to the knowledge, skills, and research that our clients need to be successful.</p>

                        </div>

                        <div className="Header section_gaps">
                            <h2>We Are <span>Members Of</span></h2>
                        </div>

                        <div className="MembersImg text-center">
                            <img src="assets/images/MembersOf.png" alt="" />
                        </div>

                    </Col>

                </Row>
                
            </Container>

        </section>
        
    )


}

export default HostWebComponent