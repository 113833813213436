import React from 'react';
import Header from '../Components/CommonComponents/Header';
import Menu from '../Components/CommonComponents/Menu';
import Footer from '../Components/CommonComponents/Footer';
import OthersBanner from '../Components/CommonComponents/OthersBanner';
import OpenVzVps from '../Components/DedicatedServerPage/OpenVzVps';
import WhereToStart from '../Components/CommonComponents/WhereToStart';
import SupportedOperatingSystem from '../Components/CommonComponents/SupportedOperatingSystem';
import VirtualServer from '../Components/DedicatedServerPage/VirtualServer';
import Partner from '../Components/CommonComponents/Partner';


const DedicatedServer = (props) => {

    let header={
        header:'Build Your Own',
        span:'Dedicated Server',
        description:'We like to think of a dedicated server like owning your own house. You can do whatever you’d like. This leads to increased speed, reliability, and security',
    }


    return (

        <>
        
            {/* Header */}
            <Header></Header>

            {/* Menu */}
            <Menu active='server'></Menu>

            {/* banner */}
            <OthersBanner header={header}></OthersBanner>

            {/* OpenVzVps */}
            <OpenVzVps></OpenVzVps>

            {/* SupportedOperatingSystem */}
            <SupportedOperatingSystem></SupportedOperatingSystem>

            {/* VirtualServer */}
            <VirtualServer></VirtualServer>

            {/* HostingSolution */}
            <WhereToStart></WhereToStart>

            {/* Partner */}
            <Partner></Partner>

            {/* Footer */}
            <Footer></Footer>

            


        </>

    )



}

export default DedicatedServer