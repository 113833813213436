import React from 'react';
import ClientReview from '../Components/CommonComponents/ClientReview';
import DataCenter from '../Components/CommonComponents/DataCenter';
import Header from '../Components/CommonComponents/Header';
import Menu from '../Components/CommonComponents/Menu';
import WhatMakeUsBest from '../Components/CommonComponents/WhatMakeUsBest';
import Partner from '../Components/CommonComponents/Partner';
import Footer from '../Components/CommonComponents/Footer';
import WhereToStart from '../Components/CommonComponents/WhereToStart';
import GetYourDomain from '../Components/HomePageComponents/GetYourDomain';
import HomeBanner from '../Components/HomePageComponents/HomeBanner';
import HostingSolution from '../Components/HomePageComponents/HostingSolution';
import OurSpecial from '../Components/HomePageComponents/OurSpecial';


const Home = () => {



    return (

        <>
        
            {/* Header */}
            <Header></Header>

            {/* Menu */}
            <Menu active='home'></Menu>

            {/* Banner */}
            <HomeBanner></HomeBanner>

            {/* OurSpecial */}
            <OurSpecial></OurSpecial>

            {/* Get Your Domain */}
            <GetYourDomain></GetYourDomain>

            {/* HostingSolution */}
            <HostingSolution></HostingSolution>

            {/* WhatMakeUsBest */}
            <WhatMakeUsBest></WhatMakeUsBest>

            {/* HostingSolution */}
            <DataCenter></DataCenter>

            {/* ClientReview */}
            <ClientReview></ClientReview>

            {/* HostingSolution */}
            <WhereToStart></WhereToStart>

            {/* Partner */}
            <Partner></Partner>

             {/* Footer */}
             <Footer></Footer>

            


        </>

    )



}

export default Home