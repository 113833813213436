import React from 'react';
import Header from '../Components/CommonComponents/Header';
import Menu from '../Components/CommonComponents/Menu';
import Partner from '../Components/CommonComponents/Partner';
import Footer from '../Components/CommonComponents/Footer';
import WhereToStart from '../Components/CommonComponents/WhereToStart';
import OthersBanner from '../Components/CommonComponents/OthersBanner';
import TermsOfServiceContent from '../Components/AffiliatesProgramPage/TermsOfServiceContent';


const AffiliatesProgram = () => {

    let header={
        header:'AffiliatesProgram',
        span:'Program',
        description:'A dedicated team of Affiliate specialists are available to assist you. There is absolutely no cost to you to be a Hostwev Affiliate, and the earning potential is substantial!',
    }



    return (

        <>
        
            {/* Header */}
            <Header></Header>

            {/* Menu */}
            <Menu></Menu>

            {/* banner */}
            <OthersBanner header={header}></OthersBanner>

            {/* TermsOfServiceContent */}
            <TermsOfServiceContent></TermsOfServiceContent>

            {/* HostingSolution */}
            <WhereToStart></WhereToStart>

            {/* Partner */}
            <Partner></Partner>

            {/* Footer */}
            <Footer></Footer>

        </>

    )


}

export default AffiliatesProgram