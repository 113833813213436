import React, { useRef, useState } from "react";
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { Pagination, Autoplay } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

const ClientReview = () => {


    return (

        <section className='ClientReview section_gaps'>

            {/* SideText */}
            <div className="SideText">
                <h6><span>Clients</span> Say</h6>
            </div>

            <div className="ClientReviewBg">
                <img src="assets/images/review_bg.png" alt="" />
            </div>

            <Container>

                {/* Header */}
                <Row className="d_flex">

                    <Col md={3}>

                        <div className="Header">

                            <div className="img">
                                <img src="assets/images/Qotes.png" alt="Qotes" />
                            </div>

                        </div>

                    </Col>

                    <Col md={9}>

                        <div className="Header">
                            <h2><span>What Our Customers </span> Have to Say</h2>
                        </div>

                    </Col>

                </Row>
                
                {/* ClientReviewSlider */}
                <div className="ClientReviewSlider">

                    <Swiper
                        centeredSlides={true}
                        grabCursor={true}
                        breakpoints={{
                            320: {
                              slidesPerView: 1,
                              spaceBetween: 20,
                            },
                            768: {
                              slidesPerView: 2,
                              spaceBetween: 20,
                            },
                            1024: {
                              slidesPerView: 3,
                              spaceBetween: 30,
                            },
                        }}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        pagination={{
                        clickable: true,
                        }}
                        modules={[Pagination, Autoplay]}
                        className="mySwiper"
                    >
                        {/* item */}
                        <SwiperSlide>

                            <div className="ClientSliderItem">

                                {/* HeaderPart */}
                                <div className="ProfilePart d_flex">

                                    <div className="img">
                                        <img src="assets/images/mahbubul.jpg" alt="profile" />
                                    </div>

                                    <div className="text">
                                        <h4>Mahabubul Hasan</h4>
                                        <p>CEO</p>
                                    </div>

                                </div>

                                <p>You guys are soooo nice! I never get a hasty remark or get treated like I'm a bother to you. That is very important to me. You've got a great service and great customer service. Who could want anything else when they can have it all at Hostwev? Don't ever change the way you are!!! I will continually recommend you folks to anyone who inquires about starting a website!</p>

                            </div>

                        </SwiperSlide>

                        {/* item */}
                        <SwiperSlide>

                            <div className="ClientSliderItem">

                                {/* HeaderPart */}
                                <div className="ProfilePart d_flex">

                                    <div className="img">
                                        <img src="assets/images/Steven.png" alt="profile" />
                                    </div>

                                    <div className="text">
                                        <h4>Steven G.</h4>
                                        <p>Founder & CEO</p>
                                    </div>

                                </div>

                                <p>Hostwev has been a great host for numerous WordPress sites that have Turbo servers, as well as a reseller sever for our smaller clients. The servers are fast, the software abilities inside the server are wonderful and their phone support is fantastic.</p>

                            </div>

                        </SwiperSlide>

                        {/* item */}
                        <SwiperSlide>

                            <div className="ClientSliderItem">

                                {/* HeaderPart */}
                                <div className="ProfilePart d_flex">

                                    <div className="img">
                                        <img src="assets/images/iqbal.jpg" alt="profile" />
                                    </div>

                                    <div className="text">
                                        <h4>Iqbal Hossain Rasel</h4>
                                        <p>Managing Director</p>
                                    </div>

                                </div>

                                <p>I bought a domain and hosting yesterday. Price was reasonable for other domain and hosting provider. Customer Service!, It was outstanding. I wish them for their success.</p>

                            </div>

                        </SwiperSlide>

                        {/* item */}
                        <SwiperSlide>

                            <div className="ClientSliderItem">

                                {/* HeaderPart */}
                                <div className="ProfilePart d_flex">

                                    <div className="img">
                                        <img src="assets/images/profile.png" alt="profile" />
                                    </div>

                                    <div className="text">
                                        <h4>Den Robert</h4>
                                        <p>Lead Engineer</p>
                                    </div>

                                </div>

                                <p>The Hostwev support is really great, some of the best support people I've ever encountered. They did not fix the issue though and told me to sign up for the WordPress forum which I'm not doing. Outside that, the Hostwev service as a whole is really great.</p>

                            </div>

                        </SwiperSlide>

                    </Swiper>

                </div>
                
            </Container>

        </section>
        
    )


}

export default ClientReview