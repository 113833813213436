import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const WhatSsl = () => {


    return (

        <section className='WhatSsl section_gaps'>

            <Container>
                
                {/* item */}
                <Row className='d_flex'>

                    {/* content */}
                    <Col md={7}>

                        <div className="Header">
                            
                            <h2>FREE FULLY <br /> <span> BRANDABLE STOREFRONT</span></h2>
                          
                            <div className="SubHeading">

                            <ul>
                                <li>Modify the HTML, CSS, Images and Content. Ensure that your Brand Stands Out.</li>
                                <li>Offer Discounts, Bundle Products & Upsell Discounted Products.</li>
                                <li>50+ pages of Readymade Editable Content to Help Enhance your SEO Score.</li>
                                <li>Use a Template which Specifically Offers the Products you wish to Sell.</li>
                                <li>User-Friendly Shopping Cart that Gives Customers a Seamless Buying Experience.</li>
                            </ul>

                        </div>

                        </div>

                    </Col>

                    {/* img */}
                    <Col md={5}>

                        <div className="WhatSslImg">
                            <img src="assets/images/term1.png" alt="" />
                        </div>

                    </Col>

                </Row>

                {/* item */}
                <Row className='d_flex TermsTop'>

                    {/* img */}
                    <Col md={5}>

                        <div className="WhatSslImg">
                            <img src="assets/images/term2.png" alt="" />
                        </div>

                    </Col>

                    {/* content */}
                    <Col md={7}>

                        <div className="Header Right">
                            
                            <h2>SELL PRODUCTS <br /> <span> & MANAGE CUSTOMERS</span></h2>
                          
                            <div className="SubHeading">

                            <ul>
                                <li>Custom Branding for Your Business</li>
                                <li>Easily Manage your Customers and Sub-Resellers</li>
                                <li>FREE Fully-Customizable Storefront to Sell. (DEMO)</li>
                                <li>Brandable Client Panel with your company logo</li>
                                <li>Completely White Labelled Program</li>
                            </ul>

                        </div>

                        </div>

                    </Col>

                </Row>

                {/* item */}
                <Row className='d_flex TermsTop'>

                    {/* content */}
                    <Col md={7}>

                        <div className="Header">
                            
                            <h2>Financial <br /> <span> Management & Support</span></h2>
                          
                            <div className="SubHeading">

                            <ul>
                                <li>Proven Billing, Customer & Order Management Panels.</li>
                                <li>Take advantage of our fantastic Coupon engine to offer discounts to your customers.</li>
                                <li>Easy integration with our HTTP API</li>
                                <li>MARKETING, PAYMENT PROCESSING & SUPPORT</li>
                                <li>Industry leading Prices & fantastic Promotions.</li>
                            </ul>

                        </div>

                        </div>

                    </Col>

                    {/* img */}
                    <Col md={5}>

                        <div className="WhatSslImg">
                            <img src="assets/images/term2.png" alt="" />
                        </div>

                    </Col>

                </Row>
                
            </Container>

        </section>

        
        
    )


}

export default WhatSsl