/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Container, Row, Col, Button, Dropdown, DropdownButton } from 'react-bootstrap';



const HomeBanner = () => {
    const [url ,setUrl] = useState("")
     const [value,setValue]=useState(".com");
   
    const urlSubmit = (e) => {
        const value =e.target.value;
        setUrl(value.replace(/\s/g, ''));
    }
    const handleSelect=(e)=>{
        setValue(e.replace(/\s/g, ''));
      }
    
    console.log(value)

    return (

        <section className='HomeBanner'>

            {/* SideText */}
            <div className="SideText">
                <h6><span>Get</span> Next Level</h6>
            </div>

            <Container>
                
                <Row className='d_flex'>

                    {/* text */}
                    <Col sm={12} lg={6}>

                        <div className="HomeBannerContent">
                            
                            <h4>High-powered VPS hosting now with double the RAM and faster performance using next generation NVMe SSD storage.</h4>
                            <h1>Secure <span>web hosting</span> that grows with your business!</h1>

                            {/* SearchDomain */}
                            <div className="SearchDomain">
                                <form></form>

                                {/* www */}
                                <div className="www">
                                    <h5>www</h5>
                                </div>

                                <input type="text" placeholder='Type your domain..' onBlur={urlSubmit} />
                                {/* <input type="submit" /> */}

                                <Button><a href={`http://my.hostwev.com/cart.php?a=add&domain=register&query=${url.replace(/\s/g,'')}+${value}`}>Search</a></Button>

                                <Dropdown>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    {value}
                                    </Dropdown.Toggle>

                                    <DropdownButton onSelect={handleSelect}>
                                        <Dropdown.Item eventKey=".com">.com</Dropdown.Item>
                                        <Dropdown.Item eventKey=".net">.net</Dropdown.Item>
                                        <Dropdown.Item eventKey=".org">.org</Dropdown.Item>
                                        <Dropdown.Item eventKey=".me">.me</Dropdown.Item>
                                        <Dropdown.Item eventKey=".xyz">.xyz</Dropdown.Item>
                                        <Dropdown.Item eventKey=".in">.in</Dropdown.Item>
                                        <Dropdown.Item eventKey=".co.in">.co.in</Dropdown.Item>
                                        <Dropdown.Item eventKey=".top">.top</Dropdown.Item>
                                        <Dropdown.Item eventKey=".info">.info</Dropdown.Item>
                                    </DropdownButton>
                                     {/* {/* <select {...register("gender")}> */}
    
                                    
                                </Dropdown>
                                {/* <select {...register("gender")} id="dropdown-basic">
        <option value="female">female</option>
        <option value="male">male</option>
        <option value="other">other</option>
      </select> */}
      

                            </div>

                        </div>

                    </Col>

                    {/* img */}
                    <Col sm={12} lg={6}>

                        <div className="HomeBannerImg">
                            <img src="assets/images/home_banner.png" alt="home_banner" />
                        </div>

                    </Col>

                </Row>

                {/* DotCom */}
                <div className="DotCom">

                    <Row>

                        {/* Item */}
                        <Col xs={6} md={4} lg={2}>

                            <div className="DotComItem">
                                <a href="#">
                                    <h6>.com</h6>
                                    <p>Only BDT 1449/-</p>
                                    <div className="underline">
                                        <img src="assets/images/underline.png" alt="" />
                                    </div>
                                </a>
                            </div>

                        </Col>

                        {/* Item */}
                        <Col xs={6} md={4} lg={2}>

                            <div className="DotComItem xyz">
                                <a href="#">
                                    <h6>.xyz</h6>
                                    <p>Only BDT 349/-</p>
                                    <div className="underline">
                                        <img src="assets/images/underline.png" alt="" />
                                    </div>
                                </a>
                            </div>

                        </Col>

                        {/* Item */}
                        <Col xs={6} md={4} lg={2}>

                            <div className="DotComItem net">
                                <a href="#">
                                    <h6>.net</h6>
                                    <p>Only BDT 1599/-</p>
                                    <div className="underline">
                                        <img src="assets/images/underline.png" alt="" />
                                    </div>
                                </a>
                            </div>

                        </Col>

                        {/* Item */}
                        <Col xs={6} md={4} lg={2}>

                            <div className="DotComItem org">
                                <a href="#">
                                    <h6>.org</h6>
                                    <p>Only BDT 1495/-</p>
                                    <div className="underline">
                                        <img src="assets/images/underline.png" alt="" />
                                    </div>
                                </a>
                            </div>

                        </Col>

                        {/* Item */}
                        <Col xs={6} md={4} lg={2}>

                            <div className="DotComItem biz">
                                <a href="#">
                                    <h6>.biz</h6>
                                    <p>Only BDT 1894/-</p>
                                    <div className="underline">
                                        <img src="assets/images/underline.png" alt="" />
                                    </div>
                                </a>
                            </div>

                        </Col>

                        {/* Item */}
                        <Col xs={6} md={4} lg={2}>

                            <div className="DotComItem info">
                                <a href="#">
                                    <h6>.info</h6>
                                    <p>Only BDT 2294/-</p>
                                    <div className="underline">
                                        <img src="assets/images/underline.png" alt="" />
                                    </div>
                                </a>
                            </div>

                        </Col>

                    </Row>

                </div>

                {/* HostingContent */}
                <div className="HostingContent section_gaps">

                    <Row>

                        {/* item */}
                	    <Col md={6} lg={3}>
                        
                            <div className="HostingItem">

                                <h3>Web Hosting</h3>
                                <img src="assets/images/hosting.png" alt="hosting" />
                                <p>With Host Wev Shared Hosting you get all the features, tools, and guidance you need to build and launch truly impressive Your websites.</p>

                                {/* Starting From */}
                                <div className="StartingFrom">
                                    <h4>Starting From</h4>
                                    <h3>BDT 150/<span>Month</span></h3>
                                    <a href="/web-hosting" className='bg'>Get Started</a>
                                </div>

                            </div>

                        </Col>

                        {/* item */}
                	    <Col md={6} lg={3}>
                        
                            <div className="HostingItem two">

                                <h3>Reseller Hosting</h3>
                                <img src="assets/images/database.png" alt="hosting" />
                                <p>For high traffic sites and larger businesses</p>

                                {/* Starting From */}
                                <div className="StartingFrom">
                                    <h4>Starting From</h4>
                                    <h3>BDT 1,599/<span>Month</span></h3>
                                    <a href="/hosting-reseller" className='bg'>Get Started</a>
                                </div>

                            </div>

                        </Col>

                        {/* item */}
                	    <Col md={6} lg={3}>
                        
                            <div className="HostingItem three">

                                <h3>Virtual Servers</h3>
                                <img src="assets/images/Virtual.png" alt="hosting" />
                                <p>Whether you’re building one high performance site or an army of them, our plans flex to your needs.</p>

                                {/* Starting From */}
                                <div className="StartingFrom">
                                    <h4>Starting From</h4>
                                    <h3>BDT 2,599/<span>Month</span></h3>
                                    <a href="/vartual-server" className='bg'>Get Started</a>
                                </div>

                            </div>

                        </Col>
                        
                        {/* item */}
                	    <Col md={6} lg={3}>
                        
                            <div className="HostingItem four">

                                <h3>Dedicated Servers</h3>
                                <img src="assets/images/data-server.png" alt="hosting" />
                                <p>Dedicated Server Hosting on fully-managed servers powered by cPanel. Get industry-leading hardware at an affordable dedicated server price.</p>

                                {/* Starting From */}
                                <div className="StartingFrom">
                                    <h4>Starting From</h4>
                                    <h3>BDT 20,999/<span>Month</span></h3>
                                    <a href="/dedicated-derver" className='bg'>Get Started</a>
                                </div>

                            </div>

                        </Col>

                    </Row>

                </div>
                
            </Container>

        </section>
        
    )


}

export default HomeBanner
