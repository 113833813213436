import React from 'react';
import Header from '../Components/CommonComponents/Header';
import Menu from '../Components/CommonComponents/Menu';
import Footer from '../Components/CommonComponents/Footer';
import OthersBanner from '../Components/CommonComponents/OthersBanner';
import ResellerHostingSolution from '../Components/WebHostingPage/ResellerHostingSolution';
import Package from '../Components/WebHostingPage/Package';
import Partner from '../Components/CommonComponents/Partner';
import OpenVzVps from '../Components/WebHostingPage/OpenVzVps';
import HostingFeature from '../Components/CommonComponents/HostingFeature';
import WhatMakeUsBest from '../Components/CommonComponents/WhatMakeUsBest';
import WhereToStart from '../Components/CommonComponents/WhereToStart';
import ClientReview from '../Components/CommonComponents/ClientReview';



const WebHosting = () => {

    let header={
        header:'Super Fast',
        span:'Web Hosting',
        description:'Hosting plans for any website and any business. Find the perfect plan for you.',
    }



    return (

        <>
        
            {/* Header */}
            <Header></Header>

            {/* Menu */}
            <Menu active='hosting'></Menu>

            {/* banner */}
            <OthersBanner header={header}></OthersBanner>

            {/* HostingReseller */}
            <ResellerHostingSolution></ResellerHostingSolution>

            {/* Package */}
            <Package></Package>

            {/* OpenVzVps */}
            <OpenVzVps></OpenVzVps>

            {/* HostingFeature */}
            <HostingFeature></HostingFeature>

            {/* WhatMakeUsBest */}
            <WhatMakeUsBest></WhatMakeUsBest>

            {/* ClientReview */}
            <ClientReview></ClientReview>

            {/* HostingSolution */}
            <WhereToStart></WhereToStart>

            {/* Partner */}
            <Partner></Partner>

            {/* Footer */}
            <Footer></Footer>



        </>

    )



}

export default WebHosting