import React from 'react';
import Header from '../Components/CommonComponents/Header';
import Menu from '../Components/CommonComponents/Menu';
import Footer from '../Components/CommonComponents/Footer';
import OthersBanner from '../Components/CommonComponents/OthersBanner';
import OpenVzVps from '../Components/VpsPageComponent/OpenVzVps';
import WhereToStart from '../Components/CommonComponents/WhereToStart';
import SupportedOperatingSystem from '../Components/CommonComponents/SupportedOperatingSystem';
import VirtualServer from '../Components/VpsPageComponent/VirtualServer';
import Partner from '../Components/CommonComponents/Partner';


const Vps = () => {

    let header={
        header:'Fully Managed',
        span:'NVMe SSD VPS',
        description:'VPS Hosting provides dedicated resources so you know your website will perform as expected.',
    }



    return (

        <>
        
            {/* Header */}
            <Header></Header>

            {/* Menu */}
            <Menu active='server'></Menu>

            {/* banner */}
            <OthersBanner header={header}></OthersBanner>

            {/* OpenVzVps */}
            <OpenVzVps></OpenVzVps>

            {/* SupportedOperatingSystem */}
            <SupportedOperatingSystem></SupportedOperatingSystem>

            {/* VirtualServer */}
            <VirtualServer></VirtualServer>

            {/* HostingSolution */}
            <WhereToStart></WhereToStart>

            {/* Partner */}
            <Partner></Partner>

            {/* Footer */}
            <Footer></Footer>

            


        </>

    )



}

export default Vps