import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const HostingFeature = () => {


    return (

        <section className='HostingFeature section_gaps'>

            <Container>
                
                <Row>

                    
                    <Col md={12}>

                        <div className="Header">
                            
                            {/*<h4>Find the Reseller Hosting Plan that's Right for Your Business</h4>*/}
                            <h2><span>Features</span> Included</h2>

                        </div>

                    </Col>

                </Row>

                <div className="HostingFeatureContent">

                    <Row>

                        {/* item */}
                        <Col md={6} lg={3}>

                            <div className="HostingFeatureItem">
                                
                                <ul>

                                    <li> <img src="assets/images/thik.png" alt="" /> 7 Day Money Back Guarantee</li>
                                    <li> <img src="assets/images/thik.png" alt="" /> 99.99% Server Uptime Guarantee</li>
                                    <li> <img src="assets/images/thik.png" alt="" /> Unlimited MySQL Databases</li>
                                    <li> <img src="assets/images/thik.png" alt="" /> Unlimited E-Mail Accounts</li>
                                    <li> <img src="assets/images/thik.png" alt="" /> Unlimited FTP Accounts</li>
                                    <li> <img src="assets/images/thik.png" alt="" /> 24/7 Technical Support</li>
                                    <li> <img src="assets/images/thik.png" alt="" /> Clustered Failover DNS</li>
                                    <li> <img src="assets/images/thik.png" alt="" /> 1 Hour Response Time</li>

                                </ul>

                            </div>

                        </Col>

                        {/* item */}
                        <Col md={6} lg={3}>

                            <div className="HostingFeatureItem scd">
                                
                                <ul>

                                    <li> <img src="assets/images/thik.png" alt="" /> FREE cPanel Migration</li>
                                    <li> <img src="assets/images/thik.png" alt="" /> Unlimited Sub Domains</li>
                                    <li> <img src="assets/images/thik.png" alt="" /> Malware & Exploit Scanning</li>
                                    <li> <img src="assets/images/thik.png" alt="" /> FREE Let's Encrypt SSL Certs</li>
                                    <li> <img src="assets/images/thik.png" alt="" /> Mariadb 10.x / phpMyAdmin</li>
                                    <li> <img src="assets/images/thik.png" alt="" /> Malware & Exploit Scanning</li>
                                    <li> <img src="assets/images/thik.png" alt="" /> CloudFlare CDN</li>
                                    <li> <img src="assets/images/thik.png" alt="" /> Ruby On Rails</li>

                                </ul>

                            </div>

                        </Col>

                        {/* item */}
                        <Col md={6} lg={3}>

                            <div className="HostingFeatureItem">
                                
                                <ul>

                                    <li> <img src="assets/images/thik.png" alt="" /> SSH Access Available</li>
                                    <li> <img src="assets/images/thik.png" alt="" /> ionCube PHP Loader</li>
                                    <li> <img src="assets/images/thik.png" alt="" /> Unlimited Auto Responders</li>
                                    <li> <img src="assets/images/thik.png" alt="" /> 20 GBPS DDoS Protection</li>
                                    <li> <img src="assets/images/thik.png" alt="" /> Awstats & Webalizer</li>
                                    <li> <img src="assets/images/thik.png" alt="" /> Spam Protection</li>
                                    <li> <img src="assets/images/thik.png" alt="" /> Zend Guard Loader</li>
                                    <li> <img src="assets/images/thik.png" alt="" /> Node.js Version Selector</li>

                                </ul>

                            </div>

                        </Col>

                        {/* item */}
                        <Col md={6} lg={3}>

                            <div className="HostingFeatureItem scd">
                                
                                <ul>

                                    <li> <img src="assets/images/thik.png" alt="" /> Latest cPanel</li>
                                    <li> <img src="assets/images/thik.png" alt="" /> LitesSpeed Web Server</li>
                                    <li> <img src="assets/images/thik.png" alt="" /> CloudLinux / CageFS</li>
                                    <li> <img src="assets/images/thik.png" alt="" /> Curl / GD2 / ImageMagick</li>
                                    <li> <img src="assets/images/thik.png" alt="" /> Python Version Selector</li>
                                    <li> <img src="assets/images/thik.png" alt="" /> Server Side Includes</li>
                                    <li> <img src="assets/images/thik.png" alt="" /> PHP Selector (5.4 - 7.x)</li>
                                    {/*<li> <img src="assets/images/thik.png" alt="" /> </li>*/}

                                </ul>

                            </div>

                        </Col>

                    </Row>

                </div>
                
                
            </Container>

        </section>
        
    )


}

export default HostingFeature