import React from 'react';
import Header from '../Components/CommonComponents/Header';
import Menu from '../Components/CommonComponents/Menu';
import Footer from '../Components/CommonComponents/Footer';
import OthersBanner from '../Components/CommonComponents/OthersBanner';
import ResellerHostingSolution from '../Components/BDIXTurboPage/ResellerHostingSolution';
import Package from '../Components/BDIXTurboPage/Package';
import Partner from '../Components/CommonComponents/Partner';
import OpenVzVps from '../Components/BDIXTurboPage/OpenVzVps';
import HostingFeature from '../Components/CommonComponents/HostingFeature';
import WhatMakeUsBest from '../Components/CommonComponents/WhatMakeUsBest';
import WhereToStart from '../Components/CommonComponents/WhereToStart';
import ClientReview from '../Components/CommonComponents/ClientReview';



const WebHosting = () => {

    let header={
        header:'20X Faster',
        span:'BDIX Turbo Hosting',
        description:'Turbo plans are built for speed. Limited occupancy, upgraded server hardware, advanced caching software, optimized configurations, and a variety of performance add-ons combine to give you one of the fastest web hosting experiences out there.',
    }



    return (

        <>
        
            {/* Header */}
            <Header></Header>

            {/* Menu */}
            <Menu active='hosting'></Menu>

            {/* banner */}
            <OthersBanner header={header}></OthersBanner>

            {/* HostingReseller */}
            <ResellerHostingSolution></ResellerHostingSolution>

            {/* Package */}
            <Package></Package>

            {/* OpenVzVps */}
            <OpenVzVps></OpenVzVps>

            {/* HostingFeature */}
            <HostingFeature></HostingFeature>

            {/* WhatMakeUsBest */}
            <WhatMakeUsBest></WhatMakeUsBest>

            {/* ClientReview */}
            <ClientReview></ClientReview>

            {/* HostingSolution */}
            <WhereToStart></WhereToStart>

            {/* Partner */}
            <Partner></Partner>

            {/* Footer */}
            <Footer></Footer>



        </>

    )



}

export default WebHosting