import React from 'react';
import Header from '../Components/CommonComponents/Header';
import Menu from '../Components/CommonComponents/Menu';
import Partner from '../Components/CommonComponents/Partner';
import Footer from '../Components/CommonComponents/Footer';
import WhereToStart from '../Components/CommonComponents/WhereToStart';
import OthersBanner from '../Components/CommonComponents/OthersBanner';
import Package from '../Components/BusinessEmailPage/Package';
import ResellerHostingSolution from '../Components/BusinessEmailPage/ResellerHostingSolution';
import AboutBusinessEmail from '../Components/BusinessEmailPage/AboutBusinessEmail';


const BusinessEmail = () => {

    let header={
        header:'Email',
        span:'Hosting',
        description:'As opposed to free email solutions, you can give your business a more professional image with Business Email by getting email that is branded with your company’s domain name (ex. sales@yourcompany.com). In addition, you also benefit from our advanced email technology that gives you the least latency and industry-best uptime.',
    }



    return (

        <>
        
            {/* Header */}
            <Header active='email'></Header>

            {/* Menu */}
            <Menu active='email'></Menu>

            {/* banner */}
            <OthersBanner header={header}></OthersBanner>

            {/* WhatSsl */}
            <ResellerHostingSolution></ResellerHostingSolution>

            {/* WhatSsl */}
            <Package></Package>

            {/* WhatSsl */}
            <AboutBusinessEmail></AboutBusinessEmail>

            {/* HostingSolution */}
            <WhereToStart></WhereToStart>

            {/* Partner */}
            <Partner></Partner>

            {/* Footer */}
            <Footer></Footer>

        </>

    )


}

export default BusinessEmail