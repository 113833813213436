import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const GetYourDomain = () => {


    return (

        <section className='GetYourDomain'>

            <Container>
                
                <Row>

                    
                    <Col md={6}>

                        <div className="GetYourDomainContents">
                            
                            <h2><span className='slimwhite'>Up To 20X</span> <span className='slimcolor'><br /> </span>Faster Web Hosting to <br /> <span className='slimwhite'></span> <span className='bold'>Help You Succeed!</span></h2>

                            {/* DualButton */}
                            <div className="DualButton">

                                <Link to="/web-hosting" className='bg'>Get started</Link>
                                {/* <Link to="/" className='bg border'>Get Demo</Link> */}

                            </div>

                        </div>

                    </Col>

                    <Col md={6}>

                        <div className="GetYourDomainImg">
                            <img src="assets/images/GetYourDomainImg.png" alt="" />
                        </div>

                    </Col>

                </Row>
                
            </Container>

        </section>
        
    )


}

export default GetYourDomain