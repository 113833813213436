/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Container, Row, Col, Button, Nav, NavDropdown, Tabs, Tab } from 'react-bootstrap';

import { Link } from 'react-router-dom';

const Package = () => {


    return (

        <section className='Package'>

            <Container>
                
                <Row className='d_flex d_justify BusinessPackage'>
                    
                    {/* item */}
                    <Col md={6}>

                        <div className="PackageContent">

                            <div className="PackageContentItem">

                                {/* <span className='Off'>50% OFF</span> */}
                                
                                {/* PackagePrice */}
                                <div className="PackagePrice">

                                    <div className="img">
                                        <img src="assets/images/businessEmail1.svg" alt="" />
                                    </div>

                                    <h4>Business Email</h4>
                                    <h6>Give your business a professional makeover with email@yourcompany.com</h6>
                                    <h3>BDT 99 /-</h3>
                                    <h5>Per Month</h5>
                                    {/* <h6>Regular Price : <del>2499/-</del></h6>
                                    <span>Coupon: FREE50</span> */}

                                </div>

                                {/* listItem */}
                                <div className="listItem">

                                    <ul>

                                        <li>5GB Storage/account</li>
                                        <li>Intuitive & Responsive Design</li>
                                        <li>Inbuilt Virus Protection</li>
                                        <li>100% Uptime and Security</li>
                                        <li>5GB Storage Plus Backup</li>
                                        <li>Calendars and Contacts</li>

                                    </ul>

                                </div>

                                <a href="https://my.hostwev.com/index.php?rp=/store/business-email/business-email" className='bg'>Get Started</a>

                            </div>
                            
                        </div>

                    </Col>

                    {/* item */}
                    <Col md={6}>

                        <div className="PackageContent">

                            <div className="PackageContentItem">

                                {/* <span className='Off'>50% OFF</span> */}
                                
                                {/* PackagePrice */}
                                <div className="PackagePrice">

                                    <div className="img">
                                        <img src="assets/images/businessEmail2.svg" alt="" />
                                    </div>

                                    <h4>Enterprise Email</h4>
                                    <h6>Advanced and Corporate-class email</h6>
                                    <h3>BDT 349 /-</h3>
                                    <h5>Per Month</h5>
                                    {/* <span>Coupon: FREE50</span> */}

                                </div>

                                {/* listItem */}
                                <div className="listItem">

                                    <ul>

                                        <li>30GB Storage/account</li>
                                        <li>Intuitive & Responsive Design</li>
                                        <li>Inbuilt Virus Protection</li>
                                        <li>100% Uptime and Security</li>
                                        <li>30GB Storage Plus Backup</li>
                                        <li>Calendars and Contacts</li>

                                    </ul>

                                </div>

                                <a href="https://my.hostwev.com/index.php?rp=/store/business-email/enterprise-email" className='bg'>Get Started</a>

                            </div>
                            
                        </div>

                    </Col>

                </Row>
                
            </Container>

        </section>
        
    )


}

export default Package