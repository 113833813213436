import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const TermsOfServiceContent = () => {


    return (

        <section className='TermsOfServiceContent section_gaps'>

            <Container>
                
                <Row>

                    
                    <Col md={12}>

                        <div className="Header">

                            <h2>Privecy <span> Policy</span></h2>

                            <p>Customer agrees when using, or accessing Hostwev services that the terms and conditions of this policy will be met: </p>

                            <p>User agrees to use the service in manner consistent with any and all applicable laws. In the event that this account is not used in accordance with all  Applicable Laws including, but not limited to Local, State or Federal Laws, Hostwev reserves the right to cooperate with all or any law enforcement agencies. </p>

                            <p>User agrees to follow the Acceptable Use Policy of any network user connects to, including Hostwev system. </p>

                            <p>Neither Hostwev nor its Information Providers are responsible for any damages arising from User's use of Hostwev or by User's inability to use Hostwev service. </p>

                            <p>User hereby agrees that any material submitted for publication on Hostwev through user's account(s) does not violate or infringe any copyright, trademark, patent, statutory, common law or proprietary rights of others, or contain anything obscene or libelous. User's may not host any hardcore adult content or porn websites on our shared, reseller servers. Software intended to facilitate any such violations or infringements may not be stored on Hostwev. </p>
                            <p>The following violations of "netiquette" are grounds for immediate suspension of service pending investigation by Hostwev and will result in termination of the account(s) the investigation determines to have originated or transmitted these types of traffic. </p>
                            <p>Posting a single article or substantially similar articles to an excessive number of newsgroups (i.e., 20 or more) or continued posting of articles which are off-topic (e.g., off-topic according to the newsgroup charter or the article provokes complaints from the regular readers of the newsgroup for being off-topic). </p>
                            <p>Sending unsolicited mass e-mailings which provoke complaints from the recipients. </p>
                            <p>Engaging in either of the above from a provider other than Hostwev and using an account on Hostwev as a mail drop for responses, or advertising a web site hosted at Hostwev. </p>
                            <p>Continued harassment of other individuals on the Internet after being asked to stop by those individuals and by Hostwev. </p>
                            <p>Impersonating another user or otherwise falsifying one's user name in email, Usenet postings, on IRC, or with any other Internet service. (This does not preclude the use of nicknames in IRC or the use of anonymous remailer services.) </p>
                            <p>Hostwev prohibits IRC and associated programs, including psybnc, "bots", etc., scripts commonly used for abuse, attacks, or flooding threatening, harassing, or obscene content and any proxies which do not require authentication, including open mail proxies, unprotected anonymous web surfing proxies.</p>
                            <p>Users whose accounts are terminated for any of the above infractions are also responsible for the cost of labor to cleanup and respond to complaints incurred by Hostwev. </p>
                            <p>User agree to not use excessive amounts of resources. Any violations may result in us taking corrective action in order maintain server stability by killing any processes, disabling and/or suspending your account.  </p>
                            <p>User may not use more than 100% CPU out of 1 CPU Core and the usage is limited to 1 CPU core, 1GB memory, 1024/Kbps IO - Read & write operations, no more than 100 NPROC - Processes or no more than 20 Entry Process. Cron jobs must not execute more than once every 30 minutes unless if specified by the script provider and will be niced to 15 or greater. Each user account may not send more than 300 emails per hour.  </p>
                            <p>If it is determined that a customer's account is overusing the system resources, we may temporarily suspend the account to prevent the server from experiencing any issues, resulting problems to other valued customers. If it is determined that the account is not suitable for the shared or reseller server environment, a notice will be sent to the customer providing them alternative solutions or such as a Premium Hosting Plan with high resources, VPS or a Dedicated Server. Hostwev will be the sole arbiter as to what constitutes a violation of this provision and Hostwev reserves the right to make adjustments to these limits at our sole discretion. </p>
                            <p>All unmetered dedicated servers and virtual private servers are installed on hundred mbps shared bandwidth ports, unless service is sold as a specified port size or specified/requested as a capped port. Hostwev reserves the right to monitor network usage, and if a service significantly exceeds average usage, to throttle the available speed to conserve network resources. This will generally not include short spikes in usage, but will include, but not be limited to, sustained transfers of significantly larger than average amounts of bandwidth. If at any time any client's data transfer rate affects other Hostwev clientele, Hostwev reserves the right to throttle the service causing such a problem. </p>
                            <p>Hostwev performs daily or daily and weekly backups on all of our servers to ensure critical files are never lost. Our daily and weekly backups are created for accounts that are under 20GB and 150,000 Inodes. These limits apply to each cPanel user individually. Furthermore, Hostwev does not guarantee this feature to any of the clients, and therefore strongly advise the client to regularly perform a backup of their own data, hosted with us. In the unfortunate event that the client has lost their data hosted with Hostwev, will use reasonable endeavors to restore the client's data; however Hostwev will not be responsible for lost data, time, income or any other resource due to faulty backups or non-existent back-ups or any data loss due to faulty hardware. We highly encourage our customers to have their own separate backups if your website or data is critical to your business or livelihood as no backup method is failsafe therefore we make no warranties. </p>
                            <p>The use of more than 50,000 inodes on any cPanel account (single user) may result in automatic warning on cPanel > Stats > inodes. There will be no service interruption at this stage but if no action is taken to reduce the inodes, service interruption may take place once a user reach 150,000 Inodes. Accounts found to be exceeding the 150,000 inode limit will automatically show resource exceeded message and may be suspended if no action is taken to reduce the inode count. Virtual private servers have a server wide limit of 5 million and there are no per cPanel user limits. Users with reseller plans have a reseller wide limit of 1 million inodes in addition to the per user limit, however this reseller limit is not something we actively enforce and it will only become an issue if a reseller is causing problems for other resellers on the server. If a resellers inode usage is in between 5 to 10 million inodes we may request the reseller to upgrade to a dedicated server or provide adequate time to resolve the inode usage, if no action is taken by the reseller, account may be suspended. Budget resellers have a per user limit of 50,000 inodes and a 250,000 reseller wide limit of inodes. If this limit is exceeded, we may request the budget reseller to upgrade to a normal reseller plan or provide adequate time to resolve the inode usage, if no action is taken by the budget reseller, account may be suspended. </p>
                            <p>Every file (a web page, image file, email, etc) on your account uses up 1 inode. Sites that slightly exceed our inode limits are unlikely to be suspended; however, accounts that constantly create and delete large numbers of files on a regular basis, have hundreds of thousands of files or resellers with millions of files that cause file system damage may be flagged for review and/or suspension. The primary cause of excessive inodes seems to be due to users leaving their catchall address enabled, but never checking their primary account mailbox. Over time, tens of thousands of messages (or more) build up, eventually pushing the account past our inode limit. To disable your default mailbox, login to cPanel and choose "Mail", then "Default Address", "Set Default Address", and then type in: :fail: No such user here. </p>
                            <p>User MUST NOT use our hosting services to offer any of the following kinds of FREE services but are not limited to; Free Web hosting services, Free email services, Free blogging services, Free home pages, Free image hosting, Free trial accounts. </p>
                            <p>If you violate this prohibition on free services, we may suspend your account immediately, with or without notice, as we in our sole discretion deem necessary to address the situation.</p>
                            <p>File hosting and file storage of any type is strictly prohibited on any and all reseller, shared and hosting servers. You MAY NOT use your hosting with Hostwev as a download or file storage repository. Our services are strictly offered for website hosting only. This does NOT mean that you can not have downloads on your site. This means you can not operate a site that specializes in downloads like Download.com or similar and you may not use your account as a storage repository for MP3, games, video, photos, images, audio or music files or as a backup storage facility. </p>
                            <p>Abuse of Hostwev Staff or Support Personnel: At Hostwev, we take pride in providing excellent service to all of our customers. It is our policy always to treat our customers with the highest level of respect and courtesy. In return, we expect the same respect and courtesy from you. If our staff feels that you are consistently engaging in abusive content toward them, or addressing them in a demeaning or rude manner, your account(s) may be suspended and you may be asked to take your business elsewhere. In the event that we terminate service for abusive conduct, customers will be given five (5) days’ notice. We will issue a refund only for the unused portion of pre-paid service. Abusive conduct includes, but is not limited to, the following behaviors: </p>
                            <p>Repeatedly addressing members of our staff in a demeaning or rude manner. Using profanity in any oral or written communications with our staff, by any medium of communication, including but not limited to email, instant messages, chat, text messaging, fax, postal mail, telephone, voice over Internet Protocol, or in-person communication. Yelling or shouting at our staff. Deliberately using all capital (uppercase) letters in any written communication to our staff; Insulting our staff because of their personal characteristics, or on the basis of their race, ethnicity, national origin, sex, sexual orientation, religion, or housing or economic status; or Deliberately providing false information to our staff for the purpose of harassing them or wasting their time. </p>

                        </div>

                        {/* SubHeading */}
                        <div className="SubHeading">

                            <h5>Payment Issues </h5>
                            <p>User agrees to pay Hostwev all charges relating to the use of User's account(s) according to rates and prices stated on said sales order at the time of commencement. </p>
                            <p>User is responsible for charges at the time the service is used and Hostwev may apply the amount due to User's credit card at any time. </p>
                            <p>Hostwev reserves the right to suspend access to service for User's account(s) upon an indication of credit problems including delinquent payments or rejection of any credit card charges. </p>
                            <p>Credit Card payment: If Customer chooses to pay for services by credit card, Customer grants Hostwev the right to charge payments to that card on each due date. Customer further agrees not to challenge or dispute any charge representing hosting payments. </p>
                            <p>Non-Payment: All web hosting payments are due in full on the monthly anniversary date. Failure to remit payment for services on the monthly anniversary date is a violation of the TOS. Failure to remit payment for five (5) consecutive days, including the anniversary date, may result in suspension of customer services. Failure to remit payment for services within seven (7) consecutive days, including the anniversary date, may result in termination of access to the service network and all services shall be reclaimed. All customer data remaining after fourteen (14) days of non-payment will be destroyed for security and privacy reasons.  </p>
                            <p>All Dedicated Server and Virtual Private Server payments are due in full on the monthly anniversary date. If full payment has not been received within three (3) consecutive days, including the anniversary date, suspension of public access to customer services may be incurred. Failure to remit payment for services within five (5) consecutive days, including the anniversary date, shall result in termination of access to the service network and all services shall be reclaimed. </p>
                            <p>Payment Reminders: Our credit card processor will remit payment for all recurring orders, for services on the monthly anniversary date. It is clients responsibility to make sure the sufficient funds are available on their credit card payment account. It is the customer’s sole responsibility to make sure that dues are paid on time and the email address on file is working and allowed to receive reminders Hostwev. Once a payment reminder dispatched from Hostwev. it is considered as a valid payment reminder. Hostwev does not take any responsibility for non deliverable email addresses or email sent by Hostwev. not reaching the client destinations, including but not limited to spam filters or network issues. For the first day after payment should have been made; you will receive a payment reminder notice that states you must update your credit card and pay your invoice. The third day and the fifth day, second and third payment reminders will be sent us. From the seventh day customer services may be suspended temporarily for non-payment. </p>

                        </div>

                        {/* SubHeading */}
                        <div className="SubHeading">

                            <h5>Payment Issues </h5>
                            <p>User agrees to pay Hostwev all charges relating to the use of User's account(s) according to rates and prices stated on said sales order at the time of commencement. </p>
                            <p>User is responsible for charges at the time the service is used and Hostwev may apply the amount due to User's credit card at any time. </p>
                            <p>Hostwev reserves the right to suspend access to service for User's account(s) upon an indication of credit problems including delinquent payments or rejection of any credit card charges. </p>
                            <p>Credit Card payment: If Customer chooses to pay for services by credit card, Customer grants Hostwev the right to charge payments to that card on each due date. Customer further agrees not to challenge or dispute any charge representing hosting payments. </p>
                            <p>Non-Payment: All web hosting payments are due in full on the monthly anniversary date. Failure to remit payment for services on the monthly anniversary date is a violation of the TOS. Failure to remit payment for five (5) consecutive days, including the anniversary date, may result in suspension of customer services. Failure to remit payment for services within seven (7) consecutive days, including the anniversary date, may result in termination of access to the service network and all services shall be reclaimed. All customer data remaining after fourteen (14) days of non-payment will be destroyed for security and privacy reasons.  </p>
                            <p>All Dedicated Server and Virtual Private Server payments are due in full on the monthly anniversary date. If full payment has not been received within three (3) consecutive days, including the anniversary date, suspension of public access to customer services may be incurred. Failure to remit payment for services within five (5) consecutive days, including the anniversary date, shall result in termination of access to the service network and all services shall be reclaimed. </p>
                            <p>Payment Reminders: Our credit card processor will remit payment for all recurring orders, for services on the monthly anniversary date. It is clients responsibility to make sure the sufficient funds are available on their credit card payment account. It is the customer’s sole responsibility to make sure that dues are paid on time and the email address on file is working and allowed to receive reminders Hostwev. Once a payment reminder dispatched from Hostwev. it is considered as a valid payment reminder. Hostwev does not take any responsibility for non deliverable email addresses or email sent by Hostwev. not reaching the client destinations, including but not limited to spam filters or network issues. For the first day after payment should have been made; you will receive a payment reminder notice that states you must update your credit card and pay your invoice. The third day and the fifth day, second and third payment reminders will be sent us. From the seventh day customer services may be suspended temporarily for non-payment. </p>

                        </div>

                        {/* SubHeading */}
                        <div className="SubHeading">

                            <h5>Public Feedback </h5>
                            <p>We have a zero tolerance policy for posting negative feedback on any public locations about our company and/or our employees before submitting a ticket to our "Suggestions & Complaints" Department to discuss your issue, this will result in immediate account termination without any refund. </p>
                            <p>Although it is your right to post feedback without discussing your issue with us first, it is undoubtedly unfair and unprofessional not to, which is not the type of client that we want to deal with. We have the utmost respect for all of our clients, and are more than fair with all of our clients. We will do anything and everything possible to keep all of our clients satisfied. But in the unlikely event you are not satisfied, all that we ask is to discuss your issue with our Complaints Department first. For example, if you are the type of person to run to the forums at the first chance you get to complain, please DO NOT SIGN UP with Hostwev in the first place. </p>
                            <p>Furthermore, all of our policies that pertain to keeping information given to us confidential including but not limited to our privacy policy, confidentiality agreement, non-disclosure agreement are nullified once you discuss your experience publicly. For example, if you make a negative post in public, then you are waiving your protection of privacy. </p>

                        </div>

                        {/* SubHeading */}
                        <div className="SubHeading">

                            <h5>Refunds</h5>
                            <p>Without waiving any of its other rights under this Agreement, Hostwev offers to its Customers a 7-day money-back guarantee on fees for hosting services. If for any reason you cancel your account within thirty (7) days starting from the day Hostwev dispatch your welcome email including your account information or the day you place the order with us, whichever comes first. Hostwev will refund your money with no questions asked; provided, however, that you have never previously obtained a refund under the 7-Day Guarantee. If you have ever previously obtained a refund under the 7-Day Guarantee, your account will be canceled, but no money will be refunded to you. </p>
                            <p>Changes to your service, including, but not limited to, adding new services, removing services, or changing the type of hosting plan you have do NOT make you eligible for an additional 7-Day Guarantee. The 7-Day Guarantee applies to your first order of Web hosting services from Hostwev and does not apply to any changes to your service at any time. </p>
                            <p>Our refund policy does not apply to any additional items or services, this includes but not limited to Domain Registration, Domain Parking services, dedicated servers, virtual private servers and overage fees. </p>

                        </div>

                        {/* SubHeading */}
                        <div className="SubHeading">

                            <h5>Cancellations </h5>
                            <p>Customers may voluntarily cancel their account at any time, for any reason or for no reason, by requesting a cancellation from your members area at Hostwev > Services > My Services > Product/Service > View Details > Management Actions > Request Cancellation. Hostwev requires a five (5) day cancellation notice prior to the anniversary billing date for discontinuance or downgrades of month to month services. Failure to supply the requisite five (5) days will result in a full billable monthly cycle prior to cancellation. All due amounts must be settled in full before we cancel the customer account. Customer understands and agrees that Hostwev does not provide pro-rated or any other kinds of refunds on cancellations. All fees Customer has paid shall be nonrefundable, unless stated otherwise under our any 7 day money back guarantee. </p>
                            <p>All cancellation requests must be made directly via https://my.hostwev.com . Cancellations requests are not accepted by email, postal mail, by phone or via the help desk (https://my.hostwev.com/submitticket.php). If a customer canceled a recurring order by contacting any 3rd parties, we will consider it as an account cancellation request. In such cases, Hostwev reserves the right to terminate the customer account with immediate effect without any prior notice. </p>

                        </div>

                        {/* SubHeading */}
                        <div className="SubHeading">

                            <h5>Agreement  </h5>
                            <p>Hostwev may modify these terms and conditions upon notice published online via Hostwev. User's use of Hostwev services after such notice shall constitute user's acceptance of the modifications to this Agreement. </p>
                            <p>This agreement, User account, contract, and billing will automatically renew unless cancelled in writing prior to the renewal date. </p>

                        </div>

                    </Col>

                </Row>
                
            </Container>

        </section>
        
    )


}

export default TermsOfServiceContent