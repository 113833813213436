import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { SlCalender } from 'react-icons/sl';
import { Link } from 'react-router-dom';

const BlogSingleContent = () => {


    return (

        <section className='BlogSingleContent section_gaps'>

            <Container>
                
                <Row>

                    
                    <Col md={12}>

                        <div className="BlogItem">
                            
                            <div className="img">
                                <img src="assets/images/single_blog.png" alt="" />
                            </div>

                            <div className="text">
                                
                                <h3>Acquiring a great domain can take persistence</h3>

                                <div className="Calender">
                                    <h5 className='d_flex'> <SlCalender/> 20 October 2022</h5>
                                </div>
                                
                                <p>Acquiring a great domain that matches your brand can pay off in spades. Carl Hancock, co-founder of the popular WordPress tool Gravity Forms, knows this firsthand. Ever since launching Gravity Forms in 2009, Hancock’s company has used the domain gravityforms.com.</p>
                                <p>Diam ornare hendrerit in nullam est fermentum sodales a. Ut mauris et felis, cras libero in tincidunt lorem vulputate. Quis viverra mauris, vitae volutpat pulvinar velit. Condimentum at proin pretium malesuada sed nec, fusce bibendum porttitor. Malesuada nulla odio proin arcu at consequat faucibus nisi, consequat. Bibendum tellus integer in et libero fermentum turpis fringilla condimentum. Nulla turpis diam diam, lacus id turpis aenean tellus. Tempor leo montes, in auctor. Malesuada fringilla gravida ante nunc, aenean lorem donec. Risus ut tellus in varius vel odio aenean. Aliquam mauris lobortis vulputate feugiat pretium tristique. Malesuada dui ac pellentesque nibh pellentesque etiam venenatis tincidunt. Vehicula feugiat convallis magna lacus tellus ac nisl elit ac. Lectus ut arcu commodo faucibus ipsum sed diam sed.</p>
                                <p> Et est ac feugiat id amet tristique integer mattis nec. Magna sit eget etiam pellentesque aliquam. Porttitor dui nunc, posuere nulla turpis in nulla. Sit morbi eget faucibus vulputate mauris felis ultrices velit. Tellus et mollis arcu eleifend tempus, phasellus aenean. Lacus sed in nunc sit sit vestibulum, in luctus. A, risus, hendrerit vitae ullamcorper nunc maecenas augue eu enim. Nibh nec nulla sed viverra. Eu aliquet integer maecenas praesent donec. Augue integer pellentesque sapien viverra non. Dui, diam aliquet phasellus pellentesque ut nibh donec. Diam sed tortor egestas nisl aliquam, ultrices tristique tristique consequat. Et, in tortor interdum pellentesque felis aliquam.</p>
                                <p>Hac enim, habitasse diam ac molestie cursus libero diam sit. Sem eleifend pellentesque a pulvinar eget libero, aliquet. Lorem purus at posuere vel vel, sagittis. Et luctus enim hac at tortor, quisque. Vel donec vel fringilla vitae, nibh ut ac. Commodo tincidunt faucibus penatibus sapien, justo. Imperdiet ante ac, libero non sed viverra vitae consectetur. Morbi tincidunt tristique facilisis quam. Amet tempus id congue porta nulla quis enim vulputate elit. Non lectus imperdiet egestas et sapien volutpat orci quis integer. Non arcu platea mattis morbi phasellus dignissim eget id. Dui, volutpat ipsum egestas luctus in et felis aliquam. Duis in pharetra magnis erat. </p>
                                <p>Varius quis posuere pulvinar aliquet tempus, semper amet facilisis. Consequat consequat blandit eu, lectus purus quis at nulla. Posuere dictum turpis nunc orci ullamcorper. Sit nunc amet tortor non blandit massa turpis id. Quis in senectus eu maecenas et. Commodo natoque massa sagittis purus facilisis fermentum. Urna nisl aenean id tincidunt suspendisse. Diam proin nulla amet volutpat et vel urna. Tincidunt varius eget senectus auctor eget. Convallis senectus cursus egestas in vitae mi.</p>

                            </div>

                        </div>

                    </Col>

                </Row>
                
            </Container>

        </section>
        
    )


}

export default BlogSingleContent