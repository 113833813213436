import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const OthersBanner = (props) => {


    return (

        <section className='OthersBanner'>

            <Container>
                
                <Row>

                    
                    <Col md={12}>

                        <div className="OthersBannerContent">

                            <div className="Header">

                                <h2>{props.header.header} <span>{props.header.span} </span></h2>
                                <p>{props.header.description}</p>

                            </div>
                            
                        </div>

                    </Col>

                </Row>
                
            </Container>

        </section>
        
    )


}

export default OthersBanner