import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const DataCenter = () => {


    return (

        <section className='DataCenter section_gaps'>

            {/* SideText */}
            <div className="SideText">
                <h6><span>Data</span> Centers</h6>
            </div>

            <Container>

                {/* header */}
                <Row className="justify-content-md-center">

                    <Col md={10}>

                        <div className="Header">
                            <h4>Fast, reliable, and secure web hosting services for all your business needs.</h4>
                            <h2><span>Our </span> Global Datacenters</h2>
                        </div>

                    </Col>

                </Row>
                
                <Row>

                    
                    <Col md={12}>

                        <div className="DataCenterImg">
                            <img src="assets/images/data_center.png" alt="" />
                        </div>

                    </Col>

                </Row>
                
            </Container>

        </section>
        
    )


}

export default DataCenter