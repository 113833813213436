import React from 'react';
import Header from '../Components/CommonComponents/Header';
import Menu from '../Components/CommonComponents/Menu';
import Partner from '../Components/CommonComponents/Partner';
import Footer from '../Components/CommonComponents/Footer';
import WhereToStart from '../Components/CommonComponents/WhereToStart';
import OthersBanner from '../Components/CommonComponents/OthersBanner';
import HomeBanner from '../Components/DomainRegisterPage/HomeBanner';
import DomainPrice from '../Components/DomainRegisterPage/DomainPrice';


const DomainRegister = () => {

    let header={
        header:'Domain',
        span:'Registration',
        description:'It All Begins with a Domain Name Search',
    }



    return (

        <>
        
            {/* Header */}
            <Header></Header>

            {/* Menu */}
            <Menu active="domain"></Menu>

            {/* banner */}
            <OthersBanner header={header}></OthersBanner>

            {/* TermsOfServiceContent */}
            <HomeBanner></HomeBanner>

            {/* DomainPrice */}
            <DomainPrice></DomainPrice>

            {/* HostingSolution */}
            <WhereToStart></WhereToStart>

            {/* Partner */}
            <Partner></Partner>

            {/* Footer */}
            <Footer></Footer>

        </>

    )


}

export default DomainRegister