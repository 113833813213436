import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const WhatSsl = () => {


    return (

        <section className='WhatSsl section_gaps'>

            <Container>
                
                <Row className='d_flex'>

                    {/* content */}
                    <Col md={12} lg={7}>

                        <div className="Header">
                            
                            <h2>What Is <br /> <span> SSL CERTIFICATE ?</span></h2>
                            <p>SSL Certificates are fundamental to internet security. They are used to establish an encrypted connection and allow data to be transmitted securely between a browser or user's computer and a server or website.</p>
                            <p>Without one, you and your customers’ personal info can be intercepted during transmission to or from your site. Yikes. Plus many web browsers will flag a website as "not secure" unless it detects that it has a valid SSL certificate. Double yikes.</p>
                            <p>Different SSL certificates provide different levels of security, depending on the level of protection and security features your website and its users need. You may be familiar with visual elements such as Site Seals, the HTTPS:// protocol appearing in the URL, but there are less-visible functions that help protect your website and visitors.</p>

                        </div>

                    </Col>

                    {/* img */}
                    <Col md={12} lg={5}>

                        <div className="WhatSslImg">
                            <img src="assets/images/WhatSsl.png" alt="" />
                        </div>

                    </Col>

                </Row>
                
            </Container>

        </section>
        
    )


}

export default WhatSsl