import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const AboutBusinessEmail = () => {


    return (

        <section className='AboutBusinessEmail section_gaps'>

            <Container>
                
                <Row className="justify-content-md-center">

                    
                    <Col md={8}>

                        <div className="Header">
                            
                            <h2><span>What's amazing about </span> Business Email</h2>

                        </div>

                    </Col>

                </Row>

                {/* AboutBusinessEmailContent */}
                <div className="AboutBusinessEmailContent">

                    <Row>

                        <Col md={6}>  
                        
                            <div className="AboutBusinessEmailImg">
                                <img src="assets/images/EmailImg1.png" alt="" />
                            </div>

                        </Col>  

                        <Col md={6}>  
                        
                            <div className="AboutBusinessEmailImg">
                                <img src="assets/images/EmailImg2.png" alt="" />
                            </div>

                        </Col>  

                    </Row>

                    <div className="AboutBusinessBg">

                        <Row>

                            
                            <Col md={12}>

                                {/* item */}
                                <div className="AboutBusinessContent d_flex">

                                    <div className="img">
                                        <img src="assets/images/emailcart1.png" alt="" />
                                    </div>

                                    <div className="text">

                                        <h3>Intuitive and Responsive Design</h3>
                                        <p>Beautifully designed state of the art webmail platform. You can also access your email on your smartphone or tablet.</p>

                                    </div>

                                </div>

                                {/* item */}
                                <div className="AboutBusinessContent d_flex">

                                    <div className="img">
                                        <img src="assets/images/emailcart2.png" alt="" />
                                    </div>

                                    <div className="text">

                                        <h3>Inbuilt Virus Protection</h3>
                                        <p>Our advanced anti-virus technology secured your inbox and ensures that you are protected from downloading malware and viruses.</p>

                                    </div>

                                </div>

                                {/* item */}
                                <div className="AboutBusinessContent d_flex">

                                    <div className="img">
                                        <img src="assets/images/emailcart3.png" alt="" />
                                    </div>

                                    <div className="text">

                                        <h3>5GB Storage Plus Backup</h3>
                                        <p>In addition to 5GB mail storage, your emails are backed up in our state-of-the-art infrastructure so that you never lose important mails.</p>

                                    </div>

                                </div>

                                {/* item */}
                                <div className="AboutBusinessContent d_flex">

                                    <div className="img">
                                        <img src="assets/images/emailcart4.png" alt="" />
                                    </div>

                                    <div className="text">

                                        <h3>Get Additional Storage</h3>
                                        <p>No more worrying about running out of storage space. You can now increase storage for individual accounts by buying additional storage blocks.</p>

                                    </div>

                                </div>

                                {/* item */}
                                <div className="AboutBusinessContent d_flex">

                                    <div className="img">
                                        <img src="assets/images/emailcart5.png" alt="" />
                                    </div>

                                    <div className="text">

                                        <h3>100% Uptime and Security</h3>
                                        <p>Our high-end mail storage infrastructure guarantees zero data loss and redundancy, along with 100% network uptime.</p>

                                    </div>

                                </div>

                                {/* item */}
                                <div className="AboutBusinessContent d_flex">

                                    <div className="img">
                                        <img src="assets/images/emailcart6.png" alt="" />
                                    </div>

                                    <div className="text">

                                        <h3>Calendars and Contacts</h3>
                                        <p>Manage contacts and keep track of all your meetings in one place with advanced productivity tools.</p>

                                    </div>

                                </div>

                            </Col>

                        </Row>

                    </div>

                </div>
                
            </Container>

        </section>
        
    )


}

export default AboutBusinessEmail