/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Container, Row, Col, Button, Nav, NavDropdown, Tabs, Tab } from 'react-bootstrap';

import { Link } from 'react-router-dom';

const Package = () => {


    return (

        <section className='Package'>

            <Container>

                {/* header */}
                <Row className="justify-content-md-center">

                    <Col md={12}>

                        <div className="Header">
                            <h4>Get More Resources and Web Hosting Power</h4>
                            <h2><span>Find the Hosting Plan</span> that's Right for Your Business</h2>
                        </div>

                    </Col>

                </Row>
                
                <Row>
                    
                    <Col md={12}>

                        <div className="PackageContent">

                            <Tabs variant='pills' defaultActiveKey="PackOne" >

                                {/* PackOne */}
                                <Tab eventKey="PackOne" title="Bill Monthly (Pay Per Month)">
                                    
                                    <div className="PackageContentPart">

                                        <Row>

                                            {/* item */}
                                            <Col lg={4} md={4}>

                                                <div className="PackageContentItem">

                                                    {/* <span className='Off'>50% OFF</span> */}
                                                    
                                                    {/* PackagePrice */}
                                                    <div className="PackagePrice">

                                                        <h4>BASIC</h4>
                                                        <h3>BDT 150 /mo</h3>
                                                        <h5>Per Month</h5>
                                                        <h6></h6>

                                                    </div>

                                                    {/* listItem */}
                                                    <div className="listItem">
                                                    
                                                        <ul>

                                                            <li>2 GB NVME SSD Storage</li>
                                                            <li>100 GB Bandwidth</li>
                                                            <li>1 Website</li>
                                                            <li>LightSpeed with LSCache</li>
                                                            <li>Website Toolkit</li>
                                                            <li>Advanced Feature</li>
                                                            <li>Free SSL Certificate</li>
                                                            <li>Advanced Feature</li>
                                                            <li>Security Shield</li>
                                                            <li>99.99% Uptime Guarantee</li>
                                                            <li>24/7 Technical Support</li>

                                                        </ul>

                                                    </div>

                                                    <a href="https://my.hostwev.com/index.php?rp=/store/web-hosting/basic&billingcycle=monthly" className='bg'>Get Started</a>

                                                </div>

                                            </Col>

                                            {/* item */}
                                            <Col lg={4} md={4}>
                                            
                                                <div className="PackageContentItem">

                                                    {/* <span className='Off'>50% OFF</span> */}
                                                    
                                                    {/* PackagePrice */}
                                                    <div className="PackagePrice">

                                                        <h4>Starter</h4>
                                                        <h3>BDT 249 /mo</h3>
                                                        <h5>Per Month</h5>
                                                        <h6></h6>

                                                    </div>

                                                    {/* listItem */}
                                                    <div className="listItem">

                                                        <ul>

                                                            <li>5 GB NVME SSD Storage</li>
                                                            <li>250 GB Bandwidth</li>
                                                            <li>3 Website</li>
                                                            <li>LightSpeed with LSCache</li>
                                                            <li>Website Toolkit</li>
                                                            <li>Advanced Feature</li>
                                                            <li>Free SSL Certificate</li>
                                                            <li>Advanced Feature</li>
                                                            <li>Security Shield</li>
                                                            <li>99.99% Uptime Guarantee</li>
                                                            <li>24/7 Technical Support</li>

                                                        </ul>

                                                    </div>

                                                    <a href="http://my.hostwev.com/index.php?rp=/store/web-hosting/starter&billingcycle=monthly" className='bg'>Get Started</a>

                                                </div>

                                            </Col>

                                            {/* item */}
                                            <Col lg={4} md={4}>
                                            
                                                <div className="PackageContentItem">

                                                    {/* <span className='Off'>50% OFF</span> */}
                                                    
                                                    {/* PackagePrice */}
                                                    <div className="PackagePrice">

                                                        <h4>Standard</h4>
                                                        <h3>BDT 449 /mo</h3>
                                                        <h5>Per Month</h5>
                                                        <h6></h6>

                                                    </div>

                                                    {/* listItem */}
                                                    <div className="listItem">

                                                        <ul>

                                                            <li>10 GB NVME SSD Storage</li>
                                                            <li>500 GB Bandwidth</li>
                                                            <li>6 Website</li>
                                                            <li>LightSpeed with LSCache</li>
                                                            <li>Website Toolkit</li>
                                                            <li>Advanced Feature</li>
                                                            <li>Free SSL Certificate</li>
                                                            <li>Advanced Feature</li>
                                                            <li>Security Shield</li>
                                                            <li>99.99% Uptime Guarantee</li>
                                                            <li>24/7 Technical Support</li>

                                                        </ul>

                                                    </div>

                                                    <a href="http://my.hostwev.com/index.php?rp=/store/web-hosting/standard&billingcycle=monthly" className='bg'>Get Started</a>

                                                </div>

                                            </Col>

                                        </Row>

                                    </div>
                                    
                                </Tab>

                                <Tab eventKey="PackTwo" title="Bill Yearly (Pay Once A Year)">

                                    <div className="PackageContentPart">

                                        <Row>

                                            {/* item */}
                                            <Col lg={4} md={4}>

                                                <div className="PackageContentItem">

                                                    {/* <span className='Off'>50% OFF</span> */}
                                                    
                                                    {/* PackagePrice */}
                                                    <div className="PackagePrice">

                                                        <h4>BASIC</h4>
                                                        <h3>BDT 1450 /yr</h3>
                                                        <h5>Yearly</h5>
                                                        <h6></h6>

                                                    </div>

                                                    {/* listItem */}
                                                    <div className="listItem">

                                                        <ul>

                                                            <li>2 GB NVME SSD Storage</li>
                                                            <li>100 GB Bandwidth</li>
                                                            <li>1 Website</li>
                                                            <li>LightSpeed with LSCache</li>
                                                            <li>Website Toolkit</li>
                                                            <li>Advanced Feature</li>
                                                            <li>Free SSL Certificate</li>
                                                            <li>Advanced Feature</li>
                                                            <li>Security Shield</li>
                                                            <li>99.99% Uptime Guarantee</li>
                                                            <li>24/7 Technical Support</li>

                                                        </ul>

                                                    </div>

                                                    <a href="http://my.hostwev.com/index.php?rp=/store/web-hosting/basic&billingcycle=annually" className='bg'>Get Started</a>

                                                </div>

                                            </Col>

                                            {/* item */}
                                            <Col lg={4} md={4}>
                                            
                                                <div className="PackageContentItem">

                                                    {/* <span className='Off'>50% OFF</span> */}
                                                    
                                                    {/* PackagePrice */}
                                                    <div className="PackagePrice">

                                                        <h4>Starter</h4>
                                                        <h3>BDT 2750 /yr</h3>
                                                        <h5>Yearly</h5>
                                                        <h6></h6>

                                                    </div>

                                                    {/* listItem */}
                                                    <div className="listItem">

                                                        <ul>

                                                            <li>5 GB NVME SSD Storage</li>
                                                            <li>250 GB Bandwidth</li>
                                                            <li>3 Website</li>
                                                            <li>LightSpeed with LSCache</li>
                                                            <li>Website Toolkit</li>
                                                            <li>Advanced Feature</li>
                                                            <li>Free SSL Certificate</li>
                                                            <li>Advanced Feature</li>
                                                            <li>Security Shield</li>
                                                            <li>99.99% Uptime Guarantee</li>
                                                            <li>24/7 Technical Support</li>

                                                        </ul>

                                                    </div>

                                                    <a href="http://my.hostwev.com/index.php?rp=/store/web-hosting/starter&billingcycle=annually" className='bg'>Get Started</a>

                                                </div>

                                            </Col>

                                            {/* item */}
                                            <Col lg={4} md={4}>
                                            
                                                <div className="PackageContentItem">

                                                    {/* <span className='Off'>50% OFF</span> */}
                                                    
                                                    {/* PackagePrice */}
                                                    <div className="PackagePrice">

                                                        <h4>Standard</h4>
                                                        <h3>BDT 4999 /yr</h3>
                                                        <h5>Yearly</h5>
                                                        <h6></h6>

                                                    </div>

                                                    {/* listItem */}
                                                    <div className="listItem">

                                                        <ul>

                                                            <li>10 GB NVME SSD Storage</li>
                                                            <li>500 GB Bandwidth</li>
                                                            <li>6 Website</li>
                                                            <li>LightSpeed with LSCache</li>
                                                            <li>Website Toolkit</li>
                                                            <li>Advanced Feature</li>
                                                            <li>Free SSL Certificate</li>
                                                            <li>Advanced Feature</li>
                                                            <li>Security Shield</li>
                                                            <li>99.99% Uptime Guarantee</li>
                                                            <li>24/7 Technical Support</li>

                                                        </ul>

                                                    </div>

                                                    <a href="http://my.hostwev.com/index.php?rp=/store/web-hosting/standard&billingcycle=annually" className='bg'>Get Started</a>

                                                </div>

                                            </Col>

                                        </Row>

                                    </div>
                                   
                                </Tab>

                            </Tabs>
                            
                        </div>

                    </Col>

                </Row>
                
            </Container>

        </section>
        
    )


}

export default Package