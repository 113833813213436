import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table';

const TermsOfServiceContent = () => {


    return (

        <section className='TermsOfServiceContent section_gaps'>

            <Container>
                
                <Row>
                    
                    <Col md={12}>

                        <div className="Header">

                            <h2><span>Overview</span></h2>

                            <p>Join the best web hosting affiliate program with unlimited cash-earning potential.
                                Affiliate marketers, you can earn unlimited commissions by referring your website visitors to Hostwev. Here’s what you will enjoy about our web hosting affiliate program. If you have any questions please email us directly at <a href='mailto:affiliates@hostwev.com'>affiliates@hostwev.com</a>.
                            </p>

                        </div>


                        {/* CommissionStructure */}
                        <div className="CommissionStructure">

                            <h3>Commission Structure</h3>

                            <Table bordered responsive >

                                <thead>

                                    <tr>

                                        <th>Web Hosting</th>
                                        <th>Reseller Hosting</th>
                                        <th>Virtual Private Server</th>

                                    </tr>

                                </thead>

                                <tbody>

                                    <tr>

                                        <td><p>Perfect for bloggers, startups, and small businesses just getting their feet wet with an online presence</p></td>
                                        <td><p>Our reseller accounts make sense for small businesses who want to sell hosting to their clients.</p></td>
                                        <td><p>Large businesses can expect the same great service from us. Send them our way and we’ll do the rest.</p></td>

                                    </tr>

                                    <tr>

                                        <td>Basic  - 10% per sale</td>
                                        <td>RH-Starter: 10% per sale</td>
                                        <td>V-SSD2GB: 10% per sale</td>

                                    </tr>

                                    <tr>

                                        <td>Starter  - 10% per sale</td>
                                        <td>RH-Standard: 10% per sale</td>
                                        <td>V-SSD4GB: 10% per sale</td>

                                    </tr>

                                    <tr>

                                        <td>Standard  - 10% per sale</td>
                                        <td>RH-Advanced: 10% per sale</td>
                                        <td>V-SSD6GB: 10% per sale</td>

                                    </tr>

                                    <tr>

                                        <td>Advanced  - 10% per sale</td>
                                        <td>RH-Business: 10% per sale</td>
                                        <td>V-SSD8GB: 10% per sale</td>

                                    </tr>

                                </tbody>

                            </Table>

                        </div>

                        <div className="Header">

                            <h3> How It <span> Works</span></h3>

                            <p>Getting started is very easy! You will have to <a href='https://my.hostwev.com/register.php' target="_blank" rel="noreferrer">Register</a>  for an account (It's free!). Once registration is completed please <a href='https://my.hostwev.com/index.php?rp=/login' target="_blank" rel="noreferrer">click here</a> for activating the affiliate account.
                            </p>

                        </div>


                        {/* SubHeading */}
                        <div className="SubHeading">

                            <h3>How does <span>Affiliate Program work?</span></h3>
                            <p>Sign up for our affiliate program and you can take advantage of our tools and software immediately. Embed your unique affiliate link or banner on your most popular pages and start driving traffic to our website. When your visitor becomes our customer you will receive a monthly payout based on the product your referral purchased. Our commission is based on a tiered program based on the number of sales your referrals generate.</p>

                        </div>

                        {/* SubHeading */}
                        <div className="SubHeading">

                            <h3>HOW DO I APPLY FOR <span>THE HOSTWEV AFFILIATE PROGRAM?</span></h3>
                            <p>You can sign up for our program at <a href='https://my.hostwev.com/affiliates.php' target="_blank" rel="noreferrer">my.hostwev.com/affiliates.php</a> </p>

                        </div>

                    </Col>

                </Row>
                
            </Container>

        </section>
        
    )


}

export default TermsOfServiceContent