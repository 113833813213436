import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const HostingSolution = () => {


    return (

        <section className='HostingSolution section_gaps'>

            {/* SideText */}
            <div className="SideText">
                <h6><span>Best Web</span> Hosting Solution</h6>
            </div>

            <Container>

                {/* header */}
                <Row className="justify-content-md-center">

                    <Col md={8}>

                        <div className="Header">
                            <h4>Consistently ranked among the best web hosting companies by trusted independent sources</h4>
                            <h2><span>Explore Website </span> Hosting Features !</h2>
                        </div>

                    </Col>

                </Row>

                {/* HostingSolutionContain */}
                <div className="HostingSolutionContain">

                    <Row>

                        {/* item */}
                        <Col md={4}>

                            <div className="HostingSolutionItem">
                                
                                <div className="img">
                                    <img src="assets/images/ssd.png" alt="ssd" />
                                </div>

                                <div className="text">

                                    <h3>Raid Protected <br />Enterprise SSD</h3>
                                    <p>Our servers have high-performance Pure SSD drives both files & databases so your sites load incredibly fast..</p>

                                </div>

                            </div>

                        </Col>

                        {/* item */}
                        <Col md={4}>

                            <div className="HostingSolutionItem">
                                
                                <div className="img">
                                    <img src="assets/images/cyber-security.png" alt="ssd" />
                                </div>

                                <div className="text">

                                    <h3>Dedicated to <br /> Security</h3>
                                    <p>Monitoring and defending your web hosting from online threats.</p>

                                </div>

                            </div>

                        </Col>

                        {/* item */}
                        <Col md={4}>

                            <div className="HostingSolutionItem">
                                
                                <div className="img">
                                    <img src="assets/images/speed-radar.png" alt="ssd" />
                                </div>

                                <div className="text">

                                    <h3>LiteSpeed Web <br /> Server</h3>
                                    <p>Static content up to 5x faster, dynamic content up to 40x faster and HTTPS up to 3x faster than Apache.</p>

                                </div>

                            </div>

                        </Col>

                    </Row>

                </div>
                
            </Container>

        </section>
        
    )


}

export default HostingSolution