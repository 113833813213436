import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const ResellerHostingSolution = () => {


    return (

        <section className='ResellerHostingSolution section_gaps'>

            <Container>
                
                <Row className="justify-content-md-center">

                    <Col md={8}>

                        <div className="Header">
                            
                            <h4>Earn Revenue & Support Your Clients with Fast and Dependable VPS Hosting Options!</h4>
                            <h2>Blazing Fast, <span>Low Cost Turbo Web Hosting</span></h2>

                            {/* DualButton */}
                            {/* <div className="DualButton">

                                <Link to="/" className='bg'>Reseller Hosting</Link>
                                <Link to="/" className='bg border'>Turbo Reseller Hosting</Link>

                            </div> */}

                        </div>

                    </Col>

                </Row>
                
            </Container>

        </section>
        
    )


}

export default ResellerHostingSolution