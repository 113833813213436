/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn, FaYoutube } from 'react-icons/fa';
import { BsFillCaretDownFill } from 'react-icons/bs';
import { FiPhoneCall } from 'react-icons/fi';
import { BsFillEnvelopeFill } from 'react-icons/bs';
import { BiTimeFive } from 'react-icons/bi';
import { MdLocationOn } from 'react-icons/md';

const Footer = () => {


    return (

        <section className='Footer'>

            <Container>
                
                <Row className="justify-content-md-center">

                    
                    <Col md={12}>

                        <div className="FooterContent">

                            <div className="FooterLogo">
                                <img src="assets/images/footer_logo.png" alt="footer_logo" />
                            </div>

                            {/* Social Icon */}
                            <div className="SocialIcon">

                                <a href="#"><FaFacebookF/></a>
                                <a href="#"><FaTwitter/></a>
                                <a href="#"><FaInstagram/></a>
                                <a href="#"><FaLinkedinIn/></a>
                                <a href="#"><FaYoutube/></a>

                            </div>

                            {/* menu */}
                            <div className="Menu">
                                
                                <ul>

                                    <li>
                                        <Link to="/web-hosting">Hosting </Link>
                                    </li>

                                    <li>
                                        <Link to="/domain-resister">Domain </Link>
                                    </li>

                                    <li>
                                        <Link to="/vps">Server </Link>
                                    </li>

                                    <li>
                                        <Link to="/g-suite">Email Hosting </Link>
                                    </li>

                                    <li>
                                        <Link to="/ssl-certificate">SSL Certificate</Link>
                                    </li>

                                    <li>
                                        <Link to="/affiliate-program">Affiliates Program</Link>
                                    </li>
                                    
                                </ul>

                            </div>

                            {/* Contqact */}
                            <div className="HeaderLeft">
                            
                                <ul>

                                    <li>
                                        <a className='d_flex' href="tel:01894844446"><FiPhoneCall/> 01894844446</a>
                                    </li>

                                    <li>
                                        <a className='d_flex' href="mailto:support@hostwev.com"><BiTimeFive/>support@hostwev.com</a>
                                    </li>

                                    <li>
                                        <a className='d_flex' href="#"><MdLocationOn/> SAR Bhaban, Level-5 , Ka-78, Progoti Sarani, Kuril, Vatara, Dhaka-1229, Bangladesh</a>
                                    </li>

                                </ul>

                            </div>
                            
                        </div>

                    </Col>

                    <div className="TinyFooter">

                        <Row>

                            <Col md={6}>

                                <div className="TinyFooterItem">
                                    <p>Copyright © <a href="#">Hostwev 2023</a></p>
                                </div>

                                </Col>

                                <Col md={6}>


                                <div className="TinyFooteritem">
                                    
                                    <ul>

                                        <li><Link to='/terms-of-service'>Terms of Service </Link></li>
                                        <li><Link to='/privacy-policy'>Privacy Policy </Link></li>
                                        {/* <li><Link to='/affiliate-program'>SLA </Link></li> */}

                                    </ul>

                                </div>

                            </Col>

                        </Row>

                    </div>

                </Row>
                
            </Container>

        </section>
        
    )


}

export default Footer