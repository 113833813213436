import React from 'react';
import Header from '../Components/CommonComponents/Header';
import Menu from '../Components/CommonComponents/Menu';
import Partner from '../Components/CommonComponents/Partner';
import Footer from '../Components/CommonComponents/Footer';
import WhereToStart from '../Components/CommonComponents/WhereToStart';
import OthersBanner from '../Components/CommonComponents/OthersBanner';
import TermsOfServiceContent from '../Components/PrivecyPolicyPage/TermsOfServiceContent';


const PrivecyPolicy = () => {

    let header={
        header:'Privacy ',
        span:'Policy',
        description:'We like to think of a dedicated server like owning your own house. You can do whatever you’d like. This leads to increased speed, reliability, and security',
    }



    return (

        <>
        
            {/* Header */}
            <Header></Header>

            {/* Menu */}
            <Menu></Menu>

            {/* banner */}
            <OthersBanner header={header}></OthersBanner>

            {/* TermsOfServiceContent */}
            <TermsOfServiceContent></TermsOfServiceContent>

            {/* HostingSolution */}
            <WhereToStart></WhereToStart>

            {/* Partner */}
            <Partner></Partner>

            {/* Footer */}
            <Footer></Footer>

        </>

    )


}

export default PrivecyPolicy