/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Container, Row, Col, Button, Nav, NavDropdown, Tabs, Tab } from 'react-bootstrap';

import { Link } from 'react-router-dom';

const Package = () => {


    return (

        <section className='Package'>

            <Container>

                {/* header */}
                <Row className="justify-content-md-center">

                    <Col md={12}>

                        <div className="Header">
                            <h4>Choose a Google Workspace plan that serves your business requirement</h4>
                            <h2><span>Google Suite </span>  Workspace</h2>
                        </div>

                    </Col>

                </Row>
                
                <Row>
                    
                    {/* item */}
                    <Col md={4}>

                        <div className="PackageContent">

                            <div className="PackageContentItem">

                                {/* <span className='Off'>50% OFF</span> */}
                                
                                {/* PackagePrice */}
                                <div className="PackagePrice">

                                    <h4>BUSINESS STARTER</h4>
                                    <h3>BDT 684/-</h3>
                                    <h5>/act/per month</h5>
                                    <h6>30 GB Cloud Storage</h6>
                                    {/* <h6>Regular Price : <del>2499/-</del></h6>
                                    <span>Coupon: FREE50</span> */}

                                </div>

                                {/* listItem */}
                                <div className="listItem">

                                    <ul>

                                        <li>Active Communication</li>
                                        <li>Basic Security</li>
                                        <li>Standard Endpoint device</li>
                                        <li>Admin Controls</li>
                                        <li>24/7 Technical Support</li>

                                    </ul>

                                </div>

                                <a href="https://my.hostwev.com/index.php?rp=/store/Google-Suite/business-starter-1&billingcycle=monthly" className='bg'>Get Started</a>

                                {/* GContent */}
                                <div className="GContent">

                                    {/* item */}
                                    <div className="GItem d_flex">
                                        
                                        <div className="img">
                                            <img src="assets/images/g1.png" alt="" />
                                        </div>

                                        <div className="text">
                                            <p>Get secure and personalised email account for your business.</p>
                                        </div>

                                    </div>

                                    {/* item */}
                                    <div className="GItem d_flex">
                                        
                                        <div className="img">
                                            <img src="assets/images/g2.png" alt="" />
                                        </div>

                                        <div className="text">
                                            <p>Keep track of important events and share your schedule.</p>
                                        </div>

                                    </div>

                                    {/* item */}
                                    <div className="GItem d_flex">
                                        
                                        <div className="img">
                                            <img src="assets/images/g3.png" alt="" />
                                        </div>

                                        <div className="text">
                                            <p>Create custom forms for surveys and questionnaires.</p>
                                        </div>

                                    </div>

                                    {/* item */}
                                    <div className="GItem d_flex">
                                        
                                        <div className="img">
                                            <img src="assets/images/g4.png" alt="" />
                                        </div>

                                        <div className="text">
                                            <p> Setup HD video with 100 participants to ensure uninterrupted productivity.</p>
                                        </div>

                                    </div>

                                    {/* item */}
                                    <div className="GItem d_flex">
                                        
                                        <div className="img">
                                            <img src="assets/images/g5.png" alt="" />
                                        </div>

                                        <div className="text">
                                            <p>Manage your to-do's, take notes on the go and never lose track of ideas.</p>
                                        </div>

                                    </div>

                                    {/* item */}
                                    <div className="GItem d_flex">
                                        
                                        <div className="img">
                                            <img src="assets/images/g6.png" alt="" />
                                        </div>

                                        <div className="text">
                                            <p>Create and work on documents with images, tables, drawings, charts and more.</p>
                                        </div>

                                    </div>

                                    {/* item */}
                                    <div className="GItem d_flex">
                                        
                                        <div className="img">
                                            <img src="assets/images/g7.png" alt="" />
                                        </div>

                                        <div className="text">
                                            <p>Basic security and admin controls.</p>
                                        </div>

                                    </div>

                                    {/* item */}
                                    <div className="GItem d_flex">
                                        
                                        <div className="img">
                                            <img src="assets/images/g8.png" alt="" />
                                        </div>

                                        <div className="text">
                                            <p>Create engaging, high-quality sites for your project.</p>
                                        </div>

                                    </div>

                                    {/* item */}
                                    <div className="GItem d_flex">
                                        
                                        <div className="img">
                                            <img src="assets/images/g9.png" alt="" />
                                        </div>

                                        <div className="text">
                                            <p>Get valuable insights via spreadsheet data using formulas, charts, connectors and macros.</p>
                                        </div>

                                    </div>

                                    {/* item */}
                                    <div className="GItem d_flex">
                                        
                                        <div className="img">
                                            <img src="assets/images/g10.png" alt="" />
                                        </div>

                                        <div className="text">
                                            <p>Create stunning presentations using templates, embed videos and images.</p>
                                        </div>

                                    </div>

                                </div>

                            </div>
                            
                        </div>

                    </Col>

                    {/* item */}
                    <Col md={4}>

                        <div className="PackageContent">

                            <div className="PackageContentItem">

                                {/* <span className='Off'>50% OFF</span> */}
                                
                                {/* PackagePrice */}
                                <div className="PackagePrice">

                                    <h4>Business Standard</h4>
                                    <h3>BDT 1499/-</h3>
                                    <h5>/act/per month</h5>
                                    <h6>2 TB Cloud Storage</h6>
                                    {/* <h6>Regular Price : <del>2499/-</del></h6>
                                    <span>Coupon: FREE50</span> */}

                                </div>

                                {/* listItem */}
                                <div className="listItem">

                                    <ul>

                                        <li>Active Communication</li>
                                        <li>Basic Security</li>
                                        <li>Standard Endpoint device</li>
                                        <li>Smart Search within and outside Google Workspace with Cloud Search.</li>
                                        <li>Admin Controls</li>
                                        <li>24/7 Technical Support</li>

                                    </ul>

                                </div>

                                <a href="https://my.hostwev.com/index.php?rp=/store/Google-Suite/business-standard-1&billingcycle=monthly" className='bg'>Get Started</a>

                                {/* GContent */}
                                <div className="GContent">

                                    {/* item */}
                                    <div className="GItem d_flex">
                                        
                                        <div className="img">
                                            <img src="assets/images/g1.png" alt="" />
                                        </div>

                                        <div className="text">
                                            <p>Get secure and personalised email account for your business.</p>
                                        </div>

                                    </div>

                                    {/* item */}
                                    <div className="GItem d_flex">
                                        
                                        <div className="img">
                                            <img src="assets/images/g2.png" alt="" />
                                        </div>

                                        <div className="text">
                                            <p>Keep track of important events and share your schedule.</p>
                                        </div>

                                    </div>

                                    {/* item */}
                                    <div className="GItem d_flex">
                                        
                                        <div className="img">
                                            <img src="assets/images/g3.png" alt="" />
                                        </div>

                                        <div className="text">
                                            <p>Create custom forms for surveys and questionnaires.</p>
                                        </div>

                                    </div>

                                    {/* item */}
                                    <div className="GItem d_flex">
                                        
                                        <div className="img">
                                            <img src="assets/images/g4.png" alt="" />
                                        </div>

                                        <div className="text">
                                            <p> Setup HD video calls with 150 participants + recording to ensure uninterrupted productivity.</p>
                                        </div>

                                    </div>

                                    {/* item */}
                                    <div className="GItem d_flex">
                                        
                                        <div className="img">
                                            <img src="assets/images/g5.png" alt="" />
                                        </div>

                                        <div className="text">
                                            <p>Manage your to-do's, take notes on the go and never lose track of ideas.</p>
                                        </div>

                                    </div>

                                    {/* item */}
                                    <div className="GItem d_flex">
                                        
                                        <div className="img">
                                            <img src="assets/images/g6.png" alt="" />
                                        </div>

                                        <div className="text">
                                            <p>Create and work on documents with images, tables, drawings, charts and more.</p>
                                        </div>

                                    </div>

                                    {/* item */}
                                    <div className="GItem d_flex">
                                        
                                        <div className="img">
                                            <img src="assets/images/g7.png" alt="" />
                                        </div>

                                        <div className="text">
                                            <p>Create and work on documents with images, tables, drawings, charts and more.</p>
                                        </div>

                                    </div>

                                    {/* item */}
                                    <div className="GItem d_flex">
                                        
                                        <div className="img">
                                            <img src="assets/images/g8.png" alt="" />
                                        </div>

                                        <div className="text">
                                            <p>Standard security & Admin controls</p>
                                        </div>

                                    </div>

                                    {/* item */}
                                    <div className="GItem d_flex">
                                        
                                        <div className="img">
                                            <img src="assets/images/g9.png" alt="" />
                                        </div>

                                        <div className="text">
                                            <p>Get valuable insights via spreadsheet data using formulas, charts, connectors and macros.</p>
                                        </div>

                                    </div>

                                    {/* item */}
                                    <div className="GItem d_flex">
                                        
                                        <div className="img">
                                            <img src="assets/images/g10.png" alt="" />
                                        </div>

                                        <div className="text">
                                            <p>Create stunning presentations using templates, embed videos and images.</p>
                                        </div>

                                    </div>

                                </div>

                            </div>
                            
                        </div>

                    </Col>

                    {/* item */}
                    <Col md={4}>

                       <div className="PackageContent">

                            <div className="PackageContentItem">

                                {/* <span className='Off'>50% OFF</span> */}
                                
                                {/* PackagePrice */}
                                <div className="PackagePrice">

                                    <h4>Business Plus</h4>
                                    <h3>BDT 2299/-</h3>
                                    <h5>/act/per month</h5>
                                    <h6>5 TB Cloud Storage</h6>
                                    {/* <h6>Regular Price : <del>2499/-</del></h6>
                                    <span>Coupon: FREE50</span> */}

                                </div>

                                {/* listItem */}
                                <div className="listItem">

                                    <ul>

                                        <li>Active Communication</li>
                                        <li>Basic Security</li>
                                        <li>Standard Endpoint device</li>
                                        <li>Smart Search within and outside Google Workspace with Cloud Search.</li>
                                        <li>Vault for eDiscovery for emails, chats, and files and archiving.</li>
                                        <li>Admin Controls</li>
                                        <li>24/7 Technical Support</li>

                                    </ul>

                                </div>

                                <a href="https://my.hostwev.com/index.php?rp=/store/Google-Suite/business-plus-in&billingcycle=monthly" className='bg'>Get Started</a>

                                {/* GContent */}
                                <div className="GContent">

                                    {/* item */}
                                    <div className="GItem d_flex">
                                        
                                        <div className="img">
                                            <img src="assets/images/g1.png" alt="" />
                                        </div>

                                        <div className="text">
                                            <p>Get secure and personalised email account for your business.</p>
                                        </div>

                                    </div>

                                    {/* item */}
                                    <div className="GItem d_flex">
                                        
                                        <div className="img">
                                            <img src="assets/images/g2.png" alt="" />
                                        </div>

                                        <div className="text">
                                            <p>Keep track of important events and share your schedule.</p>
                                        </div>

                                    </div>

                                    {/* item */}
                                    <div className="GItem d_flex">
                                        
                                        <div className="img">
                                            <img src="assets/images/g3.png" alt="" />
                                        </div>

                                        <div className="text">
                                            <p>Create custom forms for surveys and questionnaires.</p>
                                        </div>

                                    </div>

                                    {/* item */}
                                    <div className="GItem d_flex">
                                        
                                        <div className="img">
                                            <img src="assets/images/g4.png" alt="" />
                                        </div>

                                        <div className="text">
                                            <p> Setup HD video calls with 250 participants + recording + attendance tracking.</p>
                                        </div>

                                    </div>

                                    {/* item */}
                                    <div className="GItem d_flex">
                                        
                                        <div className="img">
                                            <img src="assets/images/g5.png" alt="" />
                                        </div>

                                        <div className="text">
                                            <p>Manage your to-do's, take notes on the go and never lose track of ideas.</p>
                                        </div>

                                    </div>

                                    {/* item */}
                                    <div className="GItem d_flex">
                                        
                                        <div className="img">
                                            <img src="assets/images/g6.png" alt="" />
                                        </div>

                                        <div className="text">
                                            <p>Create and work on documents with images, tables, drawings, charts and more.</p>
                                        </div>

                                    </div>

                                    {/* item */}
                                    <div className="GItem d_flex">
                                        
                                        <div className="img">
                                            <img src="assets/images/g7.png" alt="" />
                                        </div>

                                        <div className="text">
                                            <p>Basic security and admin controls.</p>
                                        </div>

                                    </div>

                                    {/* item */}
                                    <div className="GItem d_flex">
                                        
                                        <div className="img">
                                            <img src="assets/images/g8.png" alt="" />
                                        </div>

                                        <div className="text">
                                            <p>Create engaging, high-quality sites for your project.</p>
                                        </div>

                                    </div>

                                    {/* item */}
                                    <div className="GItem d_flex">
                                        
                                        <div className="img">
                                            <img src="assets/images/g9.png" alt="" />
                                        </div>

                                        <div className="text">
                                            <p>Get valuable insights via spreadsheet data using formulas, charts, connectors and macros.</p>
                                        </div>

                                    </div>

                                    {/* item */}
                                    <div className="GItem d_flex">
                                        
                                        <div className="img">
                                            <img src="assets/images/g10.png" alt="" />
                                        </div>

                                        <div className="text">
                                            <p>Create stunning presentations using templates, embed videos and images.</p>
                                        </div>

                                    </div>

                                </div>

                            </div>
                            
                        </div>

                    </Col>

                </Row>
                
            </Container>

        </section>
        
    )


}

export default Package