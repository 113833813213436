/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FiPhoneCall } from 'react-icons/fi';
import { BsFillEnvelopeFill } from 'react-icons/bs';
import { BiTimeFive } from 'react-icons/bi';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn, FaYoutube } from 'react-icons/fa';

const Header = (props) => {



    return (

        <section className='HeaderSection'>

            <Container>
                
                <Row>

                    {/* HeaderLeft */}
                    <Col md={6}>

                        <div className="HeaderLeft">
                            
                            <ul>

                                <li>
                                    <a className='d_flex' href="tel:01894844446"><FiPhoneCall/> +8801894844446</a>
                                </li>

                                <li>
                                    <a className='d_flex' href="mailto:support@hostwev.com"><BsFillEnvelopeFill/>support@hostwev.com</a>
                                </li>

                                <li>
                                    <a className='d_flex' href="#"><BiTimeFive/> Saturday-Friday (9 AM-9 PM)</a>
                                </li>

                            </ul>

                        </div>

                    </Col>

                    {/* HeaderRight */}
                    <Col md={6}>

                        <div className="HeaderRight d_flex">
                            
                            <ul>

                                <li className={props.active == 'aboutus' && 'active'}>
                                    <Link to="/about-us">About Us</Link>
                                </li>

                                <li className={props.active == 'data_center' && 'active'}>
                                    <Link to="/data-center">Our Datacenters</Link>
                                </li>

                                <li className={props.active == 'blog' && 'active'}>
                                    <Link to="/blog">Blogs</Link>
                                </li>

                                <li className={props.active == 'contactus' && 'active'}>
                                    <a href="https://my.hostwev.com/contact.php" >Contact Us</a>
                                </li>
                                
                            </ul>

                            {/* SocialIcon */}
                            <div className="SocialIcon">

                                <a href="#"><FaFacebookF/></a>
                                <a href="#"><FaTwitter/></a>
                                <a href="#"><FaInstagram/></a>
                                <a href="#"><FaLinkedinIn/></a>
                                <a href="#"><FaYoutube/></a>

                            </div>

                        </div>

                    </Col>

                </Row>
                
            </Container>

        </section>
        
    )


}

export default Header