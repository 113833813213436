import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const WhatSsl = () => {


    return (

        <section className='WhatSsl section_gaps'>

            <Container>
                
                <Row className='d_flex'>

                    {/* content */}
                    <Col md={7}>

                        <div className="Header">
                            
                            <h2>Choose Your G Suite by <br /> <span> Google Service</span></h2>
                            <p>G Suite is a set of intelligent apps—Gmail, Docs, Drive, Calendar, Hangouts, and more—designed to bring people together, with real-time collaboration built in from the start. And there’s a lot more on the way. Because we believe that when organizations break down silos, connect people and empower them to work together, we get the speed, agility and impact needed to compete in today’s market. So in the spirit of teamwork, we partnered with some of our favorite illustrators from around the world to bring our ideas to life with their iconic creativity.With G Suite, information can flow freely between devices, apps, people and teams, so great ideas never get left in the margins again. Imagine the future for your business, if this was how it could be . . .</p>

                        </div>

                    </Col>

                    {/* img */}
                    <Col md={5}>

                        <div className="WhatSslImg">
                            <img src="assets/images/g-suite.png" alt="" />
                        </div>

                    </Col>

                </Row>
                
            </Container>

        </section>
        
    )


}

export default WhatSsl