import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const OurSpecial = () => {


    return (

        <section className='OurSpecial section_gaps'>

            {/* SideText */}
            <div className="SideText">
                <h6><span>Our</span> Specials</h6>
            </div>

            <Container>
                
                <Row className='d_flex'>
                    
                    <Col md={6}>

                        <div className="OurSpecialContent">
                            
                            {/* item */}
                            <div className="OurSpecialItem d_flex">

                                <div className="img">
                                    <img src="assets/images/OurSpecial1.png" alt="OurSpecial" />
                                </div>

                                <div className="text">

                                    <h3>One Click Solution</h3>
                                    <p>Integrating your apps couldn't be any easier.</p>
                                    
                                </div>

                            </div>

                            {/* item */}
                            <div className="OurSpecialItem d_flex">

                                <div className="img">
                                    <img src="assets/images/OurSpecial2.png" alt="OurSpecial" />
                                </div>

                                <div className="text">

                                    <h3>Free SSH and Secure FTP Access</h3>
                                    <p>Access your files via command line and upload files to our server securely via SFTP.</p>
                                    
                                </div>

                            </div>

                            {/* item */}
                            <div className="OurSpecialItem d_flex">

                                <div className="img">
                                    <img src="assets/images/OurSpecial3.png" alt="OurSpecial" />
                                </div>

                                <div className="text">

                                    <h3>Easy To Use</h3>
                                    <p>Easily manage and grow your websites with cPanel and 1-click installs of the most popular web apps.</p>
                                    
                                </div>

                            </div>

                        </div>

                    </Col>

                    <Col md={6}>

                        <div className="OurSpecialImg">
                            <img src="assets/images/OurSpecialImg.png" alt="" />
                        </div>

                    </Col>

                </Row>
                
            </Container>

        </section>
        
    )


}

export default OurSpecial