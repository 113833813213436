import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const CompanyAddress = () => {


    return (

        <section className='CompanyAddress section_gaps'>

            <Container>
                
                <Row>

                    {/* Left */}
                    <Col md={6}>

                        <div className="CompanyAddressLeft">

                            {/* item */}
                            <div className="CompanyAddressItem">
                                
                                <div className="img">
                                    <img src="assets/images/map1.png" alt="" />
                                </div>

                                <div className="text">
                                    <h3>Address</h3>
                                    <p>Level-5,  SAR Bhaban, Ka-78 Pragati Sarani Main Road, 1229- Dhaka</p>
                                </div>

                            </div>

                            {/* item */}
                            <div className="CompanyAddressItem">
                                
                                <div className="img">
                                    <img src="assets/images/email1.png" alt="" />
                                </div>

                                <div className="text">
                                    <h3>Address</h3>
                                    <p>Level-5,  SAR Bhaban, Ka-78 Pragati Sarani Main Road, 1229- Dhaka</p>
                                </div>

                            </div>

                            {/* item */}
                            <div className="CompanyAddressItem">
                                
                                <div className="img">
                                    <img src="assets/images/call.png" alt="" />
                                </div>

                                <div className="text">
                                    <h3>Address</h3>
                                    <p>Level-5,  SAR Bhaban, Ka-78 Pragati Sarani Main Road, 1229- Dhaka</p>
                                </div>

                            </div>
                            
                        </div>

                    </Col>

                    {/* Right */}
                    <Col md={6}>

                        <div className="CompanyAddressRight">

                            <div className="Header">
                                <h2><span>Let’s level up</span> your brand, together</h2>
                            </div>

                            <form action="" method='post'>

                                {/* custome input */}
                                <div className="CuntomeInput">

                                    <label htmlFor="">Name</label>
                                    <input type="text" placeholder='Your name'/>

                                </div>

                                {/* custome input */}
                                <div className="CuntomeInput">

                                    <label htmlFor="">Email</label>
                                    <input type="text" placeholder='you@company.com'/>

                                </div>

                                {/* custome input */}
                                <div className="CuntomeInput">

                                    <label htmlFor="">Phone number</label>
                                    <input type="text" placeholder='+1 (555) 000-0000'/>

                                </div>

                                {/* custome input */}
                                <div className="CuntomeInput">

                                    <label htmlFor="">How can we help?</label>
                                    <textarea name="" rows="5" placeholder='Tell us a little about the project...'></textarea>

                                </div>

                                {/* custome input */}
                                <div className="CuntomeInput">

                                    <label>Services</label>

                                    {/* item */}
                                    <div className="Services">

                                        {/* item */}
                                        <div className="ServicesItem">
                                            <input type="checkbox" id='Web'/>
                                            <label htmlFor="Web">Web Hosting</label>
                                        </div>

                                        {/* item */}
                                        <div className="ServicesItem">
                                            <input type="checkbox" id='TurboReseller'/>
                                            <label htmlFor="TurboReseller">Turbo Reseller Hosting</label>
                                        </div>

                                        {/* item */}
                                        <div className="ServicesItem">
                                            <input type="checkbox" id='Reseller'/>
                                            <label htmlFor="Reseller">Reseller Web Hosting</label>
                                        </div>

                                        {/* item */}
                                        <div className="ServicesItem">
                                            <input type="checkbox" id='SSL'/>
                                            <label htmlFor="SSL">SSL Certificate</label>
                                        </div>

                                        {/* item */}
                                        <div className="ServicesItem">
                                            <input type="checkbox" id='Turbo'/>
                                            <label htmlFor="Turbo">Turbo Hosting</label>
                                        </div>

                                        {/* item */}
                                        <div className="ServicesItem">
                                            <input type="checkbox" id='Other'/>
                                            <label htmlFor="Other">Other</label>
                                        </div>

                                    </div>

                                    <button type='submit' className='bg'>Get started</button>


                                </div>

                            </form>

                        </div>

                    </Col>

                </Row>
                
            </Container>

        </section>
        
    )


}

export default CompanyAddress