import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const ResellerHostingSolution = () => {


    return (

        <section className='ResellerHostingSolution section_gaps'>

            <Container>
                
                <Row className="justify-content-md-center">

                    
                    <Col md={8}>

                        <div className="Header">
                            
                            <h4>The right Turbo Hosting Plan for your website!</h4>
                            <p>Created with care<span>, assembled with attention to the details!</span></p>

                            {/* DualButton */}
                            {/* <div className="DualButton">

                                <Link to="/" className='bg'>Bill Monthly (Pay Per Month)</Link>
                                <Link to="/" className='bg border'>Bill Yearly (Pay Once A Year)</Link>

                            </div> */}

                        </div>

                    </Col>

                </Row>
                
            </Container>

        </section>
        
    )


}

export default ResellerHostingSolution