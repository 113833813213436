/* eslint-disable eqeqeq */
import React, { useState } from 'react';
import { Container, Row, Col, Button, Offcanvas, NavDropdown, Dropdown, Nav } from 'react-bootstrap';
import { BsChevronDown, BsFillCaretDownFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import {AiOutlineBars} from 'react-icons/ai'
import {RiCloseFill} from 'react-icons/ri'

const Menu = (props) => {


    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    return (

        <>
        
        
            <section className='Menubar'>

                <Container>
                    
                    <div className="MenubarBg">

                        <Row className='d_flex'>

                            {/* Logo */}
                            <Col md={2}>

                                <div className="Logo">
                                    <Link to='/'><img src="assets/images/logo.png" alt="logo" /></Link>
                                </div>

                            </Col>
                            
                            {/* Menu */}
                            <Col md={8}>

                                <div className="Menu">
                                    
                                    <ul>
                                        
                                        <li className={props.active == 'home' && 'active'}>
                                            <Link to="/">Home</Link>
                                        </li>

                                        <li className={props.active == 'hosting' && 'active'}>
                                            <Link to="#">Hosting <BsFillCaretDownFill/></Link>

                                            <ul className='Submenu'>

                                                <li>
                                                    <Link to="/web-hosting">Web Hosting</Link>
                                                </li>

                                                <li>
                                                    <Link to="/turbo-hosting">Turbo Hosting</Link>
                                                </li>

                                                <li>
                                                    <Link to="/bdix-turbo-hosting">BDIX Turbo Hosting</Link>
                                                </li>

                                                <li>
                                                    <Link to="/bdix-reseller-hosting">BDIX Reseller Hosting</Link>
                                                </li>


                                            </ul>

                                        </li>

                                        <li className={props.active == 'server' && 'active'}>
                                            <Link to="#">Server <BsFillCaretDownFill/></Link>
                                            <ul className='Submenu'>

                                                <li>
                                                    <Link to="/vartual-server">Virtual Server</Link>
                                                </li>

                                                <li>
                                                    <Link to="/dedicated-derver">Dedicated Server</Link>
                                                </li>

                                            </ul>
                                        </li>

                                        <li className={props.active == 'domain' && 'active'}>
                                            <Link to="#">Domain <BsFillCaretDownFill/></Link>
                                            <ul className='Submenu'>

                                                <li>
                                                    <Link to="/domain-register">Domain Registration</Link>
                                                </li>

                                                <li>
                                                    <Link to="/domain-reseller">Domain Reseller</Link>
                                                </li>

                                            </ul>
                                        </li>

                                        <li className={props.active == 'email' && 'active'}>
                                            <Link to="#">Email Hosting <BsFillCaretDownFill/></Link>
                                            <ul className='Submenu'>

                                                <li>
                                                    <Link to="/g-suite">G Suite By Google</Link>
                                                </li>

                                                <li>
                                                    <Link to="/business-email">Business Email</Link>
                                                </li>

                                            </ul>
                                        </li>

                                        <li className={props.active == 'ssl' && 'active'}>
                                            <Link to="/ssl-certificate">SSL Certificate</Link>
                                        </li>
                                        
                                    </ul>

                                </div>

                            </Col>

                            {/* ClientArea */}
                            <Col md={2}>

                                <div className="ClientArea">
                                    <a href="https://my.hostwev.com/index.php?rp=/login" className='bg'>Client Area</a>
                                </div>

                            </Col>

                        </Row>

                    </div>
                    
                </Container>

            </section>

            {/*  MobileMenu */}
            <section className='MobileMenu'>

                <Container>

                    <Row>

                        {/* logo */}
                        <Col sx={6}>

                            <div className="Logo">
                                <Link to='/'><img src="assets/images/logo.png" alt="logo" /></Link>
                            </div>

                        </Col>

                        <Col sx={6}>

                            <div className="MobileMenuItem">

                                <Button className='MenuBar' onClick={handleShow}>
                                    <AiOutlineBars/>
                                </Button>

                                <Offcanvas show={show} onHide={handleClose}>

                                    <Offcanvas.Header closeButton></Offcanvas.Header>

                                    <Offcanvas.Body>

                                        <div className="MenuDropdown">


                                            <Nav className={props.active == 'home' && 'active'}><Link to='/'>Home</Link> </Nav>

                                            {/* Hosting */}
                                            <NavDropdown className={props.active == 'hosting' && 'active'} title="Hosting" id="basic-nav-dropdown">
                                                <Link to='/web-hosting'>Web Hosting</Link>
                                                <Link to='/turbo-hosting'>Turbo Hosting</Link>
                                                <Link to='/bdix-turbo-hosting'>BDIX Turbo Hosting</Link>
                                                <Link to='/bdix-reseller-hosting'>BDIX Reseller Hosting</Link>
                                            </NavDropdown>
                                            
                                            {/* Server */}
                                            <NavDropdown className={props.active == 'server' && 'active'} title="Server" id="basic-nav-dropdown">
                                                <Link to='/vartual-server'>Vartual Server</Link>
                                                <Link to='/dedicated-derver'>Dedicated Server</Link>
                                            </NavDropdown>

                                            {/* Domain */}
                                            <NavDropdown className={props.active == 'domain' && 'active'} title="Domain" id="basic-nav-dropdown">
                                                <Link to='/domain-resister'>Domain Registration</Link>
                                                <Link to='/domain-reseller'>Domain Reseller</Link>
                                            </NavDropdown>

                                            {/* Email Hosting */}
                                            <NavDropdown className={props.active == 'email' && 'active'} title="Email Hosting" id="basic-nav-dropdown">
                                                <Link to='/g-suite'>G Suite By Google</Link>
                                                <Link to='/business-email'>Business Email</Link>
                                            </NavDropdown>

                                            <Nav className={props.active == 'ssl' && 'active'}> <Link to='/ssl-certificate'>SSL Certificate</Link> </Nav>

                                            <Nav className={props.active == 'aboutus' && 'active'}> <Link to='/about-us'>About Us</Link> </Nav>
                                            <Nav className={props.active == 'data_center' && 'active'}> <Link to='/data-center'>Our Datacenters</Link> </Nav>
                                            <Nav className={props.active == 'blog' && 'active'}> <Link to='/blog'>Blogs</Link> </Nav>
                                            <Nav className={props.active == 'contactus' && 'active'}> <Link to='/contact-us'>Contact Us</Link> </Nav>

                                        </div>

                                        <a href="https://my.hostwev.com/index.php?rp=/login" className='MobileMenuItem bg'>Client Area</a>
                                        
                                    </Offcanvas.Body>

                                </Offcanvas>

                            </div>
                            

                        </Col>

                    </Row>

                </Container>

            </section>


        </>


    )


}

export default Menu