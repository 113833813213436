import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const OpenVzVps = () => {


    return (

        <>
        
            {/* OpenVzVps */}
            <section className='OpenVzVps'>

                <Container>
                    
                    <Row>

                        <Col md={12}>

                            <div className="Header">
                                <h2><span>Linux KVM</span> VPS</h2>
                            </div>

                        </Col>

                    </Row>

                    <div className='OpenVzVpsContent'>

                        <Row>

                            <Col md={12}>

                                {/* header */}
                                <div className="OpenVzVpHeader d_flex">

                                    {/* item */}
                                    <div className="OpenVzVpHeaderItem d_flex d_center">
                                        <h4>Package <br/> Name</h4>
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpHeaderItem d_flex d_center">
                                        <h4>Cores</h4>
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpHeaderItem d_flex d_center">
                                        <h4>Memory</h4>
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpHeaderItem d_flex d_center">
                                        <h4>Disk <br /> Space</h4>
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpHeaderItem d_flex d_center">
                                        <h4>Bandwith</h4>
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpHeaderItem d_flex d_center">
                                        <h4>Price</h4>
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpHeaderItem d_flex d_center vpsLast">
                                        
                                    </div>
                                    
                                </div>

                                {/* Content */}
                                <div className="OpenVzVpMiddleContent">

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* left */}
                                        <div className="OpenVzVpLeft">
                                            <h4>V-SSD2GB</h4>
                                        </div>

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>1 <br /> CPU</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>2GB  <br />RAM</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>20 GB <br />NVMe SSD</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4> 1<br />TB</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT<br />2599/Mo</h4>
                                            </div>

                                            {/* item */}
                                            <div className="Position">
                                            <a href="https://my.hostwev.com/index.php?rp=/store/vps-server/soft-starter" className='bg'>Buy Now</a>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* left */}
                                        <div className="OpenVzVpLeft">
                                            <h4>V-SSD4GB</h4>
                                        </div>

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>1 <br /> CPU</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>4GB  <br />RAM</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>40 GB <br />NVMe SSD</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4> 1<br />TB</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT<br />3999/Mo</h4>
                                            </div>

                                            {/* item */}
                                            <div className="Position">
                                                <a href="https://my.hostwev.com/index.php?rp=/store/vps-server/corporate" className='bg'>Buy Now</a>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* left */}
                                        <div className="OpenVzVpLeft">
                                            <h4>V-SSD6GB</h4>
                                        </div>

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>2 <br /> CPU</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>6GB <br />RAM</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>80 GB <br />NVMe SSD</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4> 2<br />TB</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT<br />5999/Mo</h4>
                                            </div>

                                            {/* item */}
                                            <div className="Position">
                                            <a href="https://my.hostwev.com/index.php?rp=/store/vps-server/soft-business" className='bg'>Buy Now</a>
                                            </div>

                                        </div>
                                        
                                    </div>

                                </div>

                            </Col>

                        </Row>

                    </div>
                    
                </Container>

            </section>

        </>
        
    )


}

export default OpenVzVps
