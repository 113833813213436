import React from 'react';
import Header from '../Components/CommonComponents/Header';
import Menu from '../Components/CommonComponents/Menu';
import Footer from '../Components/CommonComponents/Footer';
import OthersBanner from '../Components/CommonComponents/OthersBanner';
import OpenVzVps from '../Components/VpsPageComponent/OpenVzVps';
import WhereToStart from '../Components/CommonComponents/WhereToStart';
import SupportedOperatingSystem from '../Components/CommonComponents/SupportedOperatingSystem';
import VirtualServer from '../Components/VpsPageComponent/VirtualServer';
import ResellerHostingSolution from '../Components/HostingResellerPage/ResellerHostingSolution';
import Package from '../Components/HostingResellerPage/Package';
import WhatMakeUsBest from '../Components/CommonComponents/WhatMakeUsBest';
import ClientReview from '../Components/CommonComponents/ClientReview';
import Partner from '../Components/CommonComponents/Partner';
import HostingFeature from '../Components/CommonComponents/HostingFeature';



const HostingReseller = () => {

    let header={
        header:'BDIX Reseller',
        span:'Web Hosting',
        description:'Make money as a reseller with our fully white-labeled Reseller Hosting solutions.',
    }



    return (

        <>
        
            {/* Header */}
            <Header></Header>

            {/* Menu */}
            <Menu active='hosting'></Menu>

            {/* banner */}
            <OthersBanner header={header}></OthersBanner>

            {/* HostingReseller */}
            <ResellerHostingSolution></ResellerHostingSolution>

            {/* Package */}
            <Package></Package>

            {/* HostingFeature */}
            <HostingFeature></HostingFeature>

            {/* WhatMakeUsBest */}
            <WhatMakeUsBest></WhatMakeUsBest>

            {/* ClientReview */}
            <ClientReview></ClientReview>

            {/* HostingSolution */}
            <WhereToStart></WhereToStart>

            {/* Partner */}
            <Partner></Partner>


            {/* Footer */}
            <Footer></Footer>

            


        </>

    )



}

export default HostingReseller