/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table';

const TermsOfServiceContent = () => {


    return (

        <section className='TermsOfServiceContent section_gaps'>

            <Container>
                
                <Row>

                    
                    <Col md={12}>

                        <div className="Header">

                            <h2>Everything You Need to <span>Resell Domains</span></h2>

                            <p>Our Best Domain reseller plans come with hundreds of unlimited domain extensions including best extensions as .com .net .org .eu .etc and many new TLDs such as .host .shop .music .news .india .uk etc. Our white label domain program panel offers the best wholesale prices in 2019 for your business management. Our cheap domain prices starts as low cost as $0.99/year requires no deposit and registration is free. </p>
                            <p>To get your free domain reseller program simply purchase a Web hosting reseller Plan for your company.</p>

                            <a href="http://reseller.hostwev.com/reseller.php?action=show_signupform" className='bg' target="_blank">Sign Up Now</a>
                            <a href="http://domaincp.hostwev.com/" className='bg' target="_blank">Sign In</a>
                        </div>

                    </Col>

                </Row>
                
            </Container>


            <div className="DomainReseller section_gaps">

                <Container>

                    <Row>

                        <Col md={12}>

                            <div className="Header">
                                <h2>Features You  <span>Will Get</span></h2>
                            </div>

                            <Row>

                                {/* item */}
                                <Col md={4}>
                                
                                    <div className="DomainResellerItem">

                                        <div className="img">
                                            <img src="assets/images/control-panel (Traced).png" alt="" />
                                        </div>

                                        <div className="text">

                                            <h3>Your Branded Panel</h3>
                                            <p>Proven Billing, Customer & Order Management Panels.</p>

                                        </div>

                                    </div>

                                </Col>

                                {/* item */}
                                <Col md={4}>
                                
                                    <div className="DomainResellerItem">

                                        <div className="img">
                                            <img src="assets/images/bar-chart (Traced).png" alt="" />
                                        </div>

                                        <div className="text">

                                            <h3>White-label automation</h3>
                                            <p>Allows you, your resellers and their customers</p>

                                        </div>

                                    </div>

                                </Col>

                                {/* item */}
                                <Col md={4}>
                                
                                    <div className="DomainResellerItem">

                                        <div className="img">
                                            <img src="assets/images/web-domain (Traced).png" alt="" />
                                        </div>

                                        <div className="text">

                                            <h3>Domain API</h3>
                                            <p>Everything we offer can be seamlessly added to your web.</p>

                                        </div>

                                    </div>

                                </Col>

                                {/* item */}
                                <Col md={4}>
                                
                                    <div className="DomainResellerItem">

                                        <div className="img">
                                            <img src="assets/images/discount (Traced).png" alt="" />
                                        </div>

                                        <div className="text">

                                            <h3>Promos & Discounts</h3>
                                            <p>Take advantage of our fantastic Coupon engine</p>

                                        </div>

                                    </div>

                                </Col>

                                {/* item */}
                                <Col md={4}>
                                
                                    <div className="DomainResellerItem">

                                        <div className="img">
                                            <img src="assets/images/equalizer (Traced).png" alt="" />
                                        </div>

                                        <div className="text">

                                            <h3>Completely Customizable</h3>
                                            <p>Modify the HTML, CSS, Images and Content.</p>

                                        </div>

                                    </div>

                                </Col>

                                {/* item */}
                                <Col md={4}>
                                
                                    <div className="DomainResellerItem">

                                        <div className="img">
                                            <img src="assets/images/timeline (Traced).png" alt="" />
                                        </div>

                                        <div className="text">

                                            <h3>Pre-Created Templates</h3>
                                            <p>Use a Template that Offers the Products wish to Sell.</p>

                                        </div>

                                    </div>

                                </Col>

                            </Row>

                        </Col>

                    </Row>

                   

                </Container>

            </div>

        </section>
        
    )


}

export default TermsOfServiceContent