import React from 'react';
import { Container, Row, Col, Button, Accordion } from 'react-bootstrap';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import { Link } from 'react-router-dom';

const Question = () => {


    return (

        <section className='Question section_gaps'>

            <Container>
                
                <Row className='d_flex'>
                    
                    <Col md={12} lg={7}>

                        <div className="QuestionContent">

                        <Accordion defaultActiveKey="0">
                            
                            {/* item */}
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                    Can I use my existing domain with G Suite?

                                    <div className="plus">
                                        <AiOutlinePlus/>
                                    </div>

                                    <div className="minus">
                                        <AiOutlineMinus/>
                                    </div>

                                </Accordion.Header>

                                <Accordion.Body>
                                    <p>Yes, you can use an existing domain with your G Suite order.</p>
                                </Accordion.Body>

                            </Accordion.Item>

                            {/* item */}
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>
                                    What happens to my existing mail, contacts, and calendar data when I move to G Suite?
                                    <div className="plus">
                                        <AiOutlinePlus/>
                                    </div>

                                    <div className="minus">
                                        <AiOutlineMinus/>
                                    </div>
 
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p>When switching to G Suite from another program or web service, you and your users can bring your existing mail, contacts, and calendar data with you. You have a variety of options for migrating data into G Suite, depending on the size of your organization and the system you’re migrating from. Tools are available for migration from Microsoft Exchange, Lotus Notes, IMAP servers and other Google accounts.</p>
                                </Accordion.Body>
                            </Accordion.Item>

                            {/* item */}
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>
                                    Which Email Clients and protocols are supported?
                                    <div className="plus">
                                        <AiOutlinePlus/>
                                    </div>

                                    <div className="minus">
                                        <AiOutlineMinus/>
                                    </div>
 
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p>You can send and receive emails using any desktop-based email client such as Microsoft Outlook, Outlook Express, Mozilla Thunderbird, Eudora, Entourage 2004, Windows Mail, etc. We also have a guide on how you can configure different email clients to send/receive emails. The enterprise email product supports the POP, IMAP and MAPI protocols.</p>
                                </Accordion.Body>
                            </Accordion.Item>

                            {/* item */}
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>
                                    Is G Suite compatible with the email client I use today?
                                    <div className="plus">
                                        <AiOutlinePlus/>
                                    </div>

                                    <div className="minus">
                                        <AiOutlineMinus/>
                                    </div>
 
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p>In addition to accessing G Suite mail from the Gmail web interface, you can send and receive mail from your favorite desktop client. Depending on the client, you can use either the IMAP or POP mail protocol. If you’re switching to G Suite from Microsoft Exchange or some other Outlook service, you can use G Suite Sync. This is a plug-in for Outlook 2003, 2007, 2010 or 2013 that lets you use Outlook to manage your G Suite mail, calendar and contacts—along with your Outlook notes, tasks and journal entries.</p>
                                </Accordion.Body>
                            </Accordion.Item>

                            {/* item */}
                            <Accordion.Item eventKey="4">
                                <Accordion.Header>
                                    Which Email Clients and protocols are supported?
                                    <div className="plus">
                                        <AiOutlinePlus/>
                                    </div>

                                    <div className="minus">
                                        <AiOutlineMinus/>
                                    </div>
 
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p>You can send and receive emails using any desktop-based email client such as Microsoft Outlook, Outlook Express, Mozilla Thunderbird, Eudora, Entourage 2004, Windows Mail, etc. We also have a guide on how you can configure different email clients to send/receive emails. The enterprise email product supports the POP, IMAP and MAPI protocols.</p>
                                </Accordion.Body>
                            </Accordion.Item>

                            {/* item */}
                            <Accordion.Item eventKey="5">
                                <Accordion.Header>
                                    Which mobile phones can I access my mail from?
                                    <div className="plus">
                                        <AiOutlinePlus/>
                                    </div>

                                    <div className="minus">
                                        <AiOutlineMinus/>
                                    </div>
 
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p> Your email can be accessed using any Smartphone or Tablet. The Gmail interface is compatible on all major Operating systems such as iOS, Android, Windows Mobile, Symbian and Blackberry.</p>
                                </Accordion.Body>
                            </Accordion.Item>

                            {/* item */}
                            <Accordion.Item eventKey="6">
                                <Accordion.Header>
                                    Can I manage multiple domains with G Suite?
                                    <div className="plus">
                                        <AiOutlinePlus/>
                                    </div>

                                    <div className="minus">
                                        <AiOutlineMinus/>
                                    </div>
 
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p>If your organization acquires a new domain name or does business at multiple domains, you can add all your domains to your account at no extra cost. Users can then have identities at one or more of your domains while sharing services as part of a single organization. And you manage your domains from the same Admin console. You add a domain as either a separate domain or domain alias, depending on how you plan to use it.</p>
                                </Accordion.Body>
                            </Accordion.Item>

                            {/* item */}
                            <Accordion.Item eventKey="7">
                                <Accordion.Header>
                                    If I only want one of the products, do I have to buy the entire suite?
                                    <div className="plus">
                                        <AiOutlinePlus/>
                                    </div>

                                    <div className="minus">
                                        <AiOutlineMinus/>
                                    </div>
 
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p> Yes. G Suite is designed as an all-in-one solution with integrated tools that work seamlessly together. For instance, you can receive a message in Gmail and instantly convert it into a Calendar event. When you make a comment in Docs, Sheets or Slides, collaborators automatically receive email alerts. With a single click, you can launch a Hangouts video meeting from your inbox or calendar. Using these tools as a complete package improves productivity while giving your business the most value. However, you are welcome to purchase the suite and only use the services of your choice. </p>
                                </Accordion.Body>
                            </Accordion.Item>

                            {/* item */}
                            <Accordion.Item eventKey="8">
                                <Accordion.Header>
                                    If I only want one of the products, do I have to buy the entire suite?
                                    <div className="plus">
                                        <AiOutlinePlus/>
                                    </div>

                                    <div className="minus">
                                        <AiOutlineMinus/>
                                    </div>
 
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p> Yes. G Suite is designed as an all-in-one solution with integrated tools that work seamlessly together. For instance, you can receive a message in Gmail and instantly convert it into a Calendar event. When you make a comment in Docs, Sheets, or Slides, collaborators automatically receive email alerts. With a single click, you can launch a Hangouts video meeting from your inbox or calendar. Using these tools as a complete package improves productivity while giving your business the most value. However, you are welcome to purchase the suite and only use the services of your choice.</p>
                                </Accordion.Body>
                            </Accordion.Item>

                            {/* item */}
                            <Accordion.Item eventKey="9">
                                <Accordion.Header>
                                    Can I create mailing lists?
                                    <div className="plus">
                                        <AiOutlinePlus/>
                                    </div>

                                    <div className="minus">
                                        <AiOutlineMinus/>
                                    </div>
 
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p>Yes, you can create mailing lists and add/delete users, select a moderator, restrict people from joining a list or even ban users from a list.</p>
                                </Accordion.Body>
                            </Accordion.Item>

                            {/* item */}
                            <Accordion.Item eventKey="10">
                                <Accordion.Header>
                                    What is your SPAM policy?
                                    <div className="plus">
                                        <AiOutlinePlus/>
                                    </div>

                                    <div className="minus">
                                        <AiOutlineMinus/>
                                    </div>
 
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p>When you sign up for a G Suite account, you agree not to use the account to send spam, distribute viruses, or otherwise abuse the service. All users on your domain are subject to these agreements, which are part of the G Suite Acceptable Use Policy. If Google identifies a G Suite user who is violating these agreements, we reserve the right to immediately suspend the user. If the problem is domain-wide, we reserve the right to suspend the entire account and deny administrator access to all the G Suite services. In such cases, we send a notification to the registered secondary email address for the domain administrator. </p>
                                </Accordion.Body>
                            </Accordion.Item>

                            {/* item */}
                            <Accordion.Item eventKey="11">
                                <Accordion.Header>
                                    Can I use Auto Responders?
                                    <div className="plus">
                                        <AiOutlinePlus/>
                                    </div>

                                    <div className="minus">
                                        <AiOutlineMinus/>
                                    </div>
 
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p>Yes, you can. Use Gmail’s vacation responder to let people know that you won’t be able to get back to them right away. While your vacation responder is turned on, Gmail will send your reply to people who email you.</p>
                                </Accordion.Body>
                            </Accordion.Item>

                            {/* item */}
                            <Accordion.Item eventKey="12">
                                <Accordion.Header>
                                    Can I transfer my existing G Suite?
                                    <div className="plus">
                                        <AiOutlinePlus/>
                                    </div>

                                    <div className="minus">
                                        <AiOutlineMinus/>
                                    </div>
 
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p>Yes. During the transfer, we move all your email accounts from the old provider to us by keeping the data intact. However, your existing tenure with the other provider, if any does not get moved to us. You can click here to initiate the transfer process.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            

                        </Accordion>
                            
                        </div>

                    </Col>

                    <Col md={12} lg={5}>

                        <div className="QuestionImg">
                            <img src="assets/images/question.png" alt="" />
                        </div>

                    </Col>

                </Row>
                
            </Container>

        </section>
        
    )


}

export default Question