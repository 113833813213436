import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const RapidSsl = () => {


    return (

        <section className='RapidSsl section_gaps'>

            <Container>
                
                <Row>

                    {/* item */}
                    <Col md={6} lg={3}>

                        <div className="RapidSslItem">

                            <div className="img">
                                <img src="assets/images/RapidSsl2.png" alt="" />
                            </div>

                            <div className="text">

                                <h4>RapidSSL</h4>
                                <p>RapidSSL Certificates offer up to 256-bit data encryption and site authentication. </p>

                            </div>
                            
                        </div>

                    </Col>

                    {/* item */}
                    <Col md={6} lg={3}>

                        <div className="RapidSslItem">

                            <div className="img">
                                <img src="assets/images/RapidSsl1.png" alt="" />
                            </div>

                            <div className="text">

                                <h4>SECTIGO</h4>
                                <p>The fast and affordable online business solution for protecting customer transactions. </p>

                            </div>
                            
                        </div>

                    </Col>

                    {/* item */}
                    <Col md={6} lg={3}>

                        <div className="RapidSslItem">

                            <div className="img">
                                <img src="assets/images/RapidSsl3.png" alt="" />
                            </div>

                            <div className="text">

                                <h4>GeoTrust®</h4>
                                <p>GeoTrust® offers a range of value-priced SSL Certificates with fast delivery. </p>

                            </div>
                            
                        </div>

                    </Col>

                    {/* item */}
                    <Col md={6} lg={3}>

                        <div className="RapidSslItem">

                            <div className="img">
                                <img src="assets/images/RapidSsl4.png" alt="" />
                            </div>

                            <div className="text">

                                <h4>Symantec</h4>
                                <p>Symantec provides Secure Sockets Layer (SSL) Certificates and more in a single solution. </p>

                            </div>
                            
                        </div>

                    </Col>

                </Row>
                
            </Container>

        </section>
        
    )


}

export default RapidSsl