/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Container, Row, Col, Button, Dropdown, DropdownButton } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const HomeBanner = () => {

    const [url ,setUrl] = useState("")
    const [value,setValue]=useState(".com");
   
    const urlSubmit = (e) => {
        const value =e.target.value;
        setUrl(value.replace(/\s/g, ''));
    }
    const handleSelect=(e)=>{
        setValue(e.replace(/\s/g, ''));
      }
      


    return (

        <section className='HomeBanner DomainRegister'>

            {/* SideText */}
            <div className="SideText">
                <h6><span>Get</span> Next Level</h6>
            </div>

            <Container>
                
                <Row className='justify-content-md-center'>

                    {/* text */}
                    <Col md={10}>

                        <div className="HomeBannerContent">
                            
                            <h4>Engage customers / users with the best domain..</h4>
                            <h2>Use Domain Checker <span>to find unique domains!</span></h2>

                            {/* SearchDomain */}
                            <div className="SearchDomain">

                                {/* www */}
                                <div className="www">
                                    <h5>www</h5>
                                </div>

                                <input type="text" placeholder='Type your domain ' onBlur={urlSubmit} />

                                <Button><a href={`http://my.hostwev.com/cart.php?a=add&domain=register&query=${url.replace(/\s/g,'')}+${value}`}>Search</a></Button>

                                <Dropdown>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    {value}
                                    </Dropdown.Toggle>

                                    <DropdownButton  onSelect={handleSelect}>
                                        <Dropdown.Item eventKey=".com">.com</Dropdown.Item>
                                        <Dropdown.Item eventKey=".net">.net</Dropdown.Item>
                                        <Dropdown.Item eventKey=".org">.org</Dropdown.Item>
                                        <Dropdown.Item eventKey=".me">.me</Dropdown.Item>
                                        <Dropdown.Item eventKey=".xyz">.xyz</Dropdown.Item>
                                        <Dropdown.Item eventKey=".in">.in</Dropdown.Item>
                                        <Dropdown.Item eventKey=".co.in">.co.in</Dropdown.Item>
                                        <Dropdown.Item eventKey=".top">.top</Dropdown.Item>
                                        <Dropdown.Item eventKey=".info">.info</Dropdown.Item>
                                    </DropdownButton>
                                    
                                </Dropdown>
                                

                            </div>

                            <div className="TransfarDomain">
                                <h4>Or <a href="https://my.hostwev.com/cart.php?a=add&domain=transfer">Transfer Domain</a></h4>
                            </div>

                        </div>

                    </Col>

                </Row>

                {/* DotCom */}
                <div className="DotCom">

                    <Row>

                        {/* Item */}
                        <Col xs={6} md={4} lg={2}>

                            <div className="DotComItem">
                                <a href="#">
                                    <h6>.com</h6>
                                    <p>Only BDT 1449/-</p>
                                    <div className="underline">
                                        <img src="assets/images/underline.png" alt="" />
                                    </div>
                                </a>
                            </div>

                        </Col>

                        {/* Item */}
                        <Col xs={6} md={4} lg={2}>

                            <div className="DotComItem xyz">
                                <a href="#">
                                    <h6>.xyz</h6>
                                    <p>Only BDT 349/-</p>
                                    <div className="underline">
                                        <img src="assets/images/underline.png" alt="" />
                                    </div>
                                </a>
                            </div>

                        </Col>

                        {/* Item */}
                        <Col xs={6} md={4} lg={2}>

                            <div className="DotComItem net">
                                <a href="#">
                                    <h6>.net</h6>
                                    <p>Only BDT 1,599/-</p>
                                    <div className="underline">
                                        <img src="assets/images/underline.png" alt="" />
                                    </div>
                                </a>
                            </div>

                        </Col>

                        {/* Item */}
                        <Col xs={6} md={4} lg={2}>

                            <div className="DotComItem org">
                                <a href="#">
                                    <h6>.org</h6>
                                    <p>Only BDT 1495/-</p>
                                    <div className="underline">
                                        <img src="assets/images/underline.png" alt="" />
                                    </div>
                                </a>
                            </div>

                        </Col>

                        {/* Item */}
                        <Col xs={6} md={4} lg={2}>

                            <div className="DotComItem biz">
                                <a href="#">
                                    <h6>.biz</h6>
                                    <p>Only BDT 1894/-</p>
                                    <div className="underline">
                                        <img src="assets/images/underline.png" alt="" />
                                    </div>
                                </a>
                            </div>

                        </Col>

                        {/* Item */}
                        <Col xs={6} md={4} lg={2}>

                            <div className="DotComItem info">
                                <a href="#">
                                    <h6>.info</h6>
                                    <p>Only BDT 2294/-</p>
                                    <div className="underline">
                                        <img src="assets/images/underline.png" alt="" />
                                    </div>
                                </a>
                            </div>

                        </Col>

                    </Row>

                </div>
                
            </Container>

        </section>
        
    )


}

export default HomeBanner