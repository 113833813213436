import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const WhereToStart = () => {


    return (

        <section className='WhereToStart'>

            <Container>
                
                <Row>

                    
                    <Col md={12} lg={3}>

                        <div className="WhereToStartImg">

                            <div className="WhereToStartImgPosition">
                                <img src="assets/images/WhereToStartImg.png" alt="WhereToStartImg" />
                            </div>

                        </div>

                    </Col>

                    <Col md={12} lg={9}>

                        <div className="WhereToStartContent">

                            <h2><span>Need help?</span> We're always here for you.</h2>
                            <p>Our hostwev Sales Team is available 24/7 to help you understand which plan will work for your unique needs!</p>

                            <div className="WhereToStartGroup">

                                {/* item */}
                                <div className="WhereToStartItem">

                                    <a href="javascript:void(Tawk_API.toggle())">
                                        <div className="img">
                                            <img src="assets/images/live-chat.png" alt="live-chat" />
                                        </div>

                                        <h5>Live Chat</h5>
                                    </a>

                                </div>

                                {/* item */}
                                <div className="WhereToStartItem">

                                    <a href="https://my.hostwev.com/submitticket.php?step=2&deptid=4">
                                        <div className="img">
                                            <img src="assets/images/support-ticket.png" alt="live-chat" />
                                        </div>

                                        <h5>Support Ticket</h5>
                                    </a>

                                </div>

                                {/* item */}
                                <div className="WhereToStartItem">

                                    <a href="mailto:support@hostwev.com">
                                        <div className="img">
                                            <img src="assets/images/email.png" alt="live-chat" />
                                        </div>

                                        <h5>Send Message</h5>
                                    </a>

                                </div>

                                {/* item */}
                                <div className="WhereToStartItem">

                                   <a href="tel:01894844446">
                                    <div className="img">
                                            <img src="assets/images/phone-call.png" alt="live-chat" />
                                        </div>

                                        <h5>Call Now</h5>
                                   </a>

                                </div>

                            </div>

                        </div>

                    </Col>

                </Row>
                
            </Container>

        </section>
        
    )


}

export default WhereToStart