/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Container, Row, Col, Button, Dropdown } from 'react-bootstrap';
import { AiOutlineSearch } from 'react-icons/ai';
import { Link } from 'react-router-dom';

const DomainPrice = () => {


    return (

        <section className='DomainPrice section_gaps'>

            <Container>

                {/* Header */}
                <Row>

                    {/* Item */}
                    <Col md={12}>

                        <div className="Header">
                            
                            <h4>Check the detailed pricing of the top-level and most popular TLDs</h4>
                            <h2><span>Domain</span> Price List!</h2>

                        </div>

                    </Col>

                </Row>

                {/* DotCom */}
                <div className="DomainPriceContent">

                    <div className='OpenVzVpsContent'>

                        <Row>

                            <Col md={12}>

                                {/* header */}
                                <div className="OpenVzVpHeader d_flex">

                                    {/* item */}
                                    <div className="OpenVzVpHeaderItem d_flex d_center">
                                        <h4>TLD</h4>
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpHeaderItem d_flex d_center">
                                        <h4>Register</h4>
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpHeaderItem d_flex d_center">
                                        <h4>Transfer</h4>
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpHeaderItem d_flex d_center">
                                        <h4>Renew</h4>
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpHeaderItem d_flex d_center">
                                        <input type="text" placeholder='Search' />
                                        <div className="svg"><AiOutlineSearch/></div>
                                    </div>
                                    
                                </div>

                                {/* Content */}
                                <div className="OpenVzVpMiddleContent">

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>.com</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 1,449.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT 1,449.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 1,449.00</h4>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>.net</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 1,599.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT 1,599.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 1,599.00</h4>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>.org</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 1,495.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT 1,495.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 1,495.00</h4>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>.info</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 2,294.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT 2,294.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 2,294.00</h4>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>.biz</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 1894.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT 1894.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 1894.00</h4>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>.us</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 970.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT 970.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 970.00</h4>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>.name</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 1012.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT 1012.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 1012.00</h4>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>.icu</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 1020.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT 1020.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 1165.00</h4>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>.net.in</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 1138.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT 1138.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 1208.00</h4>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>.mobi</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 3643.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT 3643.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 4231.00</h4>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>.tv</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 3,739.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT 3,739.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 4,274.00</h4>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>.co</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 3,651.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT 3,651.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 4,414.00</h4>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>.me</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 4,080.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT 3,497.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 4,747.00</h4>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>.ca</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 1,902.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT 1,902.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 2,263.00</h4>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>.in</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 1,346.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT 1,346.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 1,404.00</h4>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>.co.uk</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 959.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT 0.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 959.00</h4>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>.io</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 6,968.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT 7,288.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 8,328.00</h4>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>.one</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 1,311.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT 1,311.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 1498.00</h4>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>.agency</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 2,623.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT 2,623.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 3,192.00</h4>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>.pw</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 2,914.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT 2,914.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 3316.00</h4>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>.actor</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 4,817.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT 4,817.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 5,802.00</h4>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>.care</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 3,942.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT 3,942.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 4,761.00</h4>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>.live</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 3,497.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT 3,497.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 3,997.00</h4>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>.love</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 4,373.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT 4,373.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 4,995.00 </h4>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>.co.in</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 1,138.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT 1,138.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 1208.00</h4>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>.news</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 3,497.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT 3,497.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 3,997.00</h4>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>.studio</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 3,497.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT 3,497.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 3,997.00</h4>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>.market</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 4,554.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT 4,554.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 5,205.00</h4>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>.global</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 8,308.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT 8,308.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 9,494.00</h4>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>.io</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 6,968.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT 7,288.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 8,328.00 </h4>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>.fm</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 17,053.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT 17,053.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 19,489.00</h4>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>.media</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 3,942.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT 3,942.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 4,761.00</h4>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>.ws</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 3,643.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT 3,643.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 4,164.00</h4>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>.com.au</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 1,605.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT 1,605.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 1,833.00</h4>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>.com.sc</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 13,992.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT 13,992.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 15,991.00</h4>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>.online</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 4,663.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT 4,663.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 3,747.00</h4>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>.shop</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 4,373.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT 4,373.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 4,997.00</h4>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>.reviews</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 3,505.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT 3,505.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 4,006.00</h4>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>.cloud</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 2,776.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT 1,457.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 1,804.00</h4>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>.stream</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 3,645.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT 874.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 915.00</h4>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>.pro</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 3,353.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT 3,353.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 3,898.00</h4>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>.store</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 7,289.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT 7,289.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 5,580.00</h4>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>.xyz</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 349.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT 1,699.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 1,899.00</h4>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>.health</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 9,183.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT 9,183.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 10,494.00</h4>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>.top</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 1,459.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT 1,459.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 1,667.00</h4>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>.style</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 3,942.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT 3,942.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 4,761.00</h4>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>.tech</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 6,559.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT 6,559.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 4,997.00</h4>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>.dev</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 2,040.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT 2,040.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 2,331.00</h4>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>.ninja</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 2,397.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT 2,397.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 2,739.00</h4>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>.buzz</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 4,747.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT 4,747.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 5,718.00</h4>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>.club</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 2,331.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT 2,331.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 2,665.00</h4>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>.life</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 3,826.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT 3,826.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 4,373.00</h4>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>.social</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 4,409.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT 4,409.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 5,039.00</h4>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>.click</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 1,115.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT 1,115.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 1,115.00</h4>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>.cc</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 2,209.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT 2,209.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 2,524.00</h4>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>.foundation</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 3,584.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT 3,584.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 3,584.00</h4>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>.blog</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 3,651.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT 3,651.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 4,414.00</h4>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>.de</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 1,020.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT 1,020.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 1165.00</h4>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>.space</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 2,354.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT 2,354.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 2,690.00</h4>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>.app</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 2,193.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT 2,193.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 2,669.00</h4>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>.link</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 1,156.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT 1,156.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 1,320.00</h4>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>.email</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 3,089.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT 3,089.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 3,531.00</h4>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>.rocks</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 1,494.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT 1,494.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 1,707.00</h4>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>.id</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 3,208.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT 3,208.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 3,665.00</h4>
                                            </div>

                                        </div>
                                        
                                    </div>

                                    {/* item */}
                                    <div className="OpenVzVpMiddle d_flex">

                                        {/* right */}
                                        <div className="OpenVzVpRight d_flex">

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>.haus</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 3,716.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4>BDT 3,716.00</h4>
                                            </div>

                                            {/* item */}
                                            <div className="OpenVzVpRightItem">
                                                <h4 className='changeColor'>BDT 4247.00</h4>
                                            </div>

                                        </div>
                                        
                                    </div>

                                </div>
                                

                            </Col>

                        </Row>

                    </div>

                </div>
                
            </Container>

        </section>
        
    )


}

export default DomainPrice