import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { SlCalender } from 'react-icons/sl';


const BlogContent = () => {


    return (

        <section className='BlogContent section_gaps'>

            <Container>
                
                <Row>

                    {/* item */}
                    <Col md={6} lg={4}>

                        <div className="BlogItem">
                            
                            <div className="img">
                                <img src="assets/images/blog1.png" alt="" />
                            </div>

                            <div className="text">
                                
                                <h3>Acquiring a great domain can take persistence</h3>

                                <div className="Calender">
                                    <h5 className='d_flex'> <SlCalender/> 20 October 2022</h5>
                                </div>
                                <p>Acquiring a great domain that matches your brand can pay off in spades. Carl Hancock, co-founder of the popular WordPress tool Gravity Forms, knows this firsthand. Ever since launching Gravity Forms in 2009, Hancock’s company has used the domain gravityforms.com.</p>

                                <Link to='/blog-single' className='bg'> Read More </Link> 

                            </div>

                        </div>

                    </Col>

                    {/* item */}
                    <Col md={6} lg={4}>

                        <div className="BlogItem">
                            
                            <div className="img">
                                <img src="assets/images/blog2.png" alt="" />
                            </div>

                            <div className="text">
                                
                                <h3>7 Different Types of Web Hosting You Must Know</h3>

                                <div className="Calender">
                                    <h5 className='d_flex'> <SlCalender/> 20 October 2022</h5>
                                </div>
                                <p>Website hosting is required if you’re planning on making a website. The faster you start researching your options, the more you’ll discover that there are many types of hosting, each with different prices, performance, features, and technical complexity. You should know what each hosting type </p>

                                <Link to='/blog-single' className='bg'> Read More </Link> 

                            </div>

                        </div>

                    </Col>

                    {/* item */}
                    <Col md={6} lg={4}>

                        <div className="BlogItem">
                            
                            <div className="img">
                                <img src="assets/images/blog3.png" alt="" />
                            </div>

                            <div className="text">
                                
                                <h3>15 Steps to Secure Your WordPress Site From Hackers</h3>

                                <div className="Calender">
                                    <h5 className='d_flex'> <SlCalender/> 20 October 2022</h5>
                                </div>
                                <p>Is your site secure from hackers? Are your customers’ and visitors’ confidential data safe from cybersecurity attacks? Hackers are trying hard to beat your WordPress security, so be diligent in securing your website. The last thing you don’t want to happen is to see your site in nowhere. In this step-by-step guide, you will learn </p>

                                <Link to='/blog-single' className='bg'> Read More </Link> 

                            </div>

                        </div>

                    </Col>

                    {/* item */}
                    <Col md={6} lg={4}>

                        <div className="BlogItem">
                            
                            <div className="img">
                                <img src="assets/images/blog4.png" alt="" />
                            </div>

                            <div className="text">
                                
                                <h3>Fix Your 404 Pages</h3>

                                <div className="Calender">
                                    <h5 className='d_flex'> <SlCalender/> 20 October 2022</h5>
                                </div>
                                <p>All web users will at some point encounter the dreaded 404 page . It’s pretty much inevitable. But even though it’s common, that doesn’t mean it’s something for brands to treat lightly. When your users land on a 404 page, it can cause frustration. It makes your visitors more likely to leave your website.</p>

                                <Link to='/blog-single' className='bg'> Read More </Link> 

                            </div>

                        </div>

                    </Col>

                    {/* item */}
                    <Col md={6} lg={4}>

                        <div className="BlogItem">
                            
                            <div className="img">
                                <img src="assets/images/blog5.png" alt="" />
                            </div>

                            <div className="text">
                                
                                <h3>Best Point of Sale (POS) Plugins for WooCommerce</h3>

                                <div className="Calender">
                                    <h5 className='d_flex'> <SlCalender/> 20 October 2022</h5>
                                </div>
                                <p>Your ecommerce business is flexible in this ever-changing economy. One day, you’re ringing up customers in your brick-and-mortar store, and the next day, you’re accepting bulk orders on your website. Your point-of-sale (POS) system needs to be agile, too.</p>

                                <Link to='/blog-single' className='bg'> Read More </Link> 

                            </div>

                        </div>

                    </Col>

                    {/* item */}
                    <Col md={6} lg={4}>

                        <div className="BlogItem">
                            
                            <div className="img">
                                <img src="assets/images/blog6.png" alt="" />
                            </div>

                            <div className="text">
                                
                                <h3>Renew Your Domains in Advance to Save Money</h3>

                                <div className="Calender">
                                    <h5 className='d_flex'> <SlCalender/> 20 October 2022</h5>
                                </div>
                                <p>Most people renew domain names annually, signing up for another year right before it’s about to expire. And that seems like a good strategy to keep money in our pocket as long as we can. However, there are good reasons to take a long-term </p>

                                <Link to='/blog-single' className='bg'> Read More </Link> 

                            </div>

                        </div>

                    </Col>

                </Row>
                
            </Container>

        </section>
        
    )


}

export default BlogContent