import React from 'react';
import Header from '../Components/CommonComponents/Header';
import Menu from '../Components/CommonComponents/Menu';
import Partner from '../Components/CommonComponents/Partner';
import Footer from '../Components/CommonComponents/Footer';
import WhereToStart from '../Components/CommonComponents/WhereToStart';
import OthersBanner from '../Components/CommonComponents/OthersBanner';
import TermsOfServiceContent from '../Components/DomainResellerPage/TermsOfServiceContent';
import WhatSsl from '../Components/DomainResellerPage/WhatSsl';


const DomainReseller = () => {

    let header={
        header:'Domain',
        span:'Reseller',
        description:'You can get your reseller business to-go. We’ll provide you with domains at trade prices, a fully hosted online store, complete white labelling, and a control panel that can be used by you and your customers.',
    }



    return (

        <>
        
            {/* Header */}
            <Header></Header>

            {/* Menu */}
            <Menu active="domain"></Menu>

            {/* banner */}
            <OthersBanner header={header}></OthersBanner>

            {/* TermsOfServiceContent */}
            <TermsOfServiceContent></TermsOfServiceContent>

            {/* wehatSSl */}
            <WhatSsl></WhatSsl>

            {/* HostingSolution */}
            <WhereToStart></WhereToStart>

            {/* Partner */}
            <Partner></Partner>

            {/* Footer */}
            <Footer></Footer>

        </>

    )


}

export default DomainReseller