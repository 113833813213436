import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const WhatSsl = () => {


    return (

        <section className='WhatSsl section_gaps'>

            <Container>
                
                <Row className='d_flex'>

                    {/* content */}
                    <Col md={12} lg={6}>

                        <div className="Header">
                            
                            <h2>Mission <br /> <span></span></h2>
                            <p>Years ago, our founders realized that many online service providers lacked the skills and vision needed to provide truly integrated, holistic, and flexible online solutions. In addition, these mavericks realized that many businesses were paying far too much money for far too little results.</p>
                            <p>That's why in 2019 they launched Hostwev | Secure, 20X Super Fast & Reliable Web Hosting.</p>
                            
                            <p>Hostwev specializes in providing professional hosting, web design, e-commerce, and Internet promotion services.</p>
                            <p>At Hostwev, we believe that the key to success is to provide professional services at a well balanced price.</p>
                            <p>Become a part of our community today and experience the difference!</p>

                        </div>

                    </Col>

                    {/* img */}
                    <Col md={12} lg={6}>

                        <div className="WhatSslImg">
                            <img src="assets/images/about_us.png" alt="" />
                        </div>

                    </Col>

                </Row>
                
            </Container>

        </section>
        
    )


}

export default WhatSsl