/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Map = () => {


    return (

        <section className='Map section_gaps'>

            <Container>
                
                <Row>

                    <Col md={12}>

                        <div className="MapContent">
                            
                           <img src="assets/images/map.png" alt="" />

                        </div>

                    </Col>

                </Row>
                
            </Container>

        </section>
        
    )


}

export default Map