import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const VirtualServer = () => {


    return (

        <section className='VirtualServer section_gaps'>

            <Container>

                {/* header */}
                <Row className="justify-content-md-center">

                    <Col md={8}>

                        <div className="Header">
                            <h4>We are providing you the best solutions !</h4>
                            <h2><span>All Virtual</span> Servers Included</h2>
                        </div>

                    </Col>

                </Row>
                
                <Row>
                    
                    {/* item */}
                    <Col md={6} lg={3}>

                        <div className="VirtualServerItem">

                            <div className="img">
                                <img src="assets/images/VirtualServers1.png" alt="" />
                            </div>

                            <div className="text">

                                <h4>Airtight <br /> Security</h4>
                                <p>Feel extra secure thanks to a private peering network, which provides you with stable connectivity for any website or application you may be running. </p>
                                
                            </div>

                        </div>

                    </Col>

                    {/* item */}
                    <Col md={6} lg={3}>

                        <div className="VirtualServerItem">

                            <div className="img">
                                <img src="assets/images/VirtualServers2.png" alt="" />
                            </div>

                            <div className="text">

                                <h4>100% Server <br /> Control</h4>
                                <p>No limitations. You'll have the full system resources at your disposal.</p>
                                
                            </div>

                        </div>

                    </Col>

                    {/* item */}
                    <Col md={6} lg={3}>

                        <div className="VirtualServerItem">

                            <div className="img">
                                <img src="assets/images/VirtualServers3.png" alt="" />
                            </div>

                            <div className="text">

                                <h4>Root <br /> Access</h4>
                                <p>Advance user? Optional full root access gives you unrestricted access to perform custom installs and more.</p>
                                
                            </div>

                        </div>

                    </Col>

                    {/* item */}
                    <Col md={6} lg={3}>

                        <div className="VirtualServerItem">

                            <div className="img">
                                <img src="assets/images/VirtualServers4.png" alt="" />
                            </div>

                            <div className="text">

                                <h4>DDoS <br /> Protection</h4>
                                <p>Through our DDoS Attack and Intrusion Prevention Solution, we can protect and reduce DDoS threats targeting.</p>
                                
                            </div>

                        </div>

                    </Col>

                </Row>
                
            </Container>

        </section>
        
    )


}

export default VirtualServer